import React, { Component } from "react";
import moment from "moment";
import classNames from "classnames";
import { isEmpty } from "lodash";
import InfoBlock from "../../../../common/InfoBlock";
import Form from "../../../../common/Form";
import {
  FORM_FIELD_TYPE,
  CAMPAIGN_ACTIONS_ALLOWED_STATUS_LIST,
  CAMPAIGN_ACTIONS,
} from "../../../../../constants";
import { getDateRange, getRestrictedDateLimit } from "../../utils/campaignCreate";
import campaignForm from "../../../../../styles/CampaignCreate/CampaignForm.module.scss";

export default class BasicDetails extends Component {
  state = {
    duplicateName: false,
    minDate: undefined,
    maxDate: undefined,
  };

  componentDidMount() {
    const { campaign } = this.props;
    const { fromDate, toDate } = getRestrictedDateLimit(campaign.validity, null);
    this.setState({
      minDate: fromDate ? new Date(fromDate) : undefined,
      maxDate: toDate ? new Date(toDate) : undefined,
    });
  }

  onNameChange(value) {
    const { targetGroups, onInputChange } = this.props;
    onInputChange(value, "name");
    const allTargetGroupNames = targetGroups.map((t) => t.name);
    if (allTargetGroupNames.includes(value)) this.setState({ duplicateName: true });
    else this.setState({ duplicateName: false });
  }

  render() {
    const {
      targetGroup,
      onInputChange,
      hasFormSaved,
      campaign: { validity },
      targetGroups,
    } = this.props;

    const allowModifyingStartDate = CAMPAIGN_ACTIONS_ALLOWED_STATUS_LIST[
      CAMPAIGN_ACTIONS.EDIT_VALIDITY
    ].includes(targetGroup.status);
    //!Date Range Validation
    const today = moment();
    const currentCampaignMedia = targetGroups.filter((tg) => tg.id === targetGroup.id)[0]?.media;
    const isMediaPresent = currentCampaignMedia && currentCampaignMedia.length > 0 ? true : false;
    //date range displayed if start date modification allowed and no media present
    const isDateRangeDisplayed = allowModifyingStartDate && !isMediaPresent;
    //date range: {from:max(campaign start date,today), to: campaign end date}
    const dateRangeMin = moment.max(moment(validity?.fromDate), today).toDate();
    const dateRangeMax = validity?.toDate ? new Date(validity.toDate) : undefined;
    //! today validation not added in start date from, as truncating/deleting media not handeled yet
    //start date:  {from: campaign start date, to: min(medias start date)}
    const startDateMin = new Date(validity?.fromDate);
    const startDateMax = moment
      .min(currentCampaignMedia?.map((m) => moment(m.validity.fromDate)))
      ?.toDate();
    //end date:(media present)    { from: max(media end date),to: campaign end date }
    //end date:(no media present) { from: fixed_start_date,to: campaign end date }
    const endDateMin = isMediaPresent
      ? moment.max(currentCampaignMedia?.map((m) => moment(m.validity.toDate)))?.toDate()
      : new Date(targetGroup.validity.fromDate);
    const endDateMax = dateRangeMax;
    const isPastCampaign = dateRangeMax < dateRangeMin;

    const targetGroupValidity = !isEmpty(targetGroup.validity)
      ? targetGroup.validity
      : {
          fromDate: moment.max(moment(validity?.fromDate), moment().startOf("day")).toDate(),
          toDate: validity?.toDate ? new Date(validity.toDate) : undefined,
        };

    return (
      <InfoBlock header="Target Group Details" childClassName={campaignForm.childWrapper}>
        <div
          className={classNames(
            "flex flex-wrap clearfix",
            campaignForm.formContainer,
            campaignForm.basicDetails
          )}
        >
          <Form
            isFormGroup={false}
            config={[
              {
                id: "name",
                label: "Target Group Name",
                type: FORM_FIELD_TYPE.INPUT,
                size: {
                  lg: 5,
                  md: 5,
                },
                placeholder: "Enter Name",
                value: targetGroup.name || "",
                onChange: (e) => this.onNameChange(e.target.value),
                error: (hasFormSaved && !targetGroup.name) || this.state.duplicateName,
                errorMessage: this.state.duplicateName
                  ? "Duplicate Target Group Name"
                  : "Please Enter Name",
              },
              {
                id: "validity",
                type: FORM_FIELD_TYPE.DATE_RANGE_PICKER,
                formatDate: (date) => moment(date).format("DD/MM/YYYY"),
                title: "Validity",
                size: {
                  lg: 6,
                  md: 6,
                },
                handleDateChange: (dateRange) => onInputChange(dateRange, "validity"),
                dateRange: getDateRange(targetGroupValidity),
                minDate: !isEmpty(targetGroup.validity)
                  ? new Date(targetGroup.validity.fromDate)
                  : dateRangeMin,
                maxDate: dateRangeMax,
                errorMessage: isPastCampaign
                  ? "Incorrect Campaign Validity"
                  : "Please Enter Validity",
                //error: hasFormSaved && !targetGroup.validity,
                error:
                  (hasFormSaved &&
                    (Object.keys(targetGroup.validity).length === 0 ||
                      !targetGroup.validity.toDate ||
                      !targetGroup.validity.fromDate)) ||
                  isPastCampaign,
                isHidden: !isDateRangeDisplayed,
                //SLATE-1458 Dec27 Anushree:- initialMonth should be taken from fromDate
                initialMonth: dateRangeMin,
                disabled: isPastCampaign,
              },
              {
                id: "startDate",
                type: allowModifyingStartDate ? FORM_FIELD_TYPE.DATE : FORM_FIELD_TYPE.INPUT,
                label: "Validity (Start Date)",
                title: "Validity (Start Date)",
                size: { lg: 2, md: 2 },
                onChange: (v) => onInputChange([v, null], "validity"),
                value: !allowModifyingStartDate
                  ? moment(targetGroup.validity.fromDate).format("MMM DD, YYYY")
                  : new Date(targetGroup.validity.fromDate),
                isHidden: isDateRangeDisplayed,
                minDate: startDateMin,
                maxDate: startDateMax,
                initialMonth: startDateMin,
                defaultValue: startDateMin,
                disabled: !allowModifyingStartDate,
              },
              {
                id: "endDate",
                type: FORM_FIELD_TYPE.DATE,
                title: "Validity (End Date)",
                size: { lg: 2, md: 2 },
                onChange: (v) => onInputChange([null, v], "validity"),
                value: new Date(targetGroup.validity.toDate),
                placeholder: "End Date",
                isHidden: isDateRangeDisplayed,
                minDate: endDateMin,
                maxDate: endDateMax,
                initialMonth: endDateMin,
                disabled:
                  endDateMax < endDateMin ||
                  endDateMin > moment(targetGroup.validity.toDate).toDate(),
                error: endDateMin > moment(targetGroup.validity.toDate).toDate(),
                errorMessage: "Incorrect Media Validity",
                defaultValue: endDateMin,
              },
              // {
              //   id: "maxPlaysPerScreen",
              //   title: "Max Plays Per Screen",
              //   type: FORM_FIELD_TYPE.NUMERIC_INPUT,
              //   size: {
              //     lg: 6,
              //     md: 6,
              //   },
              //   placeholder: "Enter Plays Per Screen",
              //   // errorMessage: "Please Enter Max Plays Per Screen",
              //   // error: hasFormSaved && !targetGroup?.maxPlaysPerScreen,
              //   onValueChange: (value) => onInputChange(value, "maxPlaysPerScreen"),
              //   value: targetGroup.maxPlaysPerScreen ? targetGroup.maxPlaysPerScreen : 0,
              // },
              // {
              //   id: "budgetedPlays",
              //   title: "Budgeted Plays",
              //   type: FORM_FIELD_TYPE.NUMERIC_INPUT,
              //   size: {
              //     lg: 6,
              //     md: 6,
              //   },
              //   placeholder: "Enter Budgeted Plays",
              //   value: targetGroup?.budgetedPlays || 0,
              //   // errorMessage: "Please Enter Budgeted Plays",
              //   // error: hasFormSaved && !targetGroup?.budgetedPlays,
              //   onValueChange: (value) => onInputChange(value, "budgetedPlays"),
              // },
            ]}
          />
        </div>
      </InfoBlock>
    );
  }
}
