import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { isEmpty } from "lodash";
import styles from "../../../../../styles/CampaignCreate/CampaignForm.module.scss";
import InfoBlock from "../../../../common/InfoBlock";
import Form from "../../../../common/Form";
import {
  CAMPAIGN_ACTIONS,
  CAMPAIGN_ACTIONS_ALLOWED_STATUS_LIST,
  FORM_FIELD_TYPE,
} from "../../../../../constants";

export default class BillingDetails extends Component {
  static propTypes = {
    campaign: PropTypes.object.isRequired,
  };

  static defaultProps = {
    billingCycles: [],
  };

  componentDidMount = () => {
    const { billingCycles, campaign } = this.props;
    //if campaign?.billingCycleId is not present then set first billing cycle as default
    if (billingCycles.length > 0 && !campaign?.billingCycleId) {
      this.onInputChange(billingCycles[0], "billingInfo billingCycleId");
    }
  };

  componentDidUpdate = (prevProps) => {
    const { billingCycles } = this.props;
    if (billingCycles.length > 0 && prevProps.billingCycles.length !== billingCycles.length) {
      this.onInputChange(billingCycles[0], "billingInfo billingCycleId");
    }
  };

  //method to update state and call parent method
  onInputChange = (value, accessor) => {
    this.setState({
      [accessor]: value,
    });
    this.props.onInputChange(value, accessor);
  };

  render() {
    const { hasFormSaved, campaign, billingCycles, orderIdType, orderRegions } = this.props;
    //Error should be shown if required details are not filled during campaign creation when id not available
    //const showError = hasFormSaved && campaign?.id && campaign?.name;
    const showError = hasFormSaved && campaign?.name;

    return (
      <InfoBlock header="Billing Details" childClassName={styles.childWrapper}>
        <div className={classNames("flex flex-wrap clearfix", styles.formContainer)}>
          <Form
            childContainerClassName={styles.childContainer}
            config={[
              {
                id: "billerName",
                label: "Biller Name",
                type: FORM_FIELD_TYPE.INPUT,
                className: "col-11",
                size: {
                  lg: 6,
                  md: 6,
                  sm: 6,
                },
                placeholder: "Enter Biller Name",
                value: campaign?.billingInfo?.billerName || "",
                // errorMessage: "Please Enter Biller Name",
                // error: showError && !campaign?.billingInfo?.billerName,
                onChange: (e) => this.onInputChange(e.target.value, "billingInfo billerName"),
                disabled: !CAMPAIGN_ACTIONS_ALLOWED_STATUS_LIST[
                  CAMPAIGN_ACTIONS.EDIT_BILLING_DETAILS
                ].includes(campaign.status),
                isOptional: true,
              },
              {
                id: "billingAddress",
                title: "Billing Address",
                type: FORM_FIELD_TYPE.TEXT_AREA,
                className: "col-11",
                size: {
                  lg: 6,
                  md: 6,
                  sm: 6,
                },
                rows: 2,
                placeholder: "Enter Billing Address",
                value: campaign?.billingInfo?.billingAddress || "",
                // errorMessage: "Please Enter Billing Address",
                // error: showError && !campaign?.billingInfo?.billingAddress,
                onChange: (e) => this.onInputChange(e.target.value, "billingInfo billingAddress"),
                disabled: !CAMPAIGN_ACTIONS_ALLOWED_STATUS_LIST[
                  CAMPAIGN_ACTIONS.EDIT_BILLING_DETAILS
                ].includes(campaign.status),
                isOptional: true,
              },
              {
                type: FORM_FIELD_TYPE.RADIO,
                title: "Billing Cycle",
                className: "col-11",
                isHidden: isEmpty(campaign?.validity) && billingCycles.length === 0,
                size: {
                  lg: 12,
                  md: 12,
                },
                placeholder: "Select Billing Cycle",
                value: campaign?.billingInfo?.billingCycleId || billingCycles[0]?.id,
                radioList: billingCycles.map((bc) => ({
                  label: bc.name,
                  value: bc.id,
                })),
                onChange: (v) =>
                  this.onInputChange(
                    billingCycles.find((bc) => bc.id === v),
                    "billingInfo billingCycleId"
                  ),
                disabled: !CAMPAIGN_ACTIONS_ALLOWED_STATUS_LIST[
                  CAMPAIGN_ACTIONS.EDIT_BILLING_DETAILS
                ].includes(campaign.status),
              },
              // {
              //   id: "paymentTermInDays",
              //   label: "Payment Term (in days)",
              //   type: FORM_FIELD_TYPE.INPUT,
              //   className: "col-11",
              //   size: {
              //     lg: 6,
              //     md: 6,
              //   },
              //   placeholder: "Enter Payment Term",
              //   value: campaign?.billingInfo?.paymentTermInDays || "",
              //   onChange: (e) =>
              //     this.onInputChange(parseInt(e.target.value), "billingInfo paymentTermInDays"),
              //   disabled: !CAMPAIGN_ACTIONS_ALLOWED_STATUS_LIST[
              //     CAMPAIGN_ACTIONS.EDIT_BILLING_DETAILS
              //   ].includes(campaign.status),
              // },
              [
                {
                  id: "orderRegion",
                  type: FORM_FIELD_TYPE.SELECT,
                  title: "Order Region",
                  className: "col-11",
                  size: {
                    lg: 3,
                    md: 3,
                  },
                  placeHolder: "Select Order Region",
                  list: orderRegions?.map((item) => ({
                    id: item.id,
                    value: item.name,
                    name: item.name,
                  })),
                  singleSelect: true,
                  showLabelInButton: false,
                  compareKey: "id",
                  textKey: "name",
                  selectedList: campaign?.billingInfo?.orderRegionName
                    ? [campaign?.billingInfo?.orderRegionName]
                    : undefined,
                  onSelect: ([data]) => {
                    this.onInputChange(data.value, "billingInfo orderRegionName");
                    this.onInputChange(data.id, "billingInfo orderRegionId");
                  },
                  isOptional: true,
                },
                {
                  id: "orderIdType",
                  type: FORM_FIELD_TYPE.SELECT,
                  title: "Order ID",
                  className: "col-11",
                  size: {
                    lg: 3,
                    md: 3,
                  },
                  placeholder: "Select Order ID",
                  list: orderIdType.map((item) => ({
                    id: item,
                    value: item,
                    name: item,
                  })),
                  singleSelect: true,
                  showLabelInButton: false,
                  compareKey: "id",
                  textKey: "name",
                  selectedList: campaign?.billingInfo?.orderIdType
                    ? [campaign?.billingInfo?.orderIdType]
                    : [orderIdType[0]],
                  onSelect: ([data]) => {
                    this.onInputChange(data.value, "billingInfo orderIdType");
                  },
                  isOptional: true,
                },
                {
                  id: "orderId",
                  title: "",
                  type: FORM_FIELD_TYPE.INPUT,
                  className: "col-11",
                  containerClassName: styles.orderId,
                  size: {
                    lg: 3,
                    md: 3,
                  },
                  placeholder: "Enter Order ID",
                  value: campaign?.billingInfo?.orderId || "",
                  onChange: (e) => this.onInputChange(e.target.value, "billingInfo orderId"),
                  disabled: !CAMPAIGN_ACTIONS_ALLOWED_STATUS_LIST[
                    CAMPAIGN_ACTIONS.EDIT_BILLING_DETAILS
                  ].includes(campaign.status),
                },
              ],
              {
                id: "isAdvancePaymentRequired",
                title: "Advance Payment",
                label: "Required",
                type: FORM_FIELD_TYPE.CHECK_BOX,
                className: "col-11",
                size: {
                  lg: 2,
                  md: 2,
                },
                //checked: !!campaign?.billingInfo?.isAdvancePaymentRequired,
                checked: !!campaign?.billingInfo?.isAdvanceAmountRequired,
                onChange: () =>
                  this.onInputChange(
                    //!campaign?.billingInfo?.isAdvancePaymentRequired,
                    //"billingInfo isAdvancePaymentRequired"
                    !campaign?.billingInfo?.isAdvanceAmountRequired,
                    "billingInfo isAdvanceAmountRequired"
                  ),
                disabled: !CAMPAIGN_ACTIONS_ALLOWED_STATUS_LIST[
                  CAMPAIGN_ACTIONS.EDIT_BILLING_DETAILS
                ].includes(campaign.status),
              },
              {
                id: "advanceAmountInFractionalUnit",
                title: "Advance Amount",
                type: FORM_FIELD_TYPE.NUMERIC_INPUT,
                size: {
                  lg: 3,
                  md: 3,
                },
                isHidden: !campaign?.billingInfo?.isAdvanceAmountRequired,
                placeholder: "Enter Advance Amount",
                value: campaign?.billingInfo?.advanceAmountInFractionalUnit || 0,
                onValueChange: (value) =>
                  this.onInputChange(value, "billingInfo advanceAmountInFractionalUnit"),
                errorMessage: "Please Enter Advance Amount",
                error:
                  showError &&
                  campaign?.billingInfo?.isAdvanceAmountRequired &&
                  !campaign?.billingInfo?.advanceAmountInFractionalUnit,
                disabled: !CAMPAIGN_ACTIONS_ALLOWED_STATUS_LIST[
                  CAMPAIGN_ACTIONS.EDIT_BILLING_DETAILS
                ].includes(campaign.status),
              },
              // {
              //   id: "isTaxExempted",
              //   title: "Tax",
              //   label: "Exempt Taxes",
              //   type: FORM_FIELD_TYPE.CHECK_BOX,
              //   className: "col-11",
              //   size: {
              //     lg: 12,
              //     md: 12,
              //   },
              //   //checked: !!campaign?.billingInfo?.isTaxExempted,
              //   checked: !!campaign?.billingInfo?.isExemptTax,
              //   onChange: () =>
              //     this.onInputChange(
              //       //     !campaign?.billingInfo?.isTaxExempted,
              //       //     "billingInfo isTaxExempted"
              //       !campaign?.billingInfo?.isExemptTax,
              //       "billingInfo isExemptTax"
              //     ),
              //   disabled: !CAMPAIGN_ACTIONS_ALLOWED_STATUS_LIST[
              //     CAMPAIGN_ACTIONS.EDIT_BILLING_DETAILS
              //   ].includes(campaign.status),
              // },
            ]}
          />
        </div>
      </InfoBlock>
    );
  }
}
