import classNames from "classnames";
import { isEmpty } from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import queryString from "query-string";
import React, { Component } from "react";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import {
  CAMPAIGN_ACTIONS,
  CAMPAIGN_ACTIONS_ALLOWED_STATUS_LIST,
  CAMPAIGN_STATUSES,
  CAMPAIGN_STEPS,
  POSITION_LIST,
} from "../../../../../constants";
import campaignFormStyles from "../../../../../styles/CampaignCreate/CampaignForm.module.scss";
import styles from "../../../../../styles/CampaignCreate/TargetGroup.module.scss";
import { bindDispatch, showToast } from "../../../../../utils";
import Breadcrumb from "../../../../common/BreadCrumb";
import EmptyPage from "../../../../common/EmptyPage";
import InfoBlockLoading from "../../../../common/InfoBlockLoading";
import PageLoadingWithTabs from "../../../../common/PageLoadingWithTabs";
import CampaignTree from "../../CampaignStructure/CampaignTree";
import CampaignPageHeader from "../../common/CampaignPageHeader";
import MediaDetails from "../../common/pages/MediaDetails";
import { campaignStepChange, validateMandatoryMediaFields } from "../../utils/campaignCreate";
import CampaignSteps from "../CampaignSteps";
import EmptyState from "../EmptyState";
import BasicDetails from "./BasicDetails";
import PlacementOptions from "./PlacementOptions";
import campaignRouteHandler from "..";

const positionUnavailabilityOptions = [
  {
    name: "PlayInNasOrNormal",
    displayName: "Play in next available spot followed by Normal position",
  },
  { name: "PlayInNormal", displayName: "Play in Normal position" },
  { name: "DoNotPlay", displayName: "Do not play" },
];

const modes = ["WeeklyPlayCount", "Advanced"];

export class Media extends Component {
  static propTypes = {
    isCampaignInvalid: PropTypes.bool,
  };

  static defaultProps = {
    mediaList: null,
    isCampaignInvalid: false,
  };

  getInitialState = () => ({
    media: {
      name: "",
      targetGroup: {},
      validity: [],
      contentId: "",
      contentTypeId: "",
      durationInSeconds: 0,
      brand: {},
      categories: [],
      categoryIds: [],
      weeklyPlayCount: 28,
      showPlayCount: 1,
      placements: [
        {
          playlistPackTypeId: "",
          segmentId: "",
          position: 0,
          numberOfPlays: 0,
          plays: [],
        },
      ],
      canPlayBackToBack: false,
      placementMode: modes[0],
      positionUnavailability: positionUnavailabilityOptions[0]?.name || "",
      status: CAMPAIGN_STATUSES.DRAFT,
    },
    placementoptions: {
      playlistPackTypes: [],
      segmentTypes: [],
      positions: [],
      positionUnavailabilityOptions,
      modes,
    },
    campaignStructure: {},
    isCampaignStructureLoading: true,
    isCampaignStructureError: false,
    isValidMedia: true,
    errors: [],
    isPageLoading: !this.props.isCampaignInvalid,
    hasFormSaved: false,
    isFormEdited: false,
    duplicateScheduleNumber: "",
  });

  state = this.getInitialState();

  componentDidMount = async () => {
    //this.props.match.params.action = "list";
    const {
      isCampaignInvalid,
      match: {
        params: { action, campaignId },
      },
      campaignCreate: { campaignMedia },
      actions,
      history,
      userData: {
        company: { id: companyId },
      },
    } = this.props;
    let mediaData = this.state.media;
    const query = queryString.parse(history.location.search);
    await actions.updateActiveStep({
      campaignStep: CAMPAIGN_STEPS.MEDIA,
      campaignId: campaignId,
    });

    if (isCampaignInvalid) return false;

    //SLATE-1459: asyc api calls called at the end of componentDidMount
    // await actions.getTimesOfDayList(this.props.match.params);
    // await actions.getCategories(this.props.match.params);
    if (action === "create") {
      if (!query?.id) {
        await actions.updateNewCampaignMedia(mediaData);
        await actions.updateMediaAction("create");
      } else {
        await actions.getMediaById(query?.id, "duplicate");
        if (isEmpty(campaignMedia)) {
          this.setState({
            isValidMedia: false,
            isPageLoading: false,
          });
        } else {
          mediaData = campaignMedia;
        }
      }
    } else if (action === "list") {
      await actions.updateMediaAction("list");
    } else {
      const media = await actions.getMediaById(action, "edit");
      if (isEmpty(media)) {
        this.setState({
          isValidMedia: false,
          isPageLoading: false,
        });
      } else {
        mediaData = media;
      }
    }
    //fetching segmentTypes and playlistPackTypes
    await actions.getPlaylistPackTypes();
    await actions.getSegmentTypes(companyId);
    const { segmentTypes, playlistPackTypes } = this.props;
    const placementoptions = {
      segmentTypes: segmentTypes.data.data,
      playlistPackTypes: playlistPackTypes.list.data,
      positions: POSITION_LIST,
      positionUnavailabilityOptions,
      modes,
    };
    this.setState({
      media: mediaData,
      isPageLoading: false,
      placementoptions,
    });
    await actions.getTimesOfDayList(this.props.match.params);
    await actions.getCategories(this.props.match.params);
  };

  componentDidUpdate(prevProps) {
    const {
      match: { params },
      campaignCreate: { mediaAction, campaignMedia },
      history,
      actions,
    } = this.props;

    //this is commented to make list as the first page the see when they click on media not create
    // if (
    //   prevProps.campaignCreate.campaignTargetGroups.data.length !== campaignMediaList.data.length
    // ) {
    //   if (campaignMediaList.data.length === 0 && mediaAction === "list") {
    //     history.push(`/campaign-create/${params.campaignId}/media/create`);
    //     actions.updateMediaAction("create");
    //   }
    // }

    // Detect changes in action (create, list)
    if (prevProps.match.params.action !== params.action) {
      if (params.action === "list") {
        this.setState({
          media: this.getInitialState().media,
          duplicateScheduleNumberMessage: "",
        });
        actions.updateMediaAction("list");
        actions.getNewCampaignMediaList(params);
      } else if (params.action === "create") {
        this.handleCampaignMediaCreate();
      }
    }

    // Handle the existing logic for mediaAction
    if (prevProps.campaignCreate.mediaAction !== mediaAction) {
      if (mediaAction === "list") {
        history.push(`/campaign-create/${params.campaignId}/media/list`);
        this.setState({
          media: this.getInitialState().media,
        });
      }

      if (mediaAction === "edit" || mediaAction === "duplicate") {
        if (mediaAction === "duplicate") {
          campaignMedia.status = CAMPAIGN_STATUSES.DRAFT;
        }
        if (mediaAction === "edit" && campaignMedia.id) {
          history.push(`/campaign-create/${params.campaignId}/media/${campaignMedia.id}`);
        }
        let numberOfPlays = 0;
        campaignMedia.placements.forEach((p) => {
          numberOfPlays =
            numberOfPlays +
            (p.numberOfPlays
              ? p.numberOfPlays
              : p.plays && p.plays.length !== 0
              ? p.plays?.reduce((acc, curr) => acc + curr.numberOfPlays, 0)
              : 0);
        });
        campaignMedia["totalNumberOfPlays"] = numberOfPlays;
        if (campaignMedia.placements)
          campaignMedia.placements = campaignMedia.placements.map((p, i) => ({
            ...p,
            index: i,
          }));
        this.setState({
          media: campaignMedia,
        });
      }
    }
  }

  // fetchCampaignTreeData = () => {
  //   const {
  //     match: { params },
  //   } = this.props;

  //   getCampaignTreeApi(params.campaignId)
  //     .then((campaignRes) =>
  //       this.setState({
  //         isCampaignStructureLoading: false,
  //         campaignStructure: campaignRes,
  //       })
  //     )
  //     .catch((err) => {
  //       this.setState({
  //         isCampaignStructureLoading: false,
  //         isCampaignStructureError: true,
  //         campaignStructure: null,
  //       });
  //     });
  // };

  onInputChange = (data, key, isDuplicate) => {
    //SLATE-1546 Feb7 Anushree:- Utilizing functional setState and spread operator for non-mutative state updates.
    this.setState((prevState) => {
      const media = { ...prevState.media };
      let { nameDuplicate } = prevState;
      if (key === "name") {
        media[key] = data;
        nameDuplicate = isDuplicate;
      } else if (key === "targetGroup") {
        //SLATE-1457: targetGroup object is required for campaign summary
        media.targetGroup = {
          name: data.name,
          targetGroupId: data.id,
          validity: data.validity,
          maxPlaysPerScreen: data.maxPlaysPerScreen,
          criteria: data.criteria,
        };
        media["targetGroupName"] = data.name;
        media.targetGroupId = data.id;
        // media.validity = data.validity;
      } else if (key === "validity") {
        const [fromDate, toDate] = data;
        const validity = {
          fromDate: fromDate
            ? moment(fromDate).format("YYYY-MM-DD")
            : media.validity?.fromDate ?? null,
          toDate: toDate ? moment(toDate).format("YYYY-MM-DD") : media.validity?.toDate ?? null,
        };
        media[key] = validity;
      } else if (key === "brandId") {
        //removing categories if brand is removed or changed
        if (!media.brandId || media.brandId !== data?.id) {
          media.categories = [];
          media.categoryIds = [];
        }
        media.brandName = data?.name;
        media[key] = data?.id;
        media.brandType = data?.type;
      } else if (key === "categoryIds") {
        // SLATE-1430 Akash Vinchnkar 08 Dec 23
        // If media[key] is already an array, use it; otherwise, initialize it as an empty array
        media[key] = media[key] || [];
        media.categories.push(data);
        const { subCategory2Id, subCategory1Id, categoryId } = data;
        media[key].push(subCategory2Id || subCategory1Id || categoryId || "");
      } else if (key === "placements") {
        media[key] = data;
        let numberOfPlays = 0;
        if (!media.placements) return;
        media.placements.forEach((p) => {
          numberOfPlays =
            numberOfPlays +
            (p.numberOfPlays
              ? p.numberOfPlays
              : p.plays && p.plays.length !== 0
              ? p.plays?.reduce((acc, curr) => acc + curr.numberOfPlays, 0)
              : 0);
        });
        media["totalNumberOfPlays"] = numberOfPlays;
      } else {
        media[key] = data;
      }
      return {
        media,
        nameDuplicate,
        isFormEdited: true,
      };
    });
  };

  handleCampaignDelete = async () => {
    const {
      history,
      match: { params },
    } = this.props;
    const resp = await this.props.actions.deleteCampaign(params.campaignId);
    if (resp && resp.success) {
      history.push(`/campaigns`);
    }
  };

  handleAddPlacement = () => {
    //SLATE-1546 Feb7 Anushree:- Utilizing functional setState and spread operator for non-mutative state updates.
    this.setState((prevState) => ({
      media: {
        ...prevState.media,
        placements: [...prevState.media.placements, { playsPerDay: [] }],
      },
    }));
  };
  handleRemovePlacement = (removedIndex) => {
    //SLATE-1546 Feb7 Anushree:- Utilizing functional setState and spread operator for non-mutative state updates.
    this.setState(
      (prevState) => {
        const newPlacements = prevState.media.placements.filter((_, i) => i !== removedIndex);
        if (newPlacements.length === 0) {
          newPlacements.push({
            playlistPackTypeId: "",
            segmentId: "",
            position: 0,
            numberOfPlays: 0,
            plays: [],
          });
        }
        return {
          media: {
            ...prevState.media,
            placements: newPlacements,
          },
        };
      },
      () => {
        this.onInputChange(this.state.media.placements, "placements");
      }
    );
  };

  handleCampaignMediaDelete = (mediaId) => {
    const {
      actions,
      match: { params },
    } = this.props;
    actions.deleteMedia(params, mediaId);
  };

  handleCampaignMediaCreate = async () => {
    const {
      actions,
      match: { params },
      history,
    } = this.props;
    history.push(`/campaign-create/${params.campaignId}/media/create`);
    //SLATE-1484 Jan3 Anushree:- reseting form to initial state on New Creation
    this.handleFormReset();
    this.setState({
      isCampaignStructureLoading: false,
    });
    await actions.updateMediaAction("create");
  };

  handleCampaignMediaEdit = (mediaId) => {
    const {
      match: { params },
      actions,
      history,
    } = this.props;
    if (params.campaignId) {
      actions.getMediaById(mediaId, "edit");
      history.push(`/campaign-create/${params.campaignId}/media/${mediaId}`);
    }
  };

  handleCreateMediaDuplicate = (mediaId) => {
    const {
      match: { params },
      actions,
      history,
    } = this.props;

    actions.getMediaById(mediaId, "duplicate");
    history.push(`/campaign-create/${params.campaignId}/media/create?id=${mediaId}`);
  };

  handleFormReset = () => {
    const {
      match: {
        params: { action },
      },
      campaignCreate: { campaignMedia },
    } = this.props;
    if (action !== "list" && action !== "create") {
      this.setState({
        media: campaignMedia,
        hasFormSaved: false,
        isFormEdited: false,
      });
    } else {
      this.setState({
        media: this.getInitialState().media,
        isPageLoading: false,
        hasFormSaved: false,
      });
    }
  };

  handleSaveMediaAsDraft = async () => {
    const { media } = this.state;
    const {
      match: { params },
      actions,
    } = this.props;

    if (media.name && media.targetGroupId) {
      media.campaignId = params.campaignId;
      await actions.createOrUpdateMedia(params.campaignId, media.targetGroupId, media, "edit");
    }

    this.setState({
      hasFormSaved: true,
      isFormEdited: false,
    });
  };

  handleChangeStep = async (desiredStep) => {
    const {
      match: { params },
      history,
      campaignCreate: { activeStep, mediaAction, campaignMediaList },
      actions,
    } = this.props;
    const { media, isFormEdited } = this.state;

    if (desiredStep === activeStep - 1) {
      if (mediaAction !== "list") {
        // Go to media list without decrementing the step
        history.push(`/campaign-create/${params.campaignId}/media/list`);
        return false;
      }
    }

    if (desiredStep === activeStep + 1 && !!params.campaignId) {
      if (mediaAction === "list") {
        //SLATE-1485 Jan3 Anushree:- Movement to Review page restricted if no Media has been created
        if (campaignMediaList.data.length > 0) {
          history.push(`/campaign-create/${params.campaignId}/review`);
          return false;
        } else {
          showToast("Please Create Media", false);
        }
      }
      if (params.action !== "list" && validateMandatoryMediaFields(media, mediaAction)) {
        media.campaignId = params.campaignId;
        //Only if form is edited then call update media api
        if (isFormEdited) {
          const responseMessage = await actions.createOrUpdateMedia(
            params.campaignId,
            media.targetGroupId,
            media,
            "list"
          );
          if (responseMessage.includes("Schedule Number already present")) {
            this.setState({
              duplicateScheduleNumberMessage: responseMessage,
            });
          }
        } else {
          actions.updateMediaAction("list");
          actions.getNewCampaignMediaList(params);
        }
      } else {
        //history.push(`/campaign-create/${params.campaignId}/review`);
      }
    } else {
      campaignStepChange(history, params, activeStep, desiredStep);
    }

    this.setState({
      hasFormSaved: true,
      isFormEdited: false,
    });
  };

  // TODO: Update URL and action once flow is completed
  handleFormSave = (data) => {
    const { actions } = this.props;
    actions.addTargetGroupMedia(data);
    actions.updateMediaEdit({}, false);
  };

  // function to remove single category from the list of categories or remove all categories
  handleRemoveCategory = (index) => {
    //Utilizing functional setState and spread operator for non-mutative state updates.
    this.setState((prevState) => {
      const media = { ...prevState.media };
      if (index === -1) {
        // Remove all elements when index is -1
        media.categoryIds = [];
        media.categories = [];
      } else {
        // Remove the element at the specified index
        media.categoryIds = media.categoryIds.filter(
          (categoryId, catIdIndex) => catIdIndex !== index
        );
        media.categories = media.categories.filter((cat, catIndex) => catIndex !== index);
      }
      return { media };
    });
  };

  renderCampaignTree = () => {
    const { isValidMedia } = this.state;
    const {
      campaignCreate: { mediaAction },

      match: {
        params: { campaignId },
      },
    } = this.props;

    if (this.props.isCampaignInvalid || !isValidMedia || mediaAction === "list") return null;

    return (
      <div className={campaignFormStyles.campaignTree}>
        <CampaignTree
          //isNewCampaign
          // isLoading={isCampaignStructureLoading}
          // campaignTreeData={{
          //   ...campaign,
          //   targetGroups: [
          //     {
          //       ...media.targetGroup,
          //       media: [media] || [{}],
          //     },
          //   ],
          // }}
          campaignIdForSummary={campaignId}
          //campaignCreate={campaignCreate}
        />
      </div>
    );
  };

  renderMediaContent = () => {
    const {
      isCampaignInvalid,
      timesOfDay: { data: timesOfDay },
      campaignCreate: {
        isMediaLoading,
        campaignMediaList,
        campaignTargetGroups,
        mediaAction,
        campaign,
        isMediaDetailsLoading,
        campaignMedia,
      },
      categories: {
        categories: { data: categoryList },
      },
      actions,
      match: { params },
    } = this.props;
    const {
      media,
      isPageLoading,
      hasFormSaved,
      isValidMedia,
      placementoptions,
      duplicateScheduleNumberMessage,
    } = this.state;
    if (isCampaignInvalid || !isValidMedia)
      return <EmptyState type={isValidMedia ? "campaign" : "media"} />;

    if (isPageLoading) return <InfoBlockLoading />;

    if (mediaAction === "list") {
      return (
        <MediaDetails
          params={params}
          isLoading={isMediaLoading}
          withCampaignTree
          isDetailsLoading={isMediaDetailsLoading}
          timesOfDay={timesOfDay}
          mediaList={campaignMediaList.data}
          getMediaDetails={actions.getMediaById}
          onEdit={this.handleCampaignMediaEdit}
          onDuplicate={this.handleCreateMediaDuplicate}
          onDelete={this.handleCampaignMediaDelete}
          onMediaCreate={this.handleCampaignMediaCreate}
          showAdd
        />
      );
    }
    if (!CAMPAIGN_ACTIONS_ALLOWED_STATUS_LIST[CAMPAIGN_ACTIONS.EDIT].includes(media.status))
      return (
        <EmptyPage
          heading="EmptyState.mediaNotEditableHeading"
          body="EmptyState.mediaNotEditableMessage"
          isHomeLinkVisible={false}
        />
      );
    if (media.name === "" && mediaAction !== "create" && mediaAction !== "duplicate") return;
    return (
      <div className={styles.formContainer}>
        <div className={styles.formContent}>
          <BasicDetails
            campaign={campaign}
            hasFormSaved={hasFormSaved}
            categoryList={categoryList}
            mediaList={campaignMediaList.data}
            media={media}
            categorySearch={actions.categorySearch}
            onInputChange={this.onInputChange}
            removeCategory={this.handleRemoveCategory}
            targetGroups={campaignTargetGroups?.data}
            duplicateScheduleNumberMessage={duplicateScheduleNumberMessage}
          />
          <PlacementOptions
            timesOfDay={timesOfDay}
            hasFormSaved={hasFormSaved}
            media={media}
            onInputChange={this.onInputChange}
            addNewPlacement={this.handleAddPlacement}
            placementoptions={placementoptions}
            campaignMedia={campaignMedia}
            removePlacement={this.handleRemovePlacement}
            getSegmentTypes={actions.getSegmentTypes}
          />
        </div>
      </div>
    );
  };

  render() {
    const {
      history,
      campaignCreate: { campaign, isMediaLoading, activeStep, campaignMediaList, mediaAction },
    } = this.props;
    const { hasFormSaved, isFormEdited } = this.state;

    const headerTitle =
      !campaign.id || (mediaAction === "list" && !campaign.id)
        ? "PageHeader.newMedia"
        : campaign.name;

    if (isMediaLoading) return <PageLoadingWithTabs />;
    return (
      <div className={`col-12 clearfix ${styles.pageWrapper}`}>
        <div className={styles.container}>
          <Breadcrumb
            history={history}
            links={[
              { name: "PageHeader.campaigns", path: "/campaigns" },
              { name: headerTitle, path: `/campaigns/campaigns/${campaign.id}/details` },
              { name: "PageHeader.editMedia" },
            ]}
          />
          <div className={classNames("col-12 clearfix")}>
            <div className={campaignFormStyles.campaignCreateProgressBar}>
              <CampaignPageHeader
                title={headerTitle}
                onSaveCampaignAsDraft={this.handleSaveMediaAsDraft}
                onDiscardCampaign={this.handleCampaignDelete}
                isDiscardDisabled={!campaign.id || campaign.status !== CAMPAIGN_STATUSES.DRAFT}
                hideDraftButton
              />
              <CampaignSteps
                activeStep={activeStep}
                // validTillStep={campaignMediaList.data.length > 0 ? activeStep + 2 : activeStep}
                validTillStep={campaignMediaList.data.length > 0 ? activeStep + 1 : activeStep}
                hasFormSaved={hasFormSaved}
                onChangeStep={this.handleChangeStep}
                onSaveDraft={this.handleSaveMediaAsDraft}
                onDiscard={this.handleFormReset}
                isAdminUser={this.validateCreateCampaignAccess}
                disableReset={!isFormEdited}
                onReset={this.handleFormReset}
              />
            </div>
            <div className={classNames("col col-12", campaignFormStyles.pageContent)}>
              {this.renderMediaContent()}
              {this.renderCampaignTree()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = createSelector(
  (state) => state.userData,
  (state) => state.campaignCreate,
  (state) => state.timesOfDay,
  (state) => state.categories,
  (state) => state.playlistPackTypes,
  (state) => state.segmentTypes,
  (userData, campaignCreate, timesOfDay, categories, playlistPackTypes, segmentTypes) => ({
    userData: userData.user,
    campaignCreate,
    timesOfDay,
    categories,
    playlistPackTypes,
    segmentTypes,
  })
);

export default connect(mapStateToProps, bindDispatch)(campaignRouteHandler(Media));
