import React, { Component } from "react";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import { Table } from "workbench";
import queryString from "query-string";
import classNames from "classnames";
import pageStyles from "../../../styles/App.module.scss";
import {
  bindDispatch,
  getBusinessType,
  modifyTableColumns,
  lastUpdatedAtColumn,
  lastUpdatedByColumn,
} from "../../../utils";
import {
  DEFAULT_ACTION_ICON_COLUMN,
  TAG_TYPE,
  DEFAULT_PAGE_SIZE,
  SPECIAL_CHARACTERS,
} from "../../../constants";
import { AUDIT_LOG_SOURCE } from "../logs/logSource";
import WithFetchList from "../../hoc/withFetchList";
import Breadcrumb from "../../common/BreadCrumb";
import HeaderButtons from "../../common/HeaderButtons";
import PageHeader from "../../common/PageHeader";
import PageLoadingWithTable from "../../common/PageLoadingWithTable";
import TableLoading from "../../common/TableLoading";
import TableControls from "../../common/TableControls";
import FilterChips from "../../common/Filters/FilterChips";
import ActionIcon from "../../common/ActionIcon";
import FooterControls from "../../common/FooterControls";
import { ActiveTableCell, ActiveTableCellRowFromArray } from "../../common/ActiveTableCell";

const _LINKS = [
  { name: "Brand Verifications", path: "/brand-verifications" },
  { name: "Rejected Brand Verifications" },
];
class RejectedBrandList extends Component {
  state = {
    selectedColumns: ["Brand", "Type", "Company", "Rejected By", "Rejected On", "Reason"],
  };

  componentDidMount = () => {
    this.props.fetchData();
    this.props.actions.getBusinessTypesList();
  };

  onColumnSelect = (selectedColumns, reorderedColumns) =>
    this.setState({ selectedColumns, reorderedColumns });

  renderBrands = (brand, onClick) => {
    const brands = [
      { id: brand.parentBrandId, name: brand.parentBrandName },
      { id: brand.individualBrandId, name: brand.individualBrandName },
      { id: brand.productId, name: brand.productName },
      { id: brand.variantId, name: brand.variantName },
    ].filter((b) => b.id);
    return ActiveTableCellRowFromArray(brands, onClick, SPECIAL_CHARACTERS.MIDDLE_DOT);
  };

  render() {
    const {
      history,
      brandVerifications: { isLoading, rejectedBrandVerificationList },
      businessTypes: { list },
      filters,
      onFilterIdSelect,
      page,
      ps,
      sort,
      onSortedChange,
      onQueryChange,
      isFilterLoading,
      userData,
    } = this.props;
    if (isLoading && !rejectedBrandVerificationList.data) return <PageLoadingWithTable />;

    const { selectedColumns, reorderedColumns } = this.state;
    const query = queryString.parse(history.location.search);
    const activeTableCell = (id, value) => ActiveTableCell(id, value, () => onFilterIdSelect(id));
    const columns = [
      {
        id: "parentBrandName;individualBrandName;productName;variantName",
        Header: "Brand",
        accessor: (d) => this.renderBrands(d, onFilterIdSelect),
      },
      {
        id: "brandType",
        Header: "Brand Type",
        accessor: (d) => activeTableCell(d.businessTypeTag, getBusinessType(list, d.businessType)),
      },
      {
        id: "submittedByCompany",
        Header: "Company",
        accessor: (d) => activeTableCell(d.submittedByCompany.id, d.submittedByCompany.name),
      },
      lastUpdatedByColumn(this.props.onFilterIdSelect, "Rejected By"),
      lastUpdatedAtColumn("Rejected On"),
      {
        id: "reason",
        Header: "Reason",
        accessor: (d) => activeTableCell(d.rejectionReasonId, d.rejectionReason),
      },
      {
        ...DEFAULT_ACTION_ICON_COLUMN,
        Cell: (props) => (
          <ActionIcon
            iconProps={[
              {
                toolTip: "Tooltip.view",
                url: `/brand-verifications/rejected/${props.original.brandType}/${props.original.draftId}`,
                iconName: "ViewIcon",
              },
            ]}
          />
        ),
      },
    ];

    return (
      <div className="col-12 clearfix">
        <Breadcrumb history={history} links={_LINKS} />
        <div className={pageStyles.pageContainer}>
          <div className={classNames("col-12 clearfix", pageStyles.pageWrapper)}>
            <PageHeader
              name="PageHeader.rejectedBrandVerifications"
              count={rejectedBrandVerificationList.totalCount}
              renderRightSideComponent={() => (
                <HeaderButtons
                  history={history}
                  logsButton
                  logsPath={`/logs/source/${AUDIT_LOG_SOURCE.BRAND_VERIFICATIONS}`}
                  userData={userData}
                />
              )}
            />
            <TableControls
              searchBar
              columnFilter
              showRowSize
              pagination
              columns={columns}
              selectedColumns={selectedColumns}
              reorderedColumns={reorderedColumns}
              data={rejectedBrandVerificationList}
              query={""}
              ps={ps}
              page={page}
              tagTypes={[
                TAG_TYPE.BRAND,
                TAG_TYPE.BUSINESS_TYPE,
                TAG_TYPE.COMPANY,
                TAG_TYPE.UPDATED_AT,
              ]}
              onSearchFilterSelect={this.props.onFilterSelect}
              onFilterChange={this.props.onFilterChange}
              onColumnFilterSelect={this.onColumnSelect}
              onRowSizeChange={onQueryChange}
              filters={filters}
            />
            <FilterChips
              selected={filters}
              showResultsCount
              resultsCount={rejectedBrandVerificationList.totalCount}
              onFilterChange={this.props.onFilterChange}
              loading={isLoading || isFilterLoading}
            />
            <Table
              columns={modifyTableColumns(columns, selectedColumns, reorderedColumns)}
              loading={isLoading || isFilterLoading}
              data={rejectedBrandVerificationList.data}
              defaultPageSize={DEFAULT_PAGE_SIZE}
              sorted={sort}
              onSortedChange={onSortedChange}
              LoadingComponent={<TableLoading columns={columns} />}
              onRowClick={({ original }) =>
                history.push(
                  `/brand-verifications/rejected/${original.brandType}/${original.draftId}`
                )
              }
            />
            <FooterControls
              pagination
              data={rejectedBrandVerificationList}
              query={query}
              ps={ps}
              page={page}
              onRowSizeChange={onQueryChange}
            />
          </div>
        </div>
      </div>
    );
  }
}

export const RejectedVerificationList = WithFetchList("getRejectedBrandVerificationList", {
  sort: [{ id: "updatedAt", desc: true }],
})(RejectedBrandList);

const mapStateToProps = createSelector(
  (state) => state.brandVerifications,
  (state) => state.businessTypes,
  (state) => state.userData,
  (brandVerifications, businessTypes, userData) => ({
    brandVerifications,
    businessTypes,
    userData: userData.user,
  })
);

export default connect(mapStateToProps, bindDispatch)(RejectedVerificationList);
