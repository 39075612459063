import classNames from "classnames";
import { isEmpty } from "lodash";
import { Icons } from "prefab";
import queryString from "query-string";
import React, { Component } from "react";
import { FormattedNumber } from "react-intl";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { createSelector } from "reselect";
import { ExpandTable } from "workbench";
import { getTargetGroup } from "../../../../../api";
import {
  CAMPAIGN_ACTIONS,
  CAMPAIGN_ACTIONS_ALLOWED_STATUS_LIST,
  CAMPAIGN_STATUSES,
  CAMPAIGN_TYPE,
  DEFAULT_ACTION_ICON_COLUMN,
  DEFAULT_PAGE_SIZE,
  SCOPES,
  SCOPE_ACTIONS,
  TAG_TYPE,
} from "../../../../../constants";
import pageStyles from "../../../../../styles/App.module.scss";
import styles from "../../../../../styles/Campaigns/Campaigns.module.scss";
import {
  bindDispatch,
  checkScopeAuth,
  checkScopes,
  createdAtColumn,
  createdByColumn,
  formatDateTime,
  getParams,
  lastUpdatedAtColumn,
  lastUpdatedByColumn,
  modifyTableColumns,
} from "../../../../../utils";
import ActionIcon from "../../../../common/ActionIcon";
import { ActiveTableCell } from "../../../../common/ActiveTableCell";
import BulkActionButtons from "../../../../common/BulkActionButtons";
import FilterChips from "../../../../common/Filters/FilterChips";
import FooterControls from "../../../../common/FooterControls";
import LocalizedButton from "../../../../common/LocalizedButton";
import PageLoadingWithTable from "../../../../common/PageLoadingWithTable";
import TableControls from "../../../../common/TableControls";
import TableLoading from "../../../../common/TableLoading";
import WithFetchList from "../../../../hoc/withFetchList";
import AddMaxPlaysPerScreen from "../../actions/AddMaxPlaysPerScreen";
import CancelCampaignTargetGroup from "../../bulkActions/CancelCampaigns";
import MoveCampaignTargetGroup from "../../bulkActions/MoveCampaigns";
import PauseOrResumeActions from "../../bulkActions/PauseOrResumeActions";
import {
  getStatus,
  getStatusIconLegend,
  isCampaignStatusChangesAvailable,
  validateCampaignsAction,
} from "../../utils";
import { combineDateTimeToISO } from "../../../../../utils/formatDateTime";

const { CancelIcon, PauseIcon, PlayIcon, EditIcon, RemoveIcon, SwapIcon } = Icons;

class CampaignTargetGroupsList extends Component {
  state = {
    selectedColumns: [
      "Target Group",
      "Start Date",
      "End Date",
      "Max Plays per Screen",
      "Theatres",
      "Status",
    ],
    selection: {
      parentSelection: [],
      childSelection: [],
    },
    openedBulkAction: null,
    currentCampaign: {},
    showAddMaxPlaysPerScreenPanel: false,
  };
  componentDidMount = () => {
    const {
      history,
      match: { params },
    } = this.props;
    this.props.fetchData(null, true, [{ campaignId: params.campaignId }]);
    const query = queryString.parse(history.location.search);
    if (params.action === "addMaxPlaysPerScreen" && query.editTargetGroupId) {
      this.setState({
        showAddMaxPlaysPerScreenPanel: true,
      });
    }
  };

  onColumnSelect = (selectedColumns, reorderedColumns) =>
    this.setState({ selectedColumns, reorderedColumns });

  onRowSelect = (selection) =>
    this.setState({
      selection,
    });

  handleCampaignAction = (action, props) => {
    this.setState({
      openedBulkAction: action,
      currentCampaign: props,
    });
  };
  closeBulkAction = () => {
    this.setState({ openedBulkAction: null });
  };

  openBulkAction = (bulkAction) => {
    this.setState({ openedBulkAction: bulkAction });
  };

  //Fnc to handle campaign clear cancel action from individual drop down menu selection
  handleCampaignCancelAction = (props) => {
    const {
      ps,
      page,
      sort,
      campaigns: { campaign },
      filters,
    } = this.props;
    this.props.actions.cancelCampaigns(
      { ids: [props.id], fromDate: "", clearCancellation: true },
      CAMPAIGN_TYPE.TARGET_GROUP,
      {
        ...getParams({
          ps,
          page,
          sort,
        }),
        campaignId: campaign.id,
      },
      () => {},
      false,
      filters
    );
  };

  handleCampaignClearAction = (action, props) => {
    const { ps, page, sort, filters } = this.props;
    this.props.actions.pauseOrResumeTargetGroups(
      [props.id],
      action,
      {
        clearPause: true,
      },
      {
        ...getParams({
          ps,
          page,
          sort,
        }),
        campaignId: props.campaignId,
      },
      filters
    );
  };

  updatePageUrl = (id = undefined, isPanelOpen = false) => {
    const {
      history,
      match: { params },
    } = this.props;
    const search = queryString.stringify({
      ...queryString.parse(history.location.search),
      editTargetGroupId: id,
    });
    if (isPanelOpen) {
      history.push(
        `/campaigns/campaigns/${params.campaignId}/target-groups/addMaxPlaysPerScreen?${search}`
      );
    } else {
      history.replace(`/campaigns/campaigns/${params.campaignId}/target-groups?${search}`);
    }
  };

  handleAddMaxPlaysPerScreen = (id) => {
    this.updatePageUrl(id, true);
    this.setState({
      showAddMaxPlaysPerScreenPanel: true,
    });
  };

  handleCloseAddMaxPlaysPerScreenPopup = () => {
    this.updatePageUrl();
    this.setState({
      showAddMaxPlaysPerScreenPanel: false,
    });
  };

  updateTargetGroupMaxPlaysPerScreen = (data) => {
    this.props.actions.updateTargetGroupDetails(data);
    this.updatePageUrl();
    this.setState({
      showAddMaxPlaysPerScreenPanel: false,
    });
  };

  renderMaxPlaysPerScreenValue = (props) => {
    const { userData } = this.props;
    const hasWriteScope = checkScopeAuth(userData, SCOPES.CAMPAIGNS, SCOPE_ACTIONS.WRITE);
    if (props.original.status === CAMPAIGN_STATUSES.CONSUMED && hasWriteScope) {
      return (
        <div className={styles.updateButtonWrapper}>
          <LocalizedButton
            id="edit"
            iconName="AddIcon"
            className={styles.updateButton}
            onClick={() => this.handleAddMaxPlaysPerScreen(props.original.id)}
            text="Button.update"
            scope={SCOPES.CAMPAIGNS}
            scopeAction={SCOPE_ACTIONS.WRITE}
            userData={userData}
          />
        </div>
      );
    }
    return this.renderNumber(props.value);
  };

  renderIconColumn = (props, url, isChild = false) => {
    const {
      campaigns: {
        campaign: { status: campaignStatus },
      },
    } = this.props;
    return (
      <ActionIcon
        iconProps={checkScopes(
          [
            {
              toolTip: "Tooltip.view",
              url: url,
              iconName: "ViewIcon",
            },
            {
              toolTip: "Tooltip.moreActions",
              iconName: "MoreVerticalIcon",
              dropdown: [
                {
                  text: "Edit",
                  onClick: () =>
                    this.props.history.push(
                      `/campaign-create/${props.campaignId}/target-groups/${props.id}`
                    ),
                  icon: <EditIcon />,
                  hideMenuItem:
                    isChild ||
                    !CAMPAIGN_ACTIONS_ALLOWED_STATUS_LIST[CAMPAIGN_ACTIONS.EDIT].includes(
                      props.status
                    ),
                },
                // {
                //   text: "Logs",
                //   onClick: () => this.props.history.push(`/logs/ref/${props.id}`),
                //   icon: <LogsIcon />,
                //   scope: SCOPES.LOGS,
                // },
                {
                  text: "Pause",
                  onClick: () => this.handleCampaignAction(CAMPAIGN_ACTIONS.PAUSE, props),
                  hideMenuItem:
                    isChild ||
                    !CAMPAIGN_ACTIONS_ALLOWED_STATUS_LIST[CAMPAIGN_ACTIONS.PAUSE].includes(
                      props.status
                    ),
                  icon: <PauseIcon />,
                },
                {
                  text: "Clear Pause",
                  onClick: () => this.handleCampaignClearAction(CAMPAIGN_ACTIONS.PAUSE, props),
                  hideMenuItem:
                    isChild ||
                    !CAMPAIGN_ACTIONS_ALLOWED_STATUS_LIST[CAMPAIGN_ACTIONS.PAUSE].includes(
                      props.status
                    ),
                  icon: <RemoveIcon />,
                },
                {
                  text: "Resume",
                  onClick: () =>
                    this.setState({
                      openedBulkAction: CAMPAIGN_ACTIONS.RESUME,
                      currentCampaign: props,
                    }),
                  hideMenuItem:
                    isChild ||
                    !CAMPAIGN_ACTIONS_ALLOWED_STATUS_LIST[CAMPAIGN_ACTIONS.RESUME].includes(
                      props.status
                    ),
                  icon: <PlayIcon />,
                },
                // {
                //   text: "Clear Resume",
                //   onClick: () => this.handleCampaignClearAction(CAMPAIGN_ACTIONS.RESUME, props),
                //   hideMenuItem:
                //     isChild ||
                //     !CAMPAIGN_ACTIONS_ALLOWED_STATUS_LIST[CAMPAIGN_ACTIONS.RESUME].includes(
                //       props.status
                //     ),
                //   icon: <CancelIcon />,
                // },
                {
                  text: "Cancel",
                  onClick: () => this.handleCampaignAction(CAMPAIGN_ACTIONS.CANCEL, props),
                  hideMenuItem: Boolean(
                    isChild ||
                      !CAMPAIGN_ACTIONS_ALLOWED_STATUS_LIST[CAMPAIGN_ACTIONS.CANCEL].includes(
                        props.status
                      )
                  ),
                  icon: <CancelIcon />,
                },
                {
                  text: "Clear Cancel",
                  onClick: () => this.handleCampaignCancelAction(props),
                  hideMenuItem: Boolean(
                    isChild ||
                      !CAMPAIGN_ACTIONS_ALLOWED_STATUS_LIST[CAMPAIGN_ACTIONS.CANCEL].includes(
                        props.status
                      )
                  ),
                  icon: <RemoveIcon />,
                },
                {
                  text: "Move",
                  onClick: () => this.handleCampaignAction(CAMPAIGN_ACTIONS.MOVE, props),
                  //hideMove if tg!==draft and the campaignStatus is not in the list of allowed status
                  hideMenuItem: !(
                    props.status === CAMPAIGN_STATUSES.DRAFT &&
                    CAMPAIGN_ACTIONS_ALLOWED_STATUS_LIST[CAMPAIGN_ACTIONS.MOVE].includes(
                      campaignStatus
                    )
                  ),
                  icon: <SwapIcon />,
                },
              ],
            },
          ],
          this.props.userData
        )}
      />
    );
  };

  renderNumber(value) {
    if (isNaN(value)) {
      return <span></span>;
    }

    return <FormattedNumber value={value} />;
  }

  render = () => {
    const {
      match: { params },
      history,
      campaigns: { campaign, campaignTargetGroups, isTabLoading },
      filters,
      sort,
      ps,
      page,
      onSortedChange,
      onQueryChange,
      isFilterLoading,
      userData,
    } = this.props;
    if (isTabLoading) return <PageLoadingWithTable />;

    const {
      selectedColumns,
      reorderedColumns,
      selection,
      openedBulkAction,
      currentCampaign,
      showAddMaxPlaysPerScreenPanel,
    } = this.state;
    const query = queryString.parse(history.location.search);
    const resultsCount = campaignTargetGroups.totalCount;
    const selectionInfo = {
      count: selection.parentSelection.length,
      label: selection.parentSelection.length === 1 ? "Media" : "Medias",
    };
    const selectedTargetGroupData =
      selection.parentSelection.length > 0 && isEmpty(currentCampaign)
        ? campaignTargetGroups.data?.filter((row) => selection.parentSelection.includes(row.id))
        : [currentCampaign];
    // const isPaused = validateCampaignsAction(
    //   campaignTargetGroups,
    //   selection,
    //   CAMPAIGN_ACTIONS.PAUSE
    // );
    //isPaused: determined by current bulk action
    const isPaused = openedBulkAction === CAMPAIGN_ACTIONS.PAUSE;

    // const isEdit = isCampaignStatusChangesAvailable(selectedTargetGroupData, openedBulkAction);
    const isEdit = isCampaignStatusChangesAvailable(
      selectedTargetGroupData,
      isPaused ? CAMPAIGN_STATUSES.PAUSED : CAMPAIGN_STATUSES.ACTIVE
    );
    const targetGroupHeader =
      isEdit && isPaused
        ? "RightPanelHeader.editPauseTargetGroup"
        : isEdit
        ? "RightPanelHeader.editResumeTargetGroup"
        : isPaused
        ? "RightPanelHeader.pauseTargetGroup"
        : "RightPanelHeader.resumeTargetGroup";
    const columns = [
      {
        id: "name",
        Header: "Target Group",
        accessor: "name",
        width: 200,
        parentCell: (props) => <div>{props.original.name}</div>,
        childCell: (props) => <div>Media: {props.original.name}</div>,
      },
      createdByColumn(this.props.onFilterIdSelect),
      {
        id: "startDate",
        Header: "Start Date",
        showTimeZone: true,
        accessor: (d) =>
          formatDateTime(combineDateTimeToISO(d.validity.fromDate, d.validity.startTime)),
      },
      {
        id: "endDate",
        Header: "End Date",
        showTimeZone: true,
        accessor: (d) =>
          formatDateTime(combineDateTimeToISO(d.validity.toDate, d.validity.endTime)),
      },
      {
        id: "countTheatres",
        Header: "Theatres",
        accessor: "countTheatres",
        Cell: (props) => (props.value ? this.renderNumber(props.value) : null),
      },
      {
        id: "status",
        Header: "Status",
        accessor: (d) => getStatus(d, this.props.onFilterSelect),
        width: 150,
      },
      {
        id: "estimatedReach",
        Header: "Estimated Reach",
        accessor: "estimatedReach",
        Cell: (props) => this.renderNumber(props.value),
      },
      {
        id: "actualReach",
        Header: "Actual Reach",
        accessor: "actualReach",
        Cell: (props) => this.renderNumber(props.value),
      },
      {
        id: "id",
        Header: "ID",
        accessor: (d) => ActiveTableCell(d.id, d.code, () => this.props.onFilterIdSelect(d.id)),
        width: 80,
      },
      {
        id: "uuid",
        Header: "UUID",
        accessor: (d) => ActiveTableCell(d.id, d.id, () => this.props.onFilterIdSelect(d.id)),
        width: 100,
      },
      // {
      //   id: "country",
      //   Header: "Country",
      //   accessor: (d) =>
      //     ActiveTableCell(d.countryId, d.countryName, () =>
      //       this.props.onFilterIdSelect(d.countryId)
      //     ),
      //   width: 100,
      // },
      {
        id: "maxPlays",
        Header: "Max Plays",
        accessor: "maxPlays",
        Cell: (props) => this.renderNumber(props.value),
        width: 150,
      },
      {
        id: "maxPlaysPerScreen",
        Header: "Max Plays per Screen",
        accessor: "maxPlaysPerScreen",
        Cell: (props) => this.renderMaxPlaysPerScreenValue(props),
      },
      {
        id: "pendingPlays",
        Header: "Pending",
        accessor: "pendingPlays",
        Cell: (props) => this.renderNumber(props.value),
        width: 150,
      },
      {
        id: "playedPlays",
        Header: "Played",
        accessor: "playedPlays",
        Cell: (props) => this.renderNumber(props.value),
        width: 150,
      },
      {
        id: "failedPlays",
        Header: "Failed",
        accessor: "failedPlays",
        Cell: (props) => this.renderNumber(props.value),
        width: 120,
      },
      createdAtColumn("Created On"),
      lastUpdatedByColumn(this.props.onFilterIdSelect),
      lastUpdatedAtColumn(),

      {
        ...DEFAULT_ACTION_ICON_COLUMN,
        width: 96,
        parentAccessor: (props) =>
          this.renderIconColumn(props, `/campaigns/target-groups/${props.id}`),
        childAccessor: (props) =>
          this.renderIconColumn(props, `/campaigns/media/${props.id}`, true),
      },
    ];

    return (
      <div className={classNames("col-12 clearfix", pageStyles.pageTableContainer)}>
        <div className={pageStyles.statusGroup}>
          {getStatusIconLegend(
            campaignTargetGroups.data,
            TAG_TYPE.CAMPAIGN_STATUS,
            this.props.onFilterSelect
          )}
        </div>
        <BulkActionButtons
          isOpen={selection.parentSelection.length > 0}
          selection={[selectionInfo]}
          buttons={[
            {
              text: "Button.pauseTargetGroup",
              onClick: () => {
                this.openBulkAction(CAMPAIGN_ACTIONS.PAUSE);
              },
              icon: "PauseIcon",
              isHidden: !validateCampaignsAction(
                campaignTargetGroups,
                selection,
                CAMPAIGN_ACTIONS.PAUSE
              ),
              checkScope: true,
              scope: SCOPES.CAMPAIGNS,
              scopeAction: SCOPE_ACTIONS.WRITE,
              userData,
            },
            {
              text: "Button.resumeTargetGroup",
              onClick: () => {
                this.openBulkAction(CAMPAIGN_ACTIONS.RESUME);
              },
              icon: "PlayIcon",
              isHidden: !validateCampaignsAction(
                campaignTargetGroups,
                selection,
                CAMPAIGN_ACTIONS.RESUME
              ),
              checkScope: true,
              scope: SCOPES.CAMPAIGNS,
              scopeAction: SCOPE_ACTIONS.WRITE,
              userData,
            },
            {
              text: "Button.cancelTargetGroup",
              onClick: () => {
                this.openBulkAction(CAMPAIGN_ACTIONS.CANCEL);
              },
              icon: "CancelIcon",
              isHidden: !validateCampaignsAction(
                campaignTargetGroups,
                selection,
                CAMPAIGN_ACTIONS.CANCEL
              ),
              checkScope: true,
              scope: SCOPES.CAMPAIGNS,
              scopeAction: SCOPE_ACTIONS.CANCEL,
              userData,
            },
          ]}
          onClose={() => {
            this.onRowSelect({
              parentSelection: [],
              childSelection: [],
            });
          }}
        />
        <TableControls
          searchBar
          columnFilter
          showRowSize
          pagination
          columns={columns}
          selectedColumns={selectedColumns}
          reorderedColumns={reorderedColumns}
          data={campaignTargetGroups}
          query={query}
          ps={ps}
          page={page}
          tagTypes={[TAG_TYPE.TARGET_GROUP, TAG_TYPE.CAMPAIGN_STATUS]}
          onSearchFilterSelect={this.props.onFilterSelect}
          onFilterChange={this.props.onFilterChange}
          onColumnFilterSelect={this.onColumnSelect}
          onRowSizeChange={onQueryChange}
          filters={filters}
        />
        <FilterChips
          selected={filters}
          showResultsCount
          resultsCount={resultsCount}
          onFilterChange={this.props.onFilterChange}
        />
        <ExpandTable
          data={campaignTargetGroups.data}
          loading={isTabLoading || isFilterLoading}
          columns={modifyTableColumns(columns, selectedColumns, reorderedColumns)}
          defaultPageSize={DEFAULT_PAGE_SIZE}
          sorted={sort}
          onSortedChange={onSortedChange}
          // selectable={true}
          selection={selection}
          onSelect={this.onRowSelect}
          childIdentificationKey="media"
          LoadingComponent={
            <TableLoading
              columns={modifyTableColumns(columns, selectedColumns, reorderedColumns)}
            />
          }
          onRowClick={({ original }) => history.push(`/campaigns/target-groups/${original.id}`)}
          onChildRowClick={({ original }) => history.push(`/campaigns/media/${original.id}`)}
        />
        <FooterControls
          pagination
          data={campaignTargetGroups}
          query={query}
          ps={ps}
          page={page}
          onRowSizeChange={onQueryChange}
        />
        {(openedBulkAction === CAMPAIGN_ACTIONS.RESUME ||
          openedBulkAction === CAMPAIGN_ACTIONS.PAUSE) && (
          <PauseOrResumeActions
            header={targetGroupHeader}
            selectionInfo={
              selection.parentSelection.length > 0 && isEmpty(currentCampaign)
                ? selectionInfo
                : null
            }
            history={history}
            selected={selectedTargetGroupData}
            isPaused={isPaused}
            isEdit={isEdit}
            showEdit={true}
            //showEdit={validateSelectedCampaignsDurations(selectedTargetGroupData)}
            isOpen={Boolean(openedBulkAction)}
            actionType={openedBulkAction}
            onClose={this.closeBulkAction}
            onPauseOrResumeAction={(period) => {
              this.props.actions.pauseOrResumeTargetGroups(
                selectedTargetGroupData.map((campaign) => campaign.id),
                openedBulkAction,
                period,
                {
                  ...getParams({
                    ps,
                    page,
                    sort,
                  }),
                  campaignId: campaign.id,
                },
                filters
              );
            }}
          />
        )}
        {openedBulkAction === CAMPAIGN_ACTIONS.CANCEL && (
          <CancelCampaignTargetGroup
            selectionInfo={
              selection.parentSelection.length > 0 && isEmpty(currentCampaign)
                ? selectionInfo
                : null
            }
            selected={selectedTargetGroupData}
            header="RightPanelHeader.cancelTargetGroup"
            isOpen={Boolean(openedBulkAction)}
            actionType={openedBulkAction}
            onClose={this.closeBulkAction}
            cancelCampaigns={(data) => {
              this.props.actions.cancelCampaigns(
                {
                  ...data,
                  ...{
                    ids: selectedTargetGroupData.map((campaign) => campaign.id),
                  },
                },
                CAMPAIGN_TYPE.TARGET_GROUP,
                {
                  ...getParams({
                    ps,
                    page,
                    sort,
                  }),
                  campaignId: campaign.id,
                },
                this.closeBulkAction,
                false,
                filters
              );
            }}
          />
        )}
        {openedBulkAction === CAMPAIGN_ACTIONS.MOVE && (
          <MoveCampaignTargetGroup
            selected={selectedTargetGroupData}
            //parentValidity= campaign validity
            parentValidity={campaign.validity}
            header="RightPanelHeader.moveTargetGroup"
            item={CAMPAIGN_TYPE.TARGET_GROUP}
            isOpen={Boolean(openedBulkAction)}
            actionType={openedBulkAction}
            onClose={this.closeBulkAction}
            moveCampaigns={(data) => {
              this.props.actions.moveCampaigns(
                { id: selectedTargetGroupData[0].id, ...data },
                CAMPAIGN_TYPE.TARGET_GROUP,
                {
                  ...getParams({
                    ps,
                    page,
                    sort,
                  }),
                  campaignId: campaign.id,
                },
                this.closeBulkAction,
                false,
                filters
              );
            }}
          />
        )}
        {query?.editTargetGroupId && params.action === "addMaxPlaysPerScreen" && (
          <AddMaxPlaysPerScreen
            fetchActionId={query.editTargetGroupId}
            fetchAction={getTargetGroup}
            isOpen={showAddMaxPlaysPerScreenPanel}
            onClose={this.handleCloseAddMaxPlaysPerScreenPopup}
            onSave={this.updateTargetGroupMaxPlaysPerScreen}
            maximumPlays={campaign.maxPlaysPerScreen}
          />
        )}
      </div>
    );
  };
}

export const CampaignTargetGroupsListWithFilters = WithFetchList("getCampaignTargetGroups", {
  sort: [
    {
      id: "status",
      desc: false,
    },
  ],
})(CampaignTargetGroupsList);

const mapStateToProps = createSelector(
  (state) => state.campaigns,
  (state) => state.userData,
  (campaigns, userData) => ({ campaigns, userData: userData.user })
);

export default connect(
  mapStateToProps,
  bindDispatch
)(withRouter(CampaignTargetGroupsListWithFilters));
