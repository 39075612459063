import { Position, Tooltip } from "@blueprintjs/core";
import ClassNames from "classnames";
import { isEmpty, isNumber, uniq } from "lodash";
import { Icons } from "prefab";
import React, { Component } from "react";

import { CAMPAIGN_STATUS_LIST } from "../../../../../constants";
import styles from "../../../../../styles/Campaigns/CampaignTree.module.scss";
import { formatValidityPeriod } from "../../../../../utils";
import StatusIcon from "../../../../common/StatusIcon";

const { ChevronUpIcon, ChevronDownIcon } = Icons;

const defaultTargetGroupInfo = [
  {
    id: "targetType",
    label: "Target Type",
    value: "Select target type",
  },
  {
    id: "validity",
    label: "Validity",
    value: "Select Target Group validity",
  },
  {
    id: "totalEstimatedPlays",
    label: "Estd. Plays",
    value: "-",
  },
  {
    id: "actualPlays",
    label: "Actual Plays",
    value: "-",
  },
];

export default class TargetGroupCard extends Component {
  state = {
    isExpanded: this.props.isExpanded,
  };

  static defaultProps = {
    isExpanded: true,
    showCollapse: false,
  };

  handleCardToggle = (isExpanded) => {
    this.setState({ isExpanded });
  };

  formatTargetTypeValues = (defaultValue, targetGroupData) => {
    const value = targetGroupData && targetGroupData[defaultValue.id];

    if (isEmpty(value) && !isNumber(value)) return defaultValue.value;
    if (
      value.hasOwnProperty("inclusions") &&
      isEmpty(value.inclusions) &&
      value.hasOwnProperty("exclusions") &&
      isEmpty(value.exclusions)
    )
      return defaultValue.value;

    if (defaultValue.id === "validity") {
      return formatValidityPeriod(value);
    } else if (defaultValue.id === "criteria") {
      const targetTypeList = [
        ...(targetGroupData.criteria.inclusions || []),
        ...(targetGroupData.criteria.exclusions || []),
      ].map((item) => item.targetGroupType);

      return (
        <Tooltip
          className={styles.toolTip}
          boundary={true}
          position={Position.AUTO}
          content={uniq(targetTypeList).join(",")}
          usePortal={false}
        >
          <div className={styles.title}>{uniq(targetTypeList).join(", ")}</div>
        </Tooltip>
      );
    }

    return value;
  };

  render() {
    const { title, showCollapse, children, targetGroupData } = this.props;
    const { isExpanded } = this.state;

    const status = targetGroupData?.status;

    const statusInfo = CAMPAIGN_STATUS_LIST.map((status) => ({
      name: status.displayName,
      id: status.id,
      type: status.type,
      color: status.color,
    }));

    return (
      <div className={ClassNames(styles.cardContainer, styles.targetGroupContainer)}>
        <div className={styles.header}>
          <div className={styles.contentWrapper}>
            <div className={styles.titleContent}>
              {statusInfo
                .filter((info) => info.id === status)
                .map((status, index) => (
                  <div key={index}>
                    <Tooltip
                      content={status.name}
                      boundary={true}
                      position={Position.AUTO}
                      usePortal={true}
                      popoverClassName={styles.tooltipPopover}
                    >
                      <StatusIcon color={status.color} type={status.type} />
                    </Tooltip>
                  </div>
                ))}
              <Tooltip
                popoverClassName={styles.tooltipPopover}
                content={title}
                boundary={true}
                position={Position.AUTO}
                usePortal={true}
              >
                <div
                  className={ClassNames(
                    styles.title,
                    styles.targetGroupTitle,
                    styles.tooltipContent
                  )}
                >
                  {title}
                </div>
              </Tooltip>
            </div>
          </div>
          {showCollapse && (
            <div className={styles.actions}>
              {isExpanded ? (
                <span onClick={() => this.handleCardToggle(false)}>
                  <ChevronUpIcon />
                </span>
              ) : (
                <span onClick={() => this.handleCardToggle(true)}>
                  <ChevronDownIcon />
                </span>
              )}
            </div>
          )}
        </div>
        <div className={styles.cardItemWrapper}>
          {defaultTargetGroupInfo.map((ItemDefaultData, index) => {
            let valueToDisplay = this.formatTargetTypeValues(ItemDefaultData, targetGroupData);

            if (ItemDefaultData.id === "estimatedPlays") {
              valueToDisplay = targetGroupData.estimatedPlays || "-";
            } else if (ItemDefaultData.id === "playedPlays") {
              valueToDisplay = targetGroupData.playedPlays || "-";
            }

            return (
              <div key={index} className={styles.itemContainer}>
                <div className={styles.labelContainer}>
                  <div className={styles.label}>{ItemDefaultData.label}</div>
                </div>
                <div className={styles.infoValue}>{valueToDisplay}</div>
              </div>
            );
          })}
        </div>
        {isExpanded && <div className={`${styles.child} ${styles.targetGroup}`}>{children}</div>}
      </div>
    );
  }
}
