import moment from "moment";
import formatDateTime, { combineDateTimeToISO } from "./formatDateTime";

const formatValidityPeriod = (validity, format = "DD MMM YYYY, h:mma") => {
  if (!validity || !validity?.fromDate || !validity?.toDate) return null;

  const fromDate = validity.startTime
    ? formatDateTime(combineDateTimeToISO(validity.fromDate, validity.startTime))
    : formatDateTime(validity.fromDate);
  const toDate = validity.endTime
    ? formatDateTime(combineDateTimeToISO(validity.toDate, validity.endTime))
    : formatDateTime(validity.toDate);

  return [
    moment(fromDate).format(validity.startTime ? format : "DD MMM YYYY"),
    moment(toDate).format(validity.endTime ? format : "DD MMM YYYY"),
  ].join(" - ");
};

export default formatValidityPeriod;
