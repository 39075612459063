/**
This component renders a form to edit a brand entity.

1. Renders a form with fields like name, business type, parent/individual brand, etc. based on the brand type.

2. Allows searching and selecting parent brands, individual brands, products, movies, theaters etc. based on the brand type.

3. Handles form validation and error handling.

4. Checks for duplicate brands on save.

5. Saves the edited brand by calling a createOrUpdateBrand action.

6. Handles editing child brands by getting appropriate params.

7. Renders extra fields for movies and theaters like release date, location etc.

8. Handles selection and removal of categories and advertisers.

9. Maintains form state and handles updates via callbacks like onSelect, onChange etc.

10. Shows a confirmation popup before discarding unsaved changes.

It provides an editing UI for the brand entity by fetching relevant data, validating input, checking duplicates and ultimately saving the changes.
 */
import React, { Component } from "react";
import { Intent } from "@blueprintjs/core";
import { has, isEmpty, omit } from "lodash";
import moment from "moment";
import queryString from "query-string";
import PropTypes from "prop-types";
import { IconNames } from "@blueprintjs/icons";
import { getBusinessType } from "../../../../utils";
import { FORM_FIELD_TYPE, BRAND_TYPE, BRANDS_TYPE, BRAND_LABEL_NAME } from "../../../../constants";
import styles from "../../../../styles/Catalogue/Brands.module.scss";
import {
  searchParentBrands,
  searchIndividualBrands,
  searchProducts,
  searchTags,
  searchMovies,
} from "../../../../api";
import RightPanel from "../../../common/RightPanel";
import Form from "../../../common/Form";
import { SearchIcon } from "../../../common/SearchIcon";
import { CustomMenuItem } from "../../../common";
import Popup from "../../../common/Popup";
import RemoveIcon from "../../../common/RemoveIcon";
import { ADD_BRAND_TITLE, ERROR_MESSAGE, DEFAULT_BRANDS_PATH } from "./brandConstants";
import DuplicateBrands from "./DuplicateBrands";

export default class EditBrand extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPopupOpen: false,
      brand: props.brand,
      hasEditError: false,
      errorMessage: {},
      duplicateBrands: [],
      disabled: false,
    };
  }

  static propTypes = {
    brand: PropTypes.object.isRequired,
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.brand !== prevProps.brand) this.setState({ brand: this.props.brand });
    if (this.state.disabled !== prevState.disabled) this.setState({ disabled: false });
  }

  handlePopup = () => this.setState((prevState) => ({ isPopupOpen: !prevState.isPopupOpen }));
  discardChanges = () => {
    this.handlePopup();
    this.cancelEdit();
  };
  cancelEdit = () => {
    this.setState({ hasEditError: false, brand: {}, duplicateBrands: [] });
    this.props.cancelEdit();
  };

  isValid = () => {
    const { brand } = this.state;
    if (
      brand.name.length === 0 ||
      ("businessType" in brand && brand.businessType.length === 0) ||
      ("parentBrandId" in brand && brand.parentBrandId.length === 0) ||
      ("individualBrandId" in brand && brand.individualBrandId.length === 0) ||
      ("productId" in brand && brand.productId.length === 0) ||
      ("productName" in brand && brand.productName.length === 0)
    ) {
      return false;
    }
    return true;
  };
  saveBrand = () => {
    const { actions, fetchDataList } = this.props;
    const { brand } = this.state;
    actions.createOrUpdateBrand(
      brand,
      { ...this.getParams() },
      this.cancelEdit,
      fetchDataList,
      this.props.filters
    );
  };
  checkDuplication = () => {
    const { brand } = this.state;
    const { actions, fetchDataList } = this.props;

    if (this.shouldShowCategoryError(brand)) {
      this.setState({ hasEditError: true });
      return;
    }

    if (this.isValid(brand)) {
      actions.checkDuplication(
        brand,
        { ...this.getParams() },
        (data) => this.hasDuplication(data),
        this.cancelEdit,
        fetchDataList
      );
    } else {
      this.setState({ hasEditError: true });
    }
  };
  getParams = () => {
    const {
      match: { params },
      history: { location },
    } = this.props;
    const queryParams = queryString.parse(location.search);
    if (params.id && params.action === "new") {
      const newParams = this.getChildParams(params);
      return newParams;
    } else {
      return { ...params, ...queryParams };
    }
  };
  getChildParams = (params) => {
    switch (params.tabId) {
      case BRANDS_TYPE.PARENT_BRANDS:
        return { tabId: BRANDS_TYPE.INDIVIDUAL_BRANDS };
      case BRANDS_TYPE.INDIVIDUAL_BRANDS:
        return { tabId: BRANDS_TYPE.PRODUCTS };
      case BRANDS_TYPE.PRODUCTS:
        return { tabId: BRANDS_TYPE.VARIANTS };
      default:
        return;
    }
  };

  hasDuplication = (data) => {
    this.setState({ duplicateBrands: data });
  };

  isSelectedCategory = (data) => {
    const { brand } = this.state;
    return (
      brand.categories &&
      brand.categories.find(
        (category) =>
          category.categoryId === data.category.id &&
          category.subCategory1Id === data.subCategory1.id &&
          category.subCategory2Id === data.subCategory2.id
      )
    );
  };

  renderCategoryMenu = (data, { modifiers, handleClick }) => {
    return (
      <CustomMenuItem
        key={`${data.category.id} ${data.subCategory1.id} ${data.subCategory2.id}`}
        active={modifiers.active}
        icon={this.isSelectedCategory(data) ? IconNames.TICK : IconNames.BLANK}
        onClick={handleClick}
        text={this.categoryMenuRenderer(data)}
        shouldDismissPopover={false}
      />
    );
  };

  renderAdvertiserMenu = (data, { modifiers, handleClick }) => (
    <CustomMenuItem
      key={data.id}
      active={modifiers.active}
      icon={this.isSelectedAdvertiser(data) ? IconNames.TICK : IconNames.BLANK}
      onClick={handleClick}
      text={data.name}
      shouldDismissPopover={false}
    />
  );

  isSelectedAdvertiser = (data) => {
    const { brand } = this.state;
    return brand.advertisers && brand.advertisers.find((advertiser) => advertiser.id === data.id);
  };

  transformData = (data) => {
    return {
      categoryId: data.category.id,
      categoryName: data.category.name,
      subCategory1Id: data.subCategory1.id,
      subCategory1Name: data.subCategory1.name,
      subCategory2Id: data.subCategory2.id,
      subCategory2Name: data.subCategory2.name,
    };
  };

  arrowWithName = (n1, n2, n3) => {
    return [n1, n2, n3].filter((n) => n !== null && n.length > 0 && n !== undefined).join(" > ");
  };

  categoryMenuRenderer = (data) => {
    return this.arrowWithName(data.category.name, data.subCategory1.name, data.subCategory2.name);
  };

  categoryTagRenderer = (data) => {
    return this.arrowWithName(data.categoryName, data.subCategory1Name, data.subCategory2Name);
  };

  selectItem = (data) => {
    const { brand } = this.state;
    this.setState({
      disabled: true,
      brand: { ...brand, ...data },
    });
  };

  onSelect = (item) => {
    const { brand } = this.state;
    this.setState({
      brand: { ...brand, ...item },
      duplicateBrands: [],
    });
  };

  searchMoviesTheatre = async (value, type) => {
    switch (type) {
      case BRAND_TYPE.EXBT:
        const theatres = await searchTags(value, "theatre");
        // Restructure the tag response as a results.
        return { ...theatres, data: { results: theatres.data } }; //  Theatre search
      case BRAND_TYPE.PSTD:
        return await searchMovies(value); // Movies search
      default:
        return "";
    }
  };

  checkMovieOrTheatre = (props, type) => {
    switch (type) {
      case BRAND_TYPE.EXBT:
        return { theatreId: props.id };
      case BRAND_TYPE.PSTD:
        return { movieId: props.id };
      default:
        return "";
    }
  };

  handleUpdateTitle = () => {
    const { brand } = this.state;
    if (brand.productId) return "Variant";
    else if (brand.individualBrandId) return "Product";
    else if (brand.parentBrandId) return "Individual Brand";
    else return "Parent Brand";
  };

  onCategorySelect = (data) => {
    const { brand } = this.state;
    const dataClone = this.transformData(data);
    const brandIndex = brand.categories.findIndex(
      (item) =>
        item.categoryName === dataClone.categoryName &&
        item.subCategory1Name === dataClone.subCategory1Name &&
        item.subCategory2Name === dataClone.subCategory2Name
    );
    if (brandIndex >= 0) this.removeCategory(brand, brandIndex);
    else
      this.selectItem({
        categories: [...brand.categories, this.transformData(data)],
      });
  };

  onAdvertiserSelect = (data) => {
    const { brand } = this.state;
    const brandIndex = brand.advertisers.findIndex((item) => item.id === data.id);
    if (brandIndex >= 0) this.removeAdvertiser(brand, brandIndex);
    else
      this.selectItem({
        advertisers: [...brand.advertisers, data],
      });
  };

  removeCategory = (brand, brandIndex) => {
    const newBrand = { ...brand };
    newBrand.categories.splice(brandIndex, 1);
    this.setState({
      brand: newBrand,
      disabled: true,
    });
  };

  removeAdvertiser = (brand, brandIndex) => {
    const newBrand = { ...brand };
    newBrand.advertisers.splice(brandIndex, 1);
    this.setState({
      brand: newBrand,
      disabled: true,
    });
  };

  removeItem = (itemIndex, key) => {
    const { brand } = this.state;
    this.setState({
      brand: {
        ...brand,
        ...{ [key]: brand[key].filter((item, index) => index !== itemIndex) },
      },
    });
  };
  clearMultiSelect = (key) => {
    const { brand } = this.state;
    this.setState({
      brand: {
        ...brand,
        ...{ [key]: [] },
      },
    });
  };
  theatresOrMoviesSubText = (item, type) => {
    switch (type) {
      case BRAND_TYPE.EXBT:
        const { context } = item;
        return [context?.city?.name, context?.province?.name, context?.country?.name]
          .filter(Boolean)
          .join(", ");
      case BRAND_TYPE.PSTD:
        const { info } = item;
        const releaseDate = Object.values(info?.country_specific_release_dates).sort();
        return releaseDate
          ? [info.language, moment(releaseDate[0]).format("YYYY")].filter(Boolean).join(" • ")
          : "";
      default:
        return "";
    }
  };
  renderBrandNameField = () => {
    const { hasEditError, brand } = this.state;
    const { title } = this.props;
    const labelName = brand.id ? this.handleUpdateTitle() : BRAND_LABEL_NAME[title];
    if (this.isRenderMovieOrTheatreField()) {
      return {
        key: "name",
        type: FORM_FIELD_TYPE.API_SEARCH,
        title: "Individual Brand Name",
        placeholder: "Search...",
        onSelect: (props) => {
          this.onSelect({
            name: props.name,
            ...this.checkMovieOrTheatre(props, brand.businessType || brand.type),
          });
        },
        query: brand.name,
        searchIcon: "right",
        showSubText: true,
        selectedItem: {
          name: brand.name,
          ...this.checkMovieOrTheatre({ id: "" }, brand.businessType || brand.type),
        },
        parseResults: (data) => {
          return data.results.map((item) => {
            return {
              id: item.id,
              name: item.displayName || item.name,
              tag: this.theatresOrMoviesSubText(item, brand.businessType || brand.type),
              item: item,
            };
          });
        },
        fetchAction: (text) => this.searchMoviesTheatre(text, brand.businessType || brand.type),
        errorMessage: ERROR_MESSAGE.enterIndividualBrandName,
        error: hasEditError && !brand.name,
        size: 12,
      };
    } else {
      return {
        key: "name",
        type: FORM_FIELD_TYPE.INPUT,
        label: `${labelName} Name`,
        className: "col-12",
        size: 12,
        placeholder: `Enter ${labelName} Name`,
        value: brand.name,
        error: hasEditError && brand.name.trim().length === 0,
        errorMessage: `Enter ${labelName} Name`,
        onChange: (e) => this.onSelect({ name: e.target.value }),
      };
    }
  };
  isRenderMovieOrTheatreField = () => {
    const { brand } = this.state;
    const {
      match: { params },
    } = this.props;
    // It looks too bad. I can't understand what it is. Should change the logic.
    return (
      (([DEFAULT_BRANDS_PATH.INDIVIDUAL_BRANDS].includes(params.tabId) ||
        ([DEFAULT_BRANDS_PATH.PARENT_BRANDS].includes(params.tabId) &&
          params.viewtabId &&
          params.action === "new") ||
        ([DEFAULT_BRANDS_PATH.PARENT_BRANDS].includes(params.tabId) &&
          params.viewtabId === DEFAULT_BRANDS_PATH.INDIVIDUAL_BRANDS)) &&
        [BRAND_TYPE.EXBT, BRAND_TYPE.PSTD].includes(brand.type)) ||
      "movieId" in brand ||
      "theatreId" in brand
    );
  };

  theatreOrMovie = () => {
    const { brand } = this.state;
    if (BRAND_TYPE.EXBT === brand.type) {
      return { name: "", theatreId: "" };
    }
    if (BRAND_TYPE.PSTD === brand.type) {
      return { name: "", movieId: "" };
    }
    return "";
  };
  onBrandSelection = (data) => {
    const { brand } = this.state;
    if ("productId" in brand && "individualBrandId" in brand && "parentBrandId" in brand) {
      return data;
    } else if ("individualBrandId" in brand && "parentBrandId" in brand) {
      return omit(data, ["productId", "productName"]);
    } else if ("parentBrandId" in brand) {
      return omit(data, ["productId", "productName", "individualBrandId", "individualBrandName"]);
    }
  };

  shouldShowCategoryError = (brand) => {
    if (!brand) return false;

    const isParentBrand =
      brand.parentBrandId === undefined &&
      brand.individualBrandId === undefined &&
      brand.productId === undefined;

    return isParentBrand && (!brand.categories || brand.categories.length === 0);
  };

  renderConfig = () => {
    const { hasEditError, brand } = this.state;

    const {
      actions,
      businessTypes: { list },
      brands: { categorySearch, advertiserSearch },
      productIdentificationNumberType: {
        list: { data: productIdentificationNumberType },
      },
    } = this.props;
    const selectedProductIdentificationNumber = brand.productIdentificationNumberTypeId
      ? [
          productIdentificationNumberType.find(
            (data) => data.id === brand.productIdentificationNumberTypeId
          )?.type,
        ]
      : [];
    const selectedVariantIdentificationNumber = brand.variantIdentificationNumberTypeId
      ? [
          productIdentificationNumberType.find(
            (data) => data.id === brand.variantIdentificationNumberTypeId
          )?.type,
        ]
      : [];

    return [
      {
        key: "code",
        type: FORM_FIELD_TYPE.LABEL,
        title: "Code",
        value: brand.code,
      },
      {
        placeHolder: "Select Brand Type",
        key: "brandType",
        type: FORM_FIELD_TYPE.SELECT,
        title: "Brand Type",
        label: "",
        value: brand.id ? getBusinessType(list, brand.businessType) : brand.businessType,
        list: list.map((l) => l?.name),
        selectedList: brand.businessType
          ? [list.find((type) => type.code === brand.businessType)?.name]
          : [],
        singleSelect: true,
        error: hasEditError && "businessType" in brand && brand.businessType.length === 0,
        errorMessage: ERROR_MESSAGE.businessType,
        compareKey: "id",
        textKey: "name",
        showLabelInButton: false,
        onSelect: ([value]) =>
          this.onSelect({
            businessType: list && list.find((type) => type.name === value).code,
          }),
        usePortal: false,
      },
      {
        key: "parentBrandId",
        type: FORM_FIELD_TYPE.API_SEARCH,
        isLabel: Boolean(brand.id),
        title: "Parent Brand",
        placeholder: "Search Parent Brand",
        onSelect: (props) => {
          this.onSelect(
            this.onBrandSelection({
              parentBrandId: props.id,
              parentBrandName: props.name,
              type: props.item.businessType,
              individualBrandId: "",
              individualBrandName: "",
              productId: "",
              productName: "",
              ...this.theatreOrMovie(),
            })
          );
        },
        query: brand.parentBrandName,
        value: brand.parentBrandName,
        selectedItem: { id: brand.parentBrandId, name: brand.parentBrandName },
        searchIcon: "right",
        showSubText: false,
        parseResults: (data) => {
          return (
            data.data &&
            data.data.map((item) => {
              return {
                id: item.id,
                name: item.name,
                tag: item.type,
                item: item,
              };
            })
          );
        },
        fetchAction: (text) => searchParentBrands(text),
        errorMessage: ERROR_MESSAGE.parentBrandName,
        error: hasEditError && !brand.parentBrandName,
      },
      {
        key: "individualBrandId",
        type: FORM_FIELD_TYPE.API_SEARCH,
        isLabel: Boolean(brand.id),
        title: "Individual Brand",
        placeholder: "Search Individual Brand",
        onSelect: (props) => {
          this.onSelect(
            this.onBrandSelection({
              parentBrandId: props?.item.parentBrandId,
              parentBrandName: props?.item.parentBrandName,
              individualBrandId: props.id,
              individualBrandName: props.name,
              productId: "",
              productName: "",
            })
          );
        },
        query: brand.individualBrandName,
        value: brand.individualBrandName,
        selectedItem: {
          id: brand.individualBrandId,
          name: brand.individualBrandName,
        },
        searchIcon: "right",
        showSubText: false,
        parseResults: (data) => {
          return (
            data.data &&
            data.data.map((item) => {
              return {
                id: item.id,
                name: item.name,
                tag: item.type,
                item: item,
              };
            })
          );
        },
        fetchAction: (text) => searchIndividualBrands(text, brand.parentBrandId),
        errorMessage: ERROR_MESSAGE.individualBrandName,
        error: hasEditError && !brand.individualBrandName,
      },
      {
        key: "productId",
        type: FORM_FIELD_TYPE.API_SEARCH,
        isLabel: Boolean(brand.id),
        title: "Product Name",
        placeholder: "Search Product",
        onSelect: (props) => {
          this.onSelect(
            this.onBrandSelection({
              parentBrandId: props?.item.parentBrandId,
              parentBrandName: props?.item.parentBrandName,
              individualBrandId: props?.item.individualBrandId,
              individualBrandName: props?.item.individualBrandName,
              productId: props.id,
              productName: props.name,
            })
          );
        },
        query: brand.productName,
        value: brand.productName,
        selectedItem: { id: brand.productId, name: brand.productName },
        searchIcon: "right",
        showSubText: false,
        parseResults: (data) => {
          return (
            data.data &&
            data.data.map((item) => {
              return {
                id: item.id,
                name: item.name,
                tag: item.type,
                item: item,
              };
            })
          );
        },
        fetchAction: (text) => searchProducts(text, brand.individualBrandId),
        errorMessage: ERROR_MESSAGE.productName,
        error: hasEditError && !brand.productName,
      },
      { ...this.renderBrandNameField() },
      {
        key: "productIdentificationNumber",
        type: FORM_FIELD_TYPE.SELECT,
        containerClassName: styles.productIdentificationNumber,
        title: "Product Identification Number",
        label: "",
        placeHolder: "Select Type",
        value: brand && brand.productIdentificationNumberTypeId,
        list: productIdentificationNumberType.map((l) => l.type),
        selectedList: selectedProductIdentificationNumber,
        singleSelect: true,
        showLabelInButton: false,
        onSelect: ([value]) =>
          this.onSelect({
            productIdentificationNumberTypeId: productIdentificationNumberType.filter(
              (data) => data.type === value
            )[0]?.id,
          }),
        usePortal: false,
      },
      {
        key: "productIdentificationNumber",
        type: FORM_FIELD_TYPE.INPUT,
        label: "",
        className: "col-12",
        placeholder: "ID",
        value: brand && brand.productIdentificationNumber,
        onChange: (e) => this.onSelect({ productIdentificationNumber: e.target.value }),
      },
      {
        key: "variantIdentificationNumber",
        type: FORM_FIELD_TYPE.SELECT,
        containerClassName: styles.productIdentificationNumber,
        title: "Variant Identification Number",
        label: "",
        placeHolder: "Select Type",
        value: brand && brand.variantIdentificationNumberTypeId,
        list: productIdentificationNumberType.map((l) => l.type),
        selectedList: selectedVariantIdentificationNumber,
        singleSelect: true,
        showLabelInButton: false,
        onSelect: ([value]) =>
          this.onSelect({
            variantIdentificationNumberTypeId: productIdentificationNumberType.filter(
              (data) => data.type === value
            )[0]?.id,
          }),
        usePortal: false,
      },
      {
        key: "variantIdentificationNumber",
        type: FORM_FIELD_TYPE.INPUT,
        label: "",
        className: "col-12",
        placeholder: "ID",
        value: brand && brand.variantIdentificationNumber,
        onChange: (e) => this.onSelect({ variantIdentificationNumber: e.target.value }),
      },
      {
        key: "advertisers",
        type: FORM_FIELD_TYPE.MULTI_SELECT,
        label: "Clients",
        items: advertiserSearch,
        onQueryChange: actions.searchAdvertisers,
        itemRenderer: this.renderAdvertiserMenu,
        onItemSelect: (data) => {
          this.onAdvertiserSelect(data);
        },
        resetOnSelect: true,
        isHidden: brand.productId,
        tagRenderer: (data) => data.name,
        tagInputProps: {
          placeholder: "Select Clients",
          disabled: this.state.disabled,
          tagProps: { intent: Intent.NONE, minimal: true },
          onRemove: (data, index) => this.removeItem(index, "advertisers"),
          rightElement:
            brand.advertisers && brand.advertisers.length ? (
              <RemoveIcon onClick={() => this.clearMultiSelect("advertisers")} />
            ) : (
              <SearchIcon />
            ),
        },
        selectedItems: brand.advertisers,
      },
      {
        key: "categories",
        type: FORM_FIELD_TYPE.MULTI_SELECT,
        label: "Categories",
        items: categorySearch,
        itemRenderer: this.renderCategoryMenu,
        onItemSelect: (data) => {
          this.onCategorySelect(data);
        },
        onQueryChange: actions.categorySearch,
        resetOnSelect: true,
        isHidden: brand.productId,
        tagRenderer: this.categoryTagRenderer,
        tagInputProps: {
          placeholder: "Select Categories",
          disabled: this.state.disabled,
          tagProps: { intent: Intent.NONE, minimal: true },
          onRemove: (data, index) => this.removeItem(index, "categories"),
          rightElement:
            brand.categories && brand.categories.length ? (
              <RemoveIcon onClick={() => this.clearMultiSelect("categories")} />
            ) : (
              <SearchIcon />
            ),
        },
        selectedItems: brand.categories,
        error: hasEditError && this.shouldShowCategoryError(brand),
        errorMessage: ERROR_MESSAGE.selectCategories,
      },
    ].filter((item) => has(brand, item.key));
  };

  handlePrimaryButtonProps = () => {
    const { duplicateBrands, brand } = this.state;
    if (!isEmpty(duplicateBrands))
      return {
        text: brand.id ? "Button.IgnoreUpdate" : "Button.IgnoreAdd",
        onClick: this.saveBrand,
      };
    return {
      text: brand.id ? "Button.update" : "Button.add",
      onClick: this.checkDuplication,
    };
  };
  handleUpdateBrandTitle = () => {
    const { brand } = this.state;
    if (brand.productId) return "updateVariant";
    else if (brand.individualBrandId) return "updateProduct";
    else if (brand.parentBrandId) return "updateIndividualBrand";
    else return "updateParentBrand";
  };

  render = () => {
    const {
      title,
      brands: { editBrand, isCreateUpdateLoading },
    } = this.props;
    const { brand, isPopupOpen, duplicateBrands } = this.state;
    return (
      <RightPanel
        isOpen={editBrand.isOpen}
        isLoading={isCreateUpdateLoading}
        onClose={() => this.cancelEdit()}
        header={
          brand.id
            ? `RightPanelHeader.${this.handleUpdateBrandTitle()}`
            : `RightPanelHeader.${ADD_BRAND_TITLE[title]}`
        }
        showFooter={true}
        isEdit={brand.id ? true : false}
        primaryButtonProps={this.handlePrimaryButtonProps()}
        secondaryButtonProps={{ text: "Button.cancel", onClick: this.cancelEdit }}
      >
        <Form config={this.renderConfig()} />
        {!isEmpty(duplicateBrands) && (
          <DuplicateBrands duplicateBrands={duplicateBrands} brand={brand} />
        )}
        <Popup
          isOpen={isPopupOpen}
          onClose={this.handlePopup}
          onConfirm={this.discardChanges}
          title="Popup.title"
          body="Popup.body"
        />
      </RightPanel>
    );
  };
}
