import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import SwitchButtonWithText from "../../../common/SwitchButtonWithText";
import { getStatusIconLegend } from "../utils";
import { TAG_TYPE } from "../../../../constants";
import styles from "../../../../styles/Campaigns/Campaigns.module.scss";
import pageStyles from "../../../../styles/App.module.scss";
import TheatresListWithFilters from "./Theatres";
import ScreensListWithFilters from "./Screens";

export class TheatresScreensList extends Component {
  static propTypes = {
    selectedSwitch: PropTypes.string,
    onSwitchChange: PropTypes.func,
    theatres: PropTypes.object,
    screens: PropTypes.object,
    onFilterSelect: PropTypes.func,
  };
  static defaultProps = {
    selectedSwitch: "theatres",
  };
  render = () => {
    const { selectedSwitch, onSwitchChange, theatres, screens, onFilterSelect } = this.props;
    const theatre = {
      theatres: <TheatresListWithFilters {...this.props} />,
      screens: <ScreensListWithFilters {...this.props} />,
    };
    const legend = {
      theatres: getStatusIconLegend(theatres.data || [], TAG_TYPE.CAMPAIGN_STATUS, onFilterSelect),
      screens: getStatusIconLegend(screens.data || [], TAG_TYPE.CAMPAIGN_STATUS, onFilterSelect),
    };
    return (
      <div className={classNames("col-12 clearfix", pageStyles.pageContainer)}>
        <div className={styles.campaignTheatresHeader}>
          <div className="flex flex-auto flex-wrap">{legend[selectedSwitch]}</div>
          <SwitchButtonWithText
            buttons={[
              {
                type: "theatres",
                onClick: () => onSwitchChange("theatres"),
                text: "Button.theatres",
              },
              {
                type: "screens",
                onClick: () => onSwitchChange("screens"),
                text: "Button.screens",
              },
            ].map((item) => (item.type === selectedSwitch ? { ...item, isActive: true } : item))}
          />
        </div>
        {theatre[selectedSwitch]}
      </div>
    );
  };
}
