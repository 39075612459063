import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import {
  bindDispatch,
  getBusinessType,
  getCategoryNames,
  checkScopes,
  lastUpdatedByColumn,
  lastUpdatedAtColumn,
  createdByColumn,
  createdAtColumn,
  getColumnsByViewport,
} from "../../../../../utils";
import * as constants from "../../../../../constants";
import WithFetchList from "../../../../hoc/withFetchList";
import ActionIcon from "../../../../common/ActionIcon";
import {
  ActiveTableCell,
  ActiveTableCellColumnFromArray,
} from "../../../../common/ActiveTableCell";
import BrandTable from "../BrandTableView";
import { BRAND_LIST_COMMON_TAG_TYPES } from "../brandConstants";
import EditBrand from "../EditBrand";

const DEFAULT_COLUMNS = ["Product", "Individual Brand", "Parent Brand", "Categories", "Clients"];
const MOBILE_COLUMNS = ["Product", "Individual Brand", "Parent Brand", "Categories", "Clients"];
class ProductList extends Component {
  state = {
    selectedColumns: getColumnsByViewport(
      this.props.app.pageDimensions,
      MOBILE_COLUMNS,
      DEFAULT_COLUMNS
    ),
  };
  componentDidMount = () => {
    const { fetchData } = this.props;
    fetchData(null, true, [{ parentTagId: this.props.parentTagId }]);
  };

  onColumnSelect = (selectedColumns, reorderedColumns) =>
    this.setState({ selectedColumns, reorderedColumns });

  editProduct = (prop) => {
    const { actions, editBrand } = this.props;
    actions.editBrand(prop.original);
    editBrand(prop.original.id);
  };
  editVariant = (prop) => {
    const { actions, editBrand } = this.props;
    actions.getVariantById({ id: prop.original.id }, true);
    editBrand(prop.original.id);
  };

  viewVariant = (prop) => {
    const { actions, history } = this.props;
    actions.updateBrand(prop.original);
    history.push(`/brands/variants/${prop.original.id}/details`);
  };

  viewProduct = (prop) => {
    const { actions, history } = this.props;
    actions.updateBrand(prop.original);
    history.push(`/brands/products/${prop.original.id}/details`);
  };

  renderAdvertisers = (advertisers) => {
    if (!advertisers || advertisers.length === 0) return "";
    return advertisers.map((ad, i) => <div key={i}>{ad}</div>);
  };
  render = () => {
    const {
      history,
      match: { params },
      brands: { productList, editBrand },
      businessTypes: { list },
      userData,
      addNewField,
      isViewOnly,
      hiddenColumn,
      title,
    } = this.props;
    const { selectedColumns, reorderedColumns } = this.state;
    const columns = [
      {
        id: "name",
        Header: "Product",
        parentAccessor: "name",
        childAccessor: "name",
      },
      {
        id: "individualBrandName",
        Header: "Individual Brand",
        parentAccessor: (d) =>
          ActiveTableCell(d.individualBrandId, d.individualBrandName, () =>
            this.props.onFilterIdSelect(d.individualBrandId)
          ),
      },
      {
        id: "parentBrandName",
        Header: "Parent Brand",
        parentAccessor: (d) =>
          ActiveTableCell(d.parentBrandId, d.parentBrandName, () =>
            this.props.onFilterIdSelect(d.parentBrandId)
          ),
      },
      {
        id: "variants",
        Header: "Variant Count",
        sortable: false,
        parentAccessor: (d) => (d.variants ? d.variants.length : ""),
      },
      {
        id: "brandType",
        Header: "Brand Type",
        parentAccessor: (d) =>
          ActiveTableCell(d.businessTypeTag, getBusinessType(list, d.businessType), () =>
            this.props.onFilterIdSelect(d.businessTypeTag)
          ),
      },
      {
        id: "categories",
        Header: "Categories",
        sortable: false,
        parentAccessor: (d) =>
          getCategoryNames(d.categories, (id) => this.props.onFilterIdSelect(id)),
        childAccessor: (d) => getCategoryNames(d.categories),
      },
      {
        id: "client",
        Header: "Clients",
        sortable: false,
        parentAccessor: (d) =>
          ActiveTableCellColumnFromArray(d.advertisers, this.props.onFilterIdSelect),
        childAccessor: (d) => ActiveTableCellColumnFromArray(d.advertisers),
      },
      lastUpdatedByColumn(this.props.onFilterIdSelect),
      lastUpdatedAtColumn(),
      createdByColumn(this.props.onFilterIdSelect),
      createdAtColumn(),
      {
        ...constants.DEFAULT_ACTION_ICON_COLUMN,
        width: isViewOnly ? 64 : 96,
        parentCell: (props) => (
          <ActionIcon
            iconProps={checkScopes(
              [
                {
                  toolTip: "Tooltip.view",
                  url: `/brands/products/${props.original.id}/details`,
                  iconName: "ViewIcon",
                },
                {
                  id: "edit",
                  scope: constants.SCOPES.BRANDS,
                  scopeAction: constants.SCOPE_ACTIONS.WRITE,
                  toolTip: "Tooltip.update",
                  onClick: () => this.editProduct(props),
                  iconName: "EditIcon",
                },
              ].filter((icon) => (isViewOnly ? icon.id !== "edit" : icon)),
              userData
            )}
          />
        ),
        childCell: (props) => (
          <ActionIcon
            iconProps={checkScopes(
              [
                {
                  toolTip: "Tooltip.view",
                  url: `/brands/variants/${props.original.id}/details`,
                  iconName: "ViewIcon",
                },
                {
                  id: "edit",
                  scope: constants.SCOPES.BRANDS,
                  scopeAction: constants.SCOPE_ACTIONS.WRITE,
                  toolTip: "Tooltip.update",
                  onClick: () => this.editVariant(props),
                  iconName: "EditIcon",
                },
              ].filter((icon) => (isViewOnly ? icon.id !== "edit" : icon)),
              userData
            )}
          />
        ),
      },
    ].filter((column) => (hiddenColumn ? column.id !== hiddenColumn : column));
    const addButtonProps = {
      addButton: true,
      buttonLabel: "Button.addProduct",
      addButtonScope: constants.SCOPES.BRANDS,
      addButtonScopeAction: constants.SCOPE_ACTIONS.WRITE,
      addNewField: addNewField,
      userData,
    };

    const tagTypes = [
      constants.TAG_TYPE.PARENT_BRAND,
      constants.TAG_TYPE.INDIVIDUAL_BRAND,
      constants.TAG_TYPE.PRODUCT,
      ...BRAND_LIST_COMMON_TAG_TYPES,
    ];

    return (
      <>
        <BrandTable
          dataList={productList}
          columns={columns}
          selectedColumns={selectedColumns}
          reorderedColumns={reorderedColumns}
          onColumnSelect={this.onColumnSelect}
          addButtonProps={addButtonProps}
          tagTypes={tagTypes}
          childIdentificationKey="variants"
          onRowClick={({ original }) => history.push(`/brands/products/${original.id}/details`)}
          onChildRowClick={({ original }) =>
            history.push(`/brands/variants/${original.id}/details`)
          }
          {...this.props}
        />
        {!isViewOnly && (
          <EditBrand
            title={title ?? params?.tabId}
            brand={editBrand.brand}
            fetchDataList={() => {
              // Reset filter list and then trigger data refresh
              this.props.onFilterChange([]);
            }}
            {...this.props}
          />
        )}
      </>
    );
  };
}
export const Products = WithFetchList("getProductList", {
  sort: [{ id: "name" }],
})(ProductList);

const mapStateToProps = createSelector(
  (state) => state.brands,
  (state) => state.businessTypes,
  (state) => state.productIdentificationNumberType,
  (state) => state.userData,
  (state) => state.app,
  (brands, businessTypes, productIdentificationNumberType, userData, app) => ({
    brands,
    businessTypes,
    productIdentificationNumberType,
    userData: userData.user,
    app,
  })
);
export default connect(mapStateToProps, bindDispatch)(withRouter(Products));
