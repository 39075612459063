import moment from "moment";
import { getUserTimeZone } from ".";

const formatDateTime = (dt, format = "DD MMM YYYY HH:mm", showUTC = false) => {
  if (!dt) return null;
  const momentDate = moment.utc(new Date(dt));
  if (!showUTC) {
    const timeZone = getUserTimeZone();
    momentDate.utcOffset(timeZone.offset);
  }
  const formatted = momentDate.format(format);
  return formatted;
};
const safeFormatDateTime = (date, format, showUTC) => {
  try {
    const formattedDate = formatDateTime(date, format, showUTC);
    if (formattedDate === "Invalid date") {
      throw new Error("Invalid date");
    }
    return formattedDate;
  } catch (error) {
    return "";
  }
};

const combineDateTimeToISO = (date, time) => {
  return `${date}T${time}:00Z`;
};

export { formatDateTime as default, safeFormatDateTime, combineDateTimeToISO };
