import { Position, Tooltip } from "@blueprintjs/core";
import { compact, isEmpty } from "lodash";
import React from "react";
import { getUserTimeZone } from "..";

const modifyTableColumns = (
  columns,
  selectedColumns,
  reorderedColumns,
  columnHeaderKey = "Header"
) => {
  if (isEmpty(reorderedColumns)) {
    reorderedColumns = compact(columns.map((c) => c[columnHeaderKey]));
  }

  let filteredColumns = reorderedColumns.reduce((acc, header) => {
    const column = columns.find((col) => col[columnHeaderKey] === header);
    if (column && selectedColumns.includes(header)) {
      acc.push(column);
    }
    return acc;
  }, []);

  filteredColumns = [...filteredColumns, ...columns.filter((c) => c.hideIfOnlyColumn)];
  const timeZone = getUserTimeZone().abbr;

  return filteredColumns.map((c) => {
    if (c.showTimeZone) {
      return { ...c, Header: `${c[columnHeaderKey]} (${timeZone})` };
    }
    return c;
  });
};

const modifyTableColumnsWithTooltip = (columns, selectedColumns, reorderedColumns) => {
  const modifiedColumns = modifyTableColumns(columns, selectedColumns, reorderedColumns);

  const columnsWithTooltip = modifiedColumns.map((column) => ({
    ...column,
    headerName: column.Header,
    Header: (
      <Tooltip content={column.Header} boundary usePortal={true} position={Position.TOP}>
        <span>{column.Header}</span>
      </Tooltip>
    ),
  }));

  return columnsWithTooltip;
};

export { modifyTableColumns };

export default modifyTableColumnsWithTooltip;
