import React, { Component } from "react";
import queryString from "query-string";
import { ExpandTable } from "workbench";
import { modifyTableColumns, locationColumn, formatDateTime } from "../../../../utils";
import * as constants from "../../../../constants";
import FilterChips from "../../../common/Filters/FilterChips";
import TableControls from "../../../common/TableControls";
import TableLoading from "../../../common/TableLoading";
import FooterControls from "../../../common/FooterControls";
import { ActiveTableCell } from "../../../common/ActiveTableCell";
import { getStatus } from "../utils";

class TheatresList extends Component {
  state = {
    selectedColumns: [
      "Theatre / Screen",
      "Chain",
      "Location",
      "Start Date",
      "End Date",
      "Actual Reach",
      "Estimated Reach",
      "Priority",
      "Status",
    ],
  };
  componentDidMount = () => {
    const {
      match: { params },
      selectedSwitch,
    } = this.props;
    this.props.fetchData(null, false, [
      {
        id: params.campaignId ?? params.targetGroupId ?? params.mediaId,
        targetType: selectedSwitch,
      },
    ]);
  };
  onColumnSelect = (selectedColumns, reorderedColumns) =>
    this.setState({ selectedColumns, reorderedColumns });

  render = () => {
    const {
      history,
      isTabLoading,
      theatres,
      filters,
      sort,
      ps,
      page,
      onSortedChange,
      onQueryChange,
      isFilterLoading,
    } = this.props;

    const { selectedColumns, reorderedColumns } = this.state;
    const query = queryString.parse(history.location.search);
    const resultsCount = theatres.totalCount || 0;
    const data = theatres.data || [];

    const columns = [
      {
        id: "name",
        Header: "Theatre / Screen",
        parentAccessor: "name",
        childAccessor: "name",
        minWidth: 100,
      },
      {
        id: "chainName",
        Header: "Chain",
        accessor: (d) =>
          ActiveTableCell(d.chainId, d.chainName, () => this.props.onFilterIdSelect(d.chainId)),
        minWidth: 100,
      },
      { ...locationColumn(this.props.onFilterIdSelect), minWidth: 100 },
      {
        id: "startDate",
        Header: "Start Date",
        showTimeZone: true,
        childAccessor: (d) => formatDateTime(d.statusChanges?.fromDate),
        minWidth: 100,
      },
      {
        id: "endDate",
        Header: "End Date",
        showTimeZone: true,
        childAccessor: (d) => formatDateTime(d.statusChanges?.toDate),
        minWidth: 100,
      },
      {
        id: "reach",
        Header: "Max Plays Per Screen",
        accessor: "maxPlays",
        minWidth: 100,
      },
      {
        id: "campaignMaxPlaysPerScreen",
        Header: "Max Plays for the Campaign",
        accessor: "campaignMaxPlayPerScreen",
        minWidth: 100,
      },
      {
        id: "pendingPlays",
        Header: "Pending Plays",
        parentAccessor: "pendingPlays",
        childAccessor: "pendingPlays",
        minWidth: 100,
      },
      {
        id: "playedPlays",
        Header: "Played",
        parentAccessor: "playedPlays",
        childAccessor: "playedPlays",
        minWidth: 100,
      },
      {
        id: "failedPlays",
        Header: "Failed",
        parentAccessor: "failedPlays",
        childAccessor: "failedPlays",
        minWidth: 100,
      },
      {
        id: "actualReach",
        Header: "Actual Reach",
        accessor: "actualReach",
        minWidth: 100,
      },
      {
        id: "estimatedReach",
        Header: "Estimated Reach",
        accessor: "estimatedReach",
        minWidth: 100,
      },
      {
        id: "status",
        Header: "Status",
        parentAccessor: (d) =>
          d.status
            ? ActiveTableCell(d.statusTag, getStatus(d), () =>
                this.props.onFilterIdSelect(d.statusTag)
              )
            : "-",
        childAccessor: (d) =>
          ActiveTableCell(d.statusTag, getStatus(d), () =>
            this.props.onFilterIdSelect(d.statusTag)
          ),
        minWidth: 100,
      },
    ];

    return (
      <div className="col-12 clearfix">
        <TableControls
          searchBar
          columnFilter
          showRowSize
          pagination
          columns={columns}
          selectedColumns={selectedColumns}
          reorderedColumns={reorderedColumns}
          data={data}
          query={query}
          ps={ps}
          page={page}
          tagTypes={[constants.TAG_TYPE.THEATRE]}
          onSearchFilterSelect={this.props.onFilterSelect}
          onFilterChange={this.props.onFilterChange}
          onColumnFilterSelect={this.onColumnSelect}
          onRowSizeChange={onQueryChange}
          filters={filters}
        />
        <FilterChips
          selected={filters}
          showResultsCount
          resultsCount={resultsCount}
          onFilterChange={this.props.onFilterChange}
        />
        <ExpandTable
          data={data}
          loading={isTabLoading || isFilterLoading}
          columns={modifyTableColumns(columns, selectedColumns, reorderedColumns)}
          defaultPageSize={constants.DEFAULT_PAGE_SIZE}
          sorted={sort}
          onSortedChange={onSortedChange}
          selectable={false}
          childIdentificationKey="screens"
          LoadingComponent={
            <TableLoading
              columns={modifyTableColumns(columns, selectedColumns, reorderedColumns)}
            />
          }
        />
        <FooterControls
          pagination
          data={data}
          query={query}
          ps={ps}
          page={page}
          onRowSizeChange={onQueryChange}
        />
      </div>
    );
  };
}

export default TheatresList;
