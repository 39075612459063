import React, { Component } from "react";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import { Table } from "workbench";
import { values, sortBy } from "lodash";
import classNames from "classnames";
import { ICON_POS } from "prefab";
import ButtonStyles from "../../../styles/Button.module.scss";
import pageStyles from "../../../styles/App.module.scss";
import {
  bindDispatch,
  modifyTableColumns,
  getBusinessType,
  checkScopes,
  dateColumn,
} from "../../../utils";
import {
  TAG_TYPE,
  DEFAULT_ACTION_ICON_COLUMN,
  SCOPES,
  SCOPE_ACTIONS,
  SPECIAL_CHARACTERS,
  BRAND_TAG,
} from "../../../constants";
import { AUDIT_LOG_SOURCE } from "../logs/logSource";
import PageHeader from "../../common/PageHeader";
import HeaderButtons from "../../common/HeaderButtons";
import LocalizedButton from "../../common/LocalizedButton";
import PageLoadingWithTable from "../../common/PageLoadingWithTable";
import TableLoading from "../../common/TableLoading";
import FilterChips from "../../common/Filters/FilterChips";
import ActionIcon from "../../common/ActionIcon";
import WithFetchList from "../../hoc/withFetchList";
import TableControls from "../../common/TableControls";
import FooterControls from "../../common/FooterControls";
import {
  ActiveTableCell,
  ActiveTableCellColumnWithRowFromArray,
} from "../../common/ActiveTableCell";
import { UserTableCell } from "../../common/UserTableAccessor";

class BrandVerificationsList extends Component {
  state = {
    selectedColumns: ["Brand", "Parent Brand", "Type", "Company", "Submitted By", "Submitted On"],
  };
  componentDidMount = async () => {
    this.props.fetchData();
    this.props.actions.getBusinessTypesList();
  };

  onColumnSelect = (selectedColumns, reorderedColumns) =>
    this.setState({ selectedColumns, reorderedColumns });

  checkAction = (data) => {
    if (data.old || (data.old && data.new)) return "old";
    else return "new";
  };

  renderBrands = (brands = [], onClick) => {
    const brandTags = values(BRAND_TAG);
    const pendingBrands = sortBy(brands, (b) => brandTags.indexOf(b.type)).map((brand) =>
      [
        { id: brand.parentBrandId, name: brand.parentBrandName },
        { id: brand.individualBrandId, name: brand.individualBrandName },
        { id: brand.productId, name: brand.productName },
        { id: brand.variantId, name: brand.variantName },
      ].filter((b) => b.id)
    );
    return ActiveTableCellColumnWithRowFromArray(
      pendingBrands,
      onClick,
      SPECIAL_CHARACTERS.MIDDLE_DOT
    );
  };

  render = () => {
    const {
      history,
      brandVerifications: { brandVerificationList, isLoading },
      businessTypes: { list },
      filters,
      onFilterIdSelect,
      userData,
      ps,
      page,
      sort,
      onQueryChange,
      isFilterLoading,
      onSortedChange,
    } = this.props;
    const { selectedColumns, reorderedColumns } = this.state;
    if (isLoading && !brandVerificationList.data) return <PageLoadingWithTable />;

    const activeTableCell = (id, value) => ActiveTableCell(id, value, () => onFilterIdSelect(id));
    const columns = [
      {
        id: "name",
        Header: "Brand",
        accessor: (d) => this.renderBrands(d.pendingBrands, onFilterIdSelect),
        sortable: false,
      },
      {
        id: "parentBrandName",
        Header: "Parent Brand",
        accessor: (d) => activeTableCell(d.parentBrandId, d.parentBrandName),
      },
      {
        id: "brandType",
        Header: "Brand Type",
        accessor: (d) => activeTableCell(d.businessTypeTag, getBusinessType(list, d.businessType)),
      },
      {
        id: "submittedByCompany",
        Header: "Company",
        accessor: (d) => activeTableCell(d.submittedBy.company.id, d.submittedBy.company.name),
      },
      {
        id: "submittedByUser",
        Header: "Submitted By",
        accessor: (d) => (
          <UserTableCell user={d.submittedBy.user} onIdSelect={this.props.onFilterIdSelect} />
        ),
      },
      dateColumn("submittedAt", "Submitted On"),
      {
        ...DEFAULT_ACTION_ICON_COLUMN,
        Cell: (props) => (
          <ActionIcon
            iconProps={checkScopes(
              [
                {
                  toolTip: "Tooltip.view",
                  url: `/brand-verifications/${props.original.parentBrandId}/${props.original.submittedBy.company.id}`,
                  iconName: "ViewIcon",
                  scope: SCOPES.BRAND_VERIFICATIONS,
                  scopeAction: SCOPE_ACTIONS.WRITE,
                },
              ],
              userData
            )}
          />
        ),
      },
    ];
    return (
      <div>
        <PageHeader
          name="PageHeader.brandVerifications"
          count={brandVerificationList.totalCount}
          renderRightSideComponent={() => (
            <>
              <LocalizedButton
                iconName="ViewIcon"
                iconPos={ICON_POS.RIGHT}
                url={`/brand-verifications/rejected`}
                text="Button.rejectedBrandVerifications"
                className={ButtonStyles.buttonSpacing}
              />
              <HeaderButtons
                history={history}
                logsButton
                logsPath={`/logs/source/${AUDIT_LOG_SOURCE.BRAND_VERIFICATIONS}`}
                userData={userData}
              />
            </>
          )}
        />
        <div className={pageStyles.pageContainer}>
          <div className={classNames("col-12 clearfix", pageStyles.pageWrapper)}>
            <TableControls
              searchBar
              columnFilter
              showRowSize
              pagination
              columns={columns}
              selectedColumns={selectedColumns}
              reorderedColumns={reorderedColumns}
              data={brandVerificationList}
              ps={ps}
              page={page}
              tagTypes={[
                TAG_TYPE.BRAND,
                TAG_TYPE.PARENT_BRAND,
                TAG_TYPE.BUSINESS_TYPE,
                TAG_TYPE.COMPANY,
                TAG_TYPE.SUBMITTED_AT,
              ]}
              onSearchFilterSelect={this.props.onFilterSelect}
              onFilterChange={this.props.onFilterChange}
              onColumnFilterSelect={this.onColumnSelect}
              onRowSizeChange={onQueryChange}
              filters={filters}
            />
            <FilterChips
              selected={filters}
              showResultsCount
              resultsCount={brandVerificationList.totalCount}
              onFilterChange={this.props.onFilterChange}
              loading={isLoading || isFilterLoading}
            />
            <Table
              data={brandVerificationList.data}
              defaultSortDesc={true}
              columns={modifyTableColumns(columns, selectedColumns, reorderedColumns)}
              manual
              loading={isLoading || isFilterLoading}
              sorted={sort}
              onSortedChange={onSortedChange}
              LoadingComponent={<TableLoading columns={columns} />}
              onRowClick={(props) =>
                history.push(
                  `/brand-verifications/${props.original.parentBrandId}/${props.original.submittedBy.company.id}`
                )
              }
            />
            <FooterControls
              pagination
              data={brandVerificationList}
              ps={ps}
              page={page}
              onRowSizeChange={onQueryChange}
            />
          </div>
        </div>
      </div>
    );
  };
}

export const BrandVerifications = WithFetchList("getBrandVerificationList", {
  sort: [{ id: "submittedAt", desc: true }],
})(BrandVerificationsList);

const mapStateToProps = createSelector(
  (state) => state.brandVerifications,
  (state) => state.businessTypes,
  (state) => state.userData,
  (brandVerifications, businessTypes, userData) => ({
    brandVerifications,
    businessTypes,
    userData: userData.user,
  })
);

export default connect(mapStateToProps, bindDispatch)(BrandVerifications);
