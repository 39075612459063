import classNames from "classnames";
import { Icons } from "prefab";
import queryString from "query-string";
import React, { Component } from "react";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import { Table } from "workbench";
import * as constants from "../../../../constants";
import { DEFAULT_PAGE_SIZE } from "../../../../constants";
import pageStyles from "../../../../styles/App.module.scss";
import { bindDispatch, checkScopes, createdByColumn, modifyTableColumns } from "../../../../utils";
import ActionIcon from "../../../common/ActionIcon";
import { ActiveTableCell } from "../../../common/ActiveTableCell";
import FilterChips from "../../../common/Filters/FilterChips";
import FooterControls from "../../../common/FooterControls";
import PageHeader from "../../../common/PageHeader";
import PageLoadingWithTable from "../../../common/PageLoadingWithTable";
import TableControls from "../../../common/TableControls";
import TableLoading from "../../../common/TableLoading";
import withFetchList from "../../../hoc/withFetchList";
import AddCampaignBias from "../bulkActions/AddCampaignBias";
import { getStatus } from "../utils";

const { EditIcon } = Icons;

class CampaignsRateBias extends Component {
  state = {
    selectedColumns: [
      "Campaign ID",
      "Campaign",
      "Advertiser",
      "Brand",
      "Status",
      "Created By",
      "Effective Rate",
      "Net Effective Rate",
    ],
    openAction: false,
    selectedCampaign: null,
  };

  componentDidMount() {
    this.props.fetchData();
  }

  onColumnSelect = (selectedColumns, reorderedColumns) => {
    this.setState({ selectedColumns, reorderedColumns });
  };

  handleEditEffectiveRate = (campaign) => {
    this.setState({ openAction: true, selectedCampaign: campaign });
  };

  getBrandNames = (brands = [], onFilter) => {
    return brands.map((brand, i) => {
      const {
        parentBrandId,
        parentBrandName,
        individualBrandId,
        individualBrandName,
        productId,
        productName,
        variantId,
        variantName,
      } = brand;

      const elements = [];

      // Add parent brand if available
      if (parentBrandName) {
        elements.push(
          ActiveTableCell(parentBrandId, parentBrandName, () => onFilter(parentBrandId))
        );
      }

      // Add individual brand if available
      if (individualBrandName) {
        if (elements.length) elements.push(<span style={{ margin: "0 4px" }}>&gt;</span>);
        elements.push(
          ActiveTableCell(individualBrandId, individualBrandName, () => onFilter(individualBrandId))
        );
      }

      // Add product if available
      if (productName) {
        if (elements.length) elements.push(<span style={{ margin: "0 4px" }}>&gt;</span>);
        elements.push(ActiveTableCell(productId, productName, () => onFilter(productId)));
      }

      // Add variant if available
      if (variantName) {
        if (elements.length) elements.push(<span style={{ margin: "0 4px" }}>&gt;</span>);
        elements.push(ActiveTableCell(variantId, variantName, () => onFilter(variantId)));
      }

      return (
        <div key={i} style={{ display: "flex", alignItems: "center" }}>
          {elements}
        </div>
      );
    });
  };

  render() {
    const {
      history,
      campaignBias: { data },
      filters,
      userData,
      sort,
      ps,
      page,
      onSortedChange,
      onQueryChange,
      isFilterLoading,
      onFilterChange,
    } = this.props;

    const { selectedColumns, reorderedColumns, openAction, selectedCampaign } = this.state;
    const query = queryString.parse(history.location.search);

    const columns = checkScopes(
      [
        {
          id: "code",
          Header: "Campaign ID",
          accessor: "code",
        },
        {
          id: "name",
          Header: "Campaign",
          accessor: (d) => (
            <>{ActiveTableCell(d.id, d.name, () => this.props.onFilterIdSelect(d.id))}</>
          ),
        },
        {
          id: "advertiserName",
          Header: "Advertiser",
          accessor: (d) => (
            <>
              {ActiveTableCell(d.advertiserId, d.advertiserName, () =>
                this.props.onFilterIdSelect(d.advertiserId)
              )}
            </>
          ),
        },
        {
          id: "brand",
          Header: "Brand",
          accessor: (d) => this.getBrandNames(d.brands, (id) => this.props.onFilterIdSelect(id)),
        },
        {
          id: "status",
          Header: "Status",
          accessor: (d) => getStatus(d, this.props.onFilterSelect),
          width: 150,
        },
        createdByColumn(this.props.onFilterIdSelect),
        {
          id: "effectiveRate",
          Header: "Effective Rate",
          accessor: "effectiveRate",
        },
        {
          id: "netEffectiveRate",
          Header: "Net Effective Rate",
          accessor: "netEffectiveRate",
        },
        {
          ...constants.DEFAULT_ACTION_ICON_COLUMN,
          scope: constants.SCOPES.CAMPAIGNS,
          scopeAction: constants.SCOPE_ACTIONS.WRITE,
          Cell: (props) => (
            <ActionIcon
              iconProps={[
                {
                  id: "edit",
                  toolTip: "Tooltip.moreActions",
                  iconName: "MoreVerticalIcon",
                  dropdown: [
                    {
                      text: "Edit Effective Rate",
                      onClick: () => this.handleEditEffectiveRate(props.original),
                      icon: <EditIcon />,
                    },
                    // {
                    //   text: "Effective Rate Logs",
                    //   onClick: () => {},
                    //   icon: <LogsIcon />,
                    // },
                  ],
                },
              ]}
            />
          ),
        },
      ],
      userData
    );

    if (!data.data) return <PageLoadingWithTable />;

    return (
      <div>
        <PageHeader name="Campaign Rate Bias" count={data.totalCount} />
        <div className={classNames("col-12 clearfix", pageStyles.pageTableContainer)}>
          <TableControls
            selectable={true}
            searchBar
            columnFilter
            showRowSize
            pagination
            columns={columns}
            selectedColumns={selectedColumns}
            reorderedColumns={reorderedColumns}
            data={data}
            query={query}
            ps={ps}
            page={page}
            filters={filters}
            onFilterChange={onFilterChange}
            onSearchFilterSelect={this.props.onFilterSelect}
            onColumnFilterSelect={this.onColumnSelect}
            onRowSizeChange={onQueryChange}
            tagTypes={[
              constants.TAG_TYPE.CAMPAIGN_STATUS,
              constants.TAG_TYPE.BRAND,
              constants.TAG_TYPE.ADVERTISER,
            ]}
          />
          <FilterChips
            selected={filters}
            showResultsCount
            resultsCount={data.totalCount || 0}
            onFilterChange={onFilterChange}
          />
          <Table
            data={data.data}
            loading={isFilterLoading}
            columns={modifyTableColumns(columns, selectedColumns, reorderedColumns)}
            defaultPageSize={DEFAULT_PAGE_SIZE}
            sorted={sort}
            onSortedChange={onSortedChange}
            LoadingComponent={
              <TableLoading
                columns={modifyTableColumns(columns, selectedColumns, reorderedColumns)}
              />
            }
          />
          <FooterControls
            pagination
            data={data}
            query={query}
            ps={ps}
            page={page}
            onRowSizeChange={onQueryChange}
          />
        </div>
        {openAction && (
          <AddCampaignBias
            header="Edit Effective Rate"
            isOpen={openAction}
            onClose={() => this.setState({ openAction: false })}
            campaign={selectedCampaign}
            onSave={(data) => {
              this.props.actions
                .updateCampaignBiasValue(selectedCampaign.id, {
                  id: selectedCampaign.id,
                  effectiveRate: selectedCampaign.effectiveRate,
                  bias: data.biasValue,
                  netEffectiveRate: selectedCampaign.netEffectiveRate,
                })
                .then(() => {
                  this.props.fetchData();
                });
            }}
          />
        )}
      </div>
    );
  }
}

export const CampaignsRateBiasWithFetch = withFetchList("getCampaignBiasData", {
  sort: [{ id: "name", desc: true }],
})(CampaignsRateBias);

const mapStateToProps = createSelector(
  (state) => state.campaignBias,
  (state) => state.userData,
  (campaignBias, userData) => ({ campaignBias, userData: userData.user })
);

export default connect(mapStateToProps, bindDispatch)(CampaignsRateBiasWithFetch);
