import React, { Component } from "react";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import { bindDispatch } from "../../utils";
import PageLoadingWithTable from "../common/PageLoadingWithTable";

class Logout extends Component {
  componentDidMount() {
    this.props.actions.logoutUser(null, this.props.userData);
    window.location = `${window.location.origin}/login`;
  }
  render() {
    return <PageLoadingWithTable />;
  }
}

const mapStateToProps = createSelector(
  (state) => state.userData,
  (userData) => ({ userData: userData.user })
);

export default connect(mapStateToProps, bindDispatch)(Logout);
