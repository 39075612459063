import classNames from "classnames";
import { isEmpty, map } from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import queryString from "query-string";
import React, { Component } from "react";
import { connect } from "react-redux";
import { createSelector } from "reselect";

import {
  CAMPAIGN_STATUSES,
  CAMPAIGN_STEPS,
  THEATRE_CRITERIA_MENU_LIST,
  THEATRE_CRITERIA_TYPES,
} from "../../../../../constants";
import campaignFormStyles from "../../../../../styles/CampaignCreate/CampaignForm.module.scss";
import styles from "../../../../../styles/CampaignCreate/TargetGroup.module.scss";
import {
  bindDispatch,
  showToast,
  validateLocationCriteria,
  validateTheatreCriteria,
} from "../../../../../utils";
import Breadcrumb from "../../../../common/BreadCrumb";
import InfoBlockLoading from "../../../../common/InfoBlockLoading";
import PageLoadingWithTabs from "../../../../common/PageLoadingWithTabs";
import SplitTargetGroup from "../../bulkActions/SplitCampaigns";
import CampaignTree from "../../CampaignStructure/CampaignTree";
import CampaignPageHeader from "../../common/CampaignPageHeader";
import TargetGroupList from "../../common/pages/TargetGroupDetails";
import { campaignStepChange, validateMandatoryTargetGroupFields } from "../../utils/campaignCreate";
import CampaignSteps from "../CampaignSteps";
import EmptyState from "../EmptyState";
import CreateTargetGroup from "./CreateTargetGroup";
import campaignRouteHandler from "..";

export class TargetGroup extends Component {
  getInitialState = () => {
    const {
      campaignCreate: { campaign },
    } = this.props;
    const today = moment();

    return {
      targetGroup: {
        name: "",
        validity: {
          fromDate:
            campaign && campaign.validity
              ? moment.max(moment(campaign.validity.fromDate), today).format("YYYY-MM-DD")
              : today.format("YYYY-MM-DD"),
          toDate:
            campaign && campaign.validity
              ? moment(campaign.validity.toDate).format("YYYY-MM-DD")
              : null,
        },
        criteria: {
          inclusions: [],
          exclusions: [],
        },
        maxPlaysPerScreen: 0,
        budgetedPlays: 0,
        status: CAMPAIGN_STATUSES.DRAFT,
      },
      isEdit: false,
      hasFormSaved: false,
      isFormEdited: false,
      isFetchingPreferences: false,
      isValidTargetGroup: true,
      isPageLoading: !this.props.isCampaignInvalid,
      isSplitPanelOpen: false,
      targetGroupSplitData: { id: "", validity: {} },
    };
  };

  state = this.getInitialState();

  static propTypes = {
    isCampaignInvalid: PropTypes.bool,
  };

  static defaultProps = {
    isCampaignInvalid: false,
  };

  static defaultProps = {
    campaign: null,
  };

  componentDidMount = async () => {
    //this.props.match.params.action = "list";
    const {
      match: {
        params: { action },
      },
      campaignCreate: { campaign, campaignTargetGroup },
      actions,
      history,
      isCampaignInvalid,
    } = this.props;
    let targetGroupData = this.state.targetGroup;
    const query = queryString.parse(history.location.search);
    await actions.updateActiveStep({
      campaignStep: CAMPAIGN_STEPS.TARGET_GROUP,
      campaignId: campaign.id,
    });

    if (isCampaignInvalid) return false;

    if (action === "create") {
      if (query?.id) {
        await actions.getTargetGroupById(query?.id, "duplicate");
        if (isEmpty(campaignTargetGroup)) {
          this.setState({
            isValidTargetGroup: false,
            isPageLoading: false,
          });
        } else {
          targetGroupData = campaignTargetGroup;
        }
      } else {
        targetGroupData = {
          ...targetGroupData,
          validity: campaign.validity,
          maxPlaysPerScreen: campaign.maxPlaysPerScreen,
        };
        await actions.updateNewCampaignTargetGroup(targetGroupData, "create");
      }
    } else if (action === "list") {
      await actions.updateTargetGroupAction(action);
    } else {
      const targetGroup = await actions.getTargetGroupById(action, "edit");
      if (isEmpty(targetGroup)) {
        this.setState({
          isValidTargetGroup: false,
          isPageLoading: false,
        });
      } else {
        targetGroupData = targetGroup;
      }
    }

    this.setState({
      targetGroup: targetGroupData,
      isPageLoading: false,
    });
  };

  componentDidUpdate(prevProps) {
    const {
      match: { params },
      campaignCreate: { targetGroupAction, campaignTargetGroup },
      history,
      actions,
    } = this.props;

    // if (
    //   campaignTargetGroups?.data?.length === 0 &&
    //   prevProps.campaignCreate.campaignTargetGroups?.data?.length !==
    //     campaignTargetGroups?.data?.length
    // ) {
    //   if (targetGroupAction === "list") {
    //     history.push(`/campaign-create/${params.campaignId}/target-groups/create`);
    //     actions.updateTargetGroupAction("create");
    //   }
    // }

    // Detect changes in action (create, list)
    if (prevProps.match.params.action !== params.action) {
      if (params.action === "list") {
        this.setState({ targetGroup: this.getInitialState().targetGroup });
        actions.updateTargetGroupAction("list");
        actions.getNewCampaignTargetGroups(params);
      } else if (params.action === "create") {
        this.handleTargetGroupCreate();
      }
    }

    if (prevProps.campaignCreate.targetGroupAction !== targetGroupAction) {
      if (targetGroupAction === "list") {
        history.push(`/campaign-create/${params.campaignId}/target-groups/list`);
        this.setState({ targetGroup: this.getInitialState().targetGroup });
      }

      if (targetGroupAction === "edit" || targetGroupAction === "duplicate") {
        if (targetGroupAction === "duplicate") {
          campaignTargetGroup.status = CAMPAIGN_STATUSES.DRAFT;
        }
        if (!params.id && targetGroupAction === "edit")
          history.push(
            `/campaign-create/${params.campaignId}/target-groups/${campaignTargetGroup.id}`
          );
        this.setState({
          targetGroup: campaignTargetGroup,
        });
      }
    }
  }

  onInputChange = (data, key) => {
    //SLATE-1546 Feb7 Anushree:- Utilizing functional setState and spread operator for non-mutative state updates.
    this.setState((prevState) => {
      const targetGroup = { ...prevState.targetGroup };
      if (key === "validity") {
        const [fromDate, toDate] = data;
        const validity = {
          fromDate: fromDate
            ? moment(fromDate).format("YYYY-MM-DD")
            : targetGroup.validity?.fromDate ?? null,
          toDate: toDate
            ? moment(toDate).format("YYYY-MM-DD")
            : targetGroup.validity?.toDate ?? null,
        };
        targetGroup[key] = validity;
      } else {
        targetGroup[key] = data;
      }
      return {
        targetGroup,
        isFormEdited: true,
      };
    });
  };

  updateDefaultValues = (data) => {
    const currentItem = THEATRE_CRITERIA_MENU_LIST.find((item) => item.id === data.type);
    let criteriaValues = {};
    //default values for auditoriumDimensions
    map(currentItem.accessors, (key) => {
      criteriaValues[key] = {
        rangeFrom: data[key].rangeFrom || 0,
        rangeTo: data[key].rangeTo || Infinity,
      };
    });
    //SLATE-1434 Dec11 Anushree:- default value for ticketPrice and seatingCapacity added
    if (
      currentItem?.id === THEATRE_CRITERIA_TYPES.TICKET_PRICE ||
      currentItem?.id === THEATRE_CRITERIA_TYPES.SEATING_CAPACITY
    ) {
      criteriaValues = {
        rangeFrom: data.rangeFrom || 0,
      };
    }

    return {
      ...data,
      ...criteriaValues,
    };
  };

  updateCriteriaList = (data, listType, targetType, isRemoved = false) => {
    //SLATE-1546 Feb7 Anushree:- Utilizing functional setState and spread operator for non-mutative state updates.
    this.setState((prevState) => {
      const targetGroup = { ...prevState.targetGroup };
      let criteria = { ...targetGroup.criteria };
      const criteriaList = {
        ...data,
        targetGroupType: targetType,
      };
      const errors = [];

      if (isRemoved) {
        targetGroup.criteria = { ...criteria, [listType]: data };
        return {
          targetGroup,
          errors,
          isFormEdited: true,
        };
      }

      if (targetType === "location") {
        const locationCriteriaErrors = validateLocationCriteria(criteria, criteriaList, listType);
        if (locationCriteriaErrors.length === 0)
          criteria = {
            ...criteria,
            [listType]: [...criteria[listType], criteriaList],
          };
        errors.push(...locationCriteriaErrors);
      } else if (targetType === "theatre") {
        const theatreCriteriaErrors = validateTheatreCriteria(criteria, criteriaList, listType);
        if (theatreCriteriaErrors.length === 0)
          criteria = {
            ...criteria,
            [listType]: [...criteria[listType], this.updateDefaultValues(criteriaList)],
          };
        errors.push(...theatreCriteriaErrors);
      }

      targetGroup.criteria = criteria;
      return {
        targetGroup,
        errors,
        isFormEdited: true,
      };
    });
  };

  // TODO: Update URL once flow is completed
  handleTargetGroupDelete = (targetGroupId) => {
    const {
      actions,
      match: { params },
    } = this.props;
    actions.deleteTargetGroup(params, targetGroupId);
  };

  handleCampaignDelete = async () => {
    const {
      history,
      match: { params },
    } = this.props;
    const resp = await this.props.actions.deleteCampaign(params.campaignId);
    if (resp && resp.success) {
      history.push(`/campaigns`);
    }
  };

  handleTargetGroupCreate = async () => {
    const {
      actions,
      match: { params },
      history,
    } = this.props;
    history.push(`/campaign-create/${params.campaignId}/target-groups/create`);
    //SLATE-1444 Dec15 Anushree:- reset form on creation of new campaign
    this.handleFormReset();
    await actions.updateTargetGroupAction("create");
  };

  handleTargetGroupEdit = (targetGroupId) => {
    const {
      match: { params },
      actions,
      history,
    } = this.props;
    if (params.campaignId) {
      actions.getTargetGroupById(targetGroupId, "edit");
      history.push(`/campaign-create/${params.campaignId}/target-groups/${targetGroupId}`);
    }
  };

  handleTargetGroupDuplicate = (targetGroupId) => {
    const {
      match: { params },
      actions,
      history,
    } = this.props;

    actions.getTargetGroupById(targetGroupId, "duplicate");
    history.push(`/campaign-create/${params.campaignId}/target-groups/create?id=${targetGroupId}`);
  };

  handleFormReset = () => {
    const {
      match: {
        params: { action },
      },
      campaignCreate: { campaignTargetGroup },
    } = this.props;
    if (action !== "list" && action !== "create") {
      this.setState({
        targetGroup: campaignTargetGroup,
        hasFormSaved: false,
        isFormEdited: false,
      });
    } else {
      this.setState({
        ...this.getInitialState(),
        isPageLoading: false,
      });
    }
  };

  handleSaveTargetGroupAsDraft = async () => {
    const { targetGroup } = this.state;
    const {
      match: { params },
      actions,
    } = this.props;

    if (targetGroup.name) {
      targetGroup.campaignId = params.campaignId;
      await actions.createOrUpdateTargetGroup(params.campaignId, targetGroup, "edit");
    }

    this.setState({
      hasFormSaved: true,
      isFormEdited: false,
    });
  };

  handleChangeStep = (preferredStep) => {
    const {
      match: { params },
      history,
      campaignCreate: { activeStep, targetGroupAction, campaignTargetGroups },
      actions,
    } = this.props;
    const { targetGroup, isFormEdited } = this.state;

    if (preferredStep === activeStep - 1) {
      if (targetGroupAction !== "list") {
        // Go back to target group list page without decrementing the step
        history.push(`/campaign-create/${params.campaignId}/target-groups/list`);
        return false;
      }
    }

    //SLATE-1417 Dec6 Anushree:- initial condition checks if movement is to direct next step, else executes campaignStepChange function.
    if (preferredStep === activeStep + 1 && params.campaignId) {
      if (targetGroupAction === "list") {
        //SLATE-1485 Jan3 Anushree:- Movement to Media page restricted if no targetGroup has been created
        if (campaignTargetGroups.data.length > 0) {
          history.push(`/campaign-create/${params.campaignId}/media/list`);
          return false;
        } else {
          showToast("Please Create Target Group", false);
        }
      }
      if (
        params.action !== "list" &&
        validateMandatoryTargetGroupFields(targetGroup, targetGroupAction)
      ) {
        targetGroup.campaignId = params.campaignId;
        //Only if form is edited then call update target group api
        if (isFormEdited) actions.createOrUpdateTargetGroup(params.campaignId, targetGroup, "list");
        else {
          actions.updateTargetGroupAction("list");
          actions.getNewCampaignTargetGroups(params);
        }
      } else {
      }
    } else {
      campaignStepChange(history, params, activeStep, preferredStep);
    }

    this.setState({
      hasFormSaved: true,
      isFormEdited: false,
    });
  };

  renderCampaignTree = () => {
    const {
      isCampaignInvalid,

      match: {
        params: { campaignId },
      },
    } = this.props;

    const { isValidTargetGroup } = this.state;
    if (isCampaignInvalid || !isValidTargetGroup) return null;
    return (
      <div className={campaignFormStyles.campaignTree}>
        <CampaignTree
          isNewCampaign
          // isLoading={false}
          // campaignTreeData={{
          //   ...campaign,
          //   targetGroups: targetGroupAction === "list" ? campaignTargetGroups?.data : [targetGroup],
          // }}
          // campaignCreate={campaignCreate}
          campaignIdForSummary={campaignId}
        />
      </div>
    );
  };

  renderTargetGroupSection = () => {
    const {
      isCampaignInvalid,
      actions,
      campaignCreate: {
        campaign,
        campaignTargetGroups,
        targetGroupAction,
        isTargetGroupLoading,
        isTargetGroupDetailsLoading,
      },
    } = this.props;
    const { targetGroup, hasFormSaved, errors, isPageLoading, isValidTargetGroup } = this.state;
    if (isCampaignInvalid || !isValidTargetGroup)
      return <EmptyState type={isValidTargetGroup ? "campaign" : "TargetGroup"} />;

    if (isPageLoading) return <InfoBlockLoading />;

    if (targetGroupAction === "list") {
      return (
        <TargetGroupList
          targetGroups={campaignTargetGroups?.data}
          onEdit={this.handleTargetGroupEdit}
          onDuplicate={this.handleTargetGroupDuplicate}
          onDelete={this.handleTargetGroupDelete}
          showAdd
          isLoading={isTargetGroupLoading}
          isDetailsLoading={isTargetGroupDetailsLoading}
          countries={campaign.countries}
          getTargetGroupDetails={actions.getTargetGroupById}
          onTargetGroupCreate={this.handleTargetGroupCreate}
          showSplit
          onSplit={this.handleTargetGroupSplit}
        />
      );
    } else {
      return (
        <CreateTargetGroup
          campaign={campaign}
          targetGroups={campaignTargetGroups.data}
          hasFormSaved={hasFormSaved}
          targetGroup={targetGroup}
          onInputChange={this.onInputChange}
          criteria={targetGroup.criteria}
          errors={errors}
          onCriteriaListUpdate={this.updateCriteriaList}
          companyId={this.props.userData.company.id}
          targetGroupAction={targetGroupAction}
        />
      );
    }
  };
  //method to handle the target group split
  handleTargetGroupSplit = (targetGroupId) => {
    const {
      campaignCreate: {
        campaignTargetGroups: { data },
      },
    } = this.props;
    //finding the targetGroup validity for which the split will occur
    const targetGroupValidityForSplit = data.find((item) => item.id === targetGroupId)?.validity;
    this.setState({
      isSplitPanelOpen: true,
      targetGroupSplitData: { id: targetGroupId, validity: targetGroupValidityForSplit },
    });
  };

  closeSplitPanel = () => {
    this.setState({
      isSplitPanelOpen: false,
      targetGroupValidityForSplit: { id: "", validity: {} },
    });
  };

  render() {
    const {
      history,
      campaignCreate: {
        isTargetGroupLoading,
        campaign,
        activeStep,
        campaignMediaList,
        targetGroupAction,
      },
      match: { params },
    } = this.props;
    const { hasFormSaved, isFormEdited, isSplitPanelOpen, targetGroupSplitData } = this.state;

    if (isTargetGroupLoading) return <PageLoadingWithTabs />;

    const headerTitle =
      !campaign.id || (targetGroupAction === "list" && !campaign.id)
        ? "PageHeader.newTargetGroup"
        : campaign.name;

    return (
      <div className={`col-12 clearfix ${styles.pageWrapper}`}>
        <div className={styles.container}>
          <Breadcrumb
            history={history}
            links={[
              { name: "PageHeader.campaigns", path: "/campaigns" },
              { name: headerTitle, path: `/campaigns/campaigns/${campaign.id}/details` },
              { name: "PageHeader.editTargetGroup" },
            ]}
          />
          <div className={classNames("col-12 clearfix")}>
            <div className={campaignFormStyles.campaignCreateProgressBar}>
              <CampaignPageHeader
                title={headerTitle}
                onSaveCampaignAsDraft={this.handleSaveTargetGroupAsDraft}
                onDiscardCampaign={this.handleCampaignDelete}
                isDiscardDisabled={!campaign.id || campaign.status !== CAMPAIGN_STATUSES.DRAFT}
                hideDraftButton
              />
              <CampaignSteps
                activeStep={activeStep}
                validTillStep={campaignMediaList.data?.length > 0 ? activeStep + 1 : activeStep}
                hasFormSaved={hasFormSaved}
                disableSave={!campaign.id}
                disableReset={!isFormEdited}
                onChangeStep={this.handleChangeStep}
                onReset={this.handleFormReset}
                isAdminUser={this.validateCreateCampaignAccess}
                onSaveDraft={() => {}}
              />
            </div>
            <div className={classNames("col col-12", campaignFormStyles.pageContent)}>
              {this.renderTargetGroupSection()}
              {this.renderCampaignTree()}
            </div>
            {isSplitPanelOpen && (
              <SplitTargetGroup
                validity={targetGroupSplitData.validity}
                header="RightPanelHeader.splitTargetGroup"
                isOpen={isSplitPanelOpen}
                onClose={this.closeSplitPanel}
                splitCampaigns={(data) => {
                  this.props.actions.splitTargetGroup(params, targetGroupSplitData.id, data);
                }}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = createSelector(
  (state) => state.userData,
  (state) => state.campaignCreate,
  (userData, campaignCreate) => ({
    userData: userData.user,
    campaignCreate: campaignCreate,
  })
);

export default connect(mapStateToProps, bindDispatch)(campaignRouteHandler(TargetGroup));
