import { BUTTON_TYPE } from "prefab";
import PropTypes from "prop-types";
import React, { Component } from "react";
import Form from "../../../common/Form";
import RightPanel from "../../../common/RightPanel";
import { FORM_FIELD_TYPE } from "../../../../constants";
import styles from "../../../../styles/InfoBlock.module.scss";

class AddCampaignBias extends Component {
  state = {
    biasValue: "",
    hasError: false,
  };

  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    header: PropTypes.string.isRequired,
    onSave: PropTypes.func.isRequired,
    campaign: PropTypes.object.isRequired,
  };

  componentDidMount() {
    const { campaign } = this.props;
    this.setState({ biasValue: campaign.bias || "" });
  }

  cancelAction = () => {
    this.setState({ hasError: false, biasValue: "" });
    this.props.onClose();
  };

  save = () => {
    const { biasValue } = this.state;
    if (!this.isValidBias(biasValue)) {
      this.setState({ hasError: true });
      return;
    }
    this.props.onSave({ biasValue: parseFloat(biasValue) });
    this.cancelAction();
  };

  handleBiasChange = (e) => {
    const { value } = e.target;

    // Regular expression to allow only numbers, negative sign, and decimal point
    const regex = /^-?\d*\.?\d*$/;

    if (regex.test(value)) {
      this.setState({
        biasValue: value,
        hasError: !this.isValidBias(value),
      });
    }
  };

  isValidBias = (value) => {
    const num = parseFloat(value);
    return !isNaN(num);
  };

  render() {
    const { isOpen, onClose, header, campaign } = this.props;
    const { biasValue, hasError } = this.state;

    return (
      <RightPanel
        isOpen={isOpen}
        onClose={onClose}
        header={header}
        showFooter={true}
        primaryButtonProps={[
          {
            text: "Save",
            buttonType: BUTTON_TYPE.PRIMARY,
            onClick: this.save,
          },
        ]}
        secondaryButtonProps={{
          text: "Cancel",
          onClick: this.cancelAction,
        }}
      >
        <div className={styles.primary}>
          <div className={styles.block}>
            <h3 className={styles.inputTitle}>Campaign Name</h3>
            <p className={styles.textValue}>{campaign?.name}</p>

            <h3 className={styles.inputTitle}>Advertiser</h3>
            <p className={styles.subTitle}>{campaign?.advertiserName}</p>

            <h3 className={styles.inputTitle}>Validity</h3>
            <p className={styles.subTitle}>
              {`${campaign?.validity?.fromDate} to ${campaign?.validity?.toDate}`}
            </p>

            <h3 className={styles.inputTitle}>Created By</h3>
            <p className={styles.subTitle}>
              {`${campaign?.createdBy?.firstName} ${campaign?.createdBy?.lastName}`}
            </p>

            <h3 className={styles.inputTitle}>Effective Rate</h3>
            <p className={styles.subTitle}>{campaign?.effectiveRate}</p>
          </div>
        </div>

        <div style={{ marginLeft: "-10px", marginTop: "-10px" }}>
          <Form
            config={[
              {
                id: "biasValue",
                label: "Bias (Numerical Input)",
                value: biasValue,
                placeholder: "Enter bias value",
                type: FORM_FIELD_TYPE.INPUT,
                error: hasError,
                errorMessage: "Enter a valid Bias Value",
                onChange: this.handleBiasChange,
              },
            ]}
          />
        </div>

        <div className={styles.primary}>
          <div className={styles.block}>
            <h3 className={styles.label}>Net Effective Rate</h3>
            <p className={styles.subTitle}>{campaign?.netEffectiveRate}</p>
          </div>
        </div>
      </RightPanel>
    );
  }
}

export default AddCampaignBias;
