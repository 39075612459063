import React, { Component } from "react";
import moment from "moment";
import classNames from "classnames";
import { startCase, isEmpty } from "lodash";
import { Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import Form from "../../../../common/Form";
import { CustomMenuItem } from "../../../../common";
import { SearchIcon } from "../../../../common/SearchIcon";
import RemoveIcon from "../../../../common/RemoveIcon";
import InfoBlock from "../../../../common/InfoBlock";
import {
  FORM_FIELD_TYPE,
  TAG_TYPE,
  CAMPAIGN_ACTIONS,
  CAMPAIGN_ACTIONS_ALLOWED_STATUS_LIST,
} from "../../../../../constants";
import { getDateRange, getRestrictedDateLimit } from "../../utils/campaignCreate";
import styles from "../../../../../styles/CampaignCreate/CampaignForm.module.scss";
import {
  searchContentTypes,
  searchBrandsWithAdvertiserId,
  categorySearchWithBrandAndText,
  searchCpls,
} from "../../../../../api";

export default class BasicDetails extends Component {
  state = {
    duplicateName: false,
    minDate: undefined,
    maxDate: undefined,
    productCategories: [],
  };

  componentDidMount() {
    const { media, campaign } = this.props;
    const { fromDate, toDate } = getRestrictedDateLimit(
      media?.targetGroup?.validity,
      campaign.validity
    );
    this.setState({
      minDate: fromDate ? new Date(fromDate) : undefined,
      maxDate: toDate ? new Date(toDate) : undefined,
    });
  }

  getRestrictedDateLimit = () => {
    const { media, campaign } = this.props;
    const validity = isEmpty(media?.targetGroup?.validity)
      ? campaign.validity
      : media.targetGroup.validity;
    if (!isEmpty(validity)) {
      const { fromDate, toDate } = validity;
      this.setState({
        minDate: fromDate ? new Date(fromDate) : undefined,
        maxDate: toDate ? new Date(toDate) : undefined,
      });
    }
  };

  onNameChange(value) {
    const { mediaList, onInputChange } = this.props;
    const allMediaNames = mediaList.map((t) => t.name);
    onInputChange(value, "name", allMediaNames.includes(value));
  }

  validateMediaContent(field) {
    const { media } = this.props;
    if (!media.contentId) {
      return media.contentTypeId && media.contentDurationInSeconds;
    }

    return Boolean(media[field]);
  }

  isSelectedCategory = (data) => {
    const {
      media: { brand },
    } = this.props;
    return brand?.categories?.find(
      (category) =>
        category.categoryId === data.categoryId &&
        category.subCategory1Id === data.subCategory1Id &&
        category.subCategory2Id === data.subCategory2Id
    );
  };

  arrowWithName = (n1, n2, n3) => {
    return [n1, n2, n3].filter((n) => n !== undefined && n !== null && n.length > 0).join(" > ");
  };

  categoryMenuRenderer = (data) => {
    return this.arrowWithName(data.categoryName, data.subCategory1Name, data.subCategory2Name);
  };

  renderBrandMenuName = (item) => {
    switch (item.type) {
      case "parentBrand":
        return `${item.parentBrandName}`;
      case "individualBrand":
        return `${item.parentBrandName}  ->  ${item.individualBrandName}`;
      case "product":
        return `${item.parentBrandName}  ->  ${item.individualBrandName}  ->  ${item.productName}`;
      case "variant":
        return `${item.parentBrandName}  ->  ${item.individualBrandName}  ->  ${item.productName}  ->  ${item.variantName}`;
      default:
        return `${item.parentBrandName}`;
    }
  };
  renderCategoryMenuItem = (data, { modifiers, handleClick }) => {
    return (
      <CustomMenuItem
        key={`${data.categoryId} ${data.subCategory1Id} ${data.subCategory2Id}`}
        active={modifiers?.active}
        icon={this.isSelectedCategory(data) ? IconNames.TICK : IconNames.BLANK}
        onClick={handleClick}
        text={this.categoryMenuRenderer(data)}
        shouldDismissPopover={false}
      />
    );
  };

  onBrandQueryChange = async (text) => {
    const { media } = this.props;

    if (media?.brandId) {
      const categories = await categorySearchWithBrandAndText(media?.brandId, text);
      const categoriesResponse = categories.data;

      const transformedCategories = categoriesResponse.map((categoryInfo) => {
        const category = categoryInfo?.category || {};
        const subCategory1 = categoryInfo?.subCategory1 || {};
        const subCategory2 = categoryInfo?.subCategory2 || {};

        return {
          categoryId: category.id || "",
          categoryName: category.name || "",
          subCategory1Id: subCategory1.id || "",
          subCategory1Name: subCategory1.name || "",
          subCategory2Id: subCategory2.id || "",
          subCategory2Name: subCategory2.name || "",
          updatedAt: category.updatedAt || "",
        };
      });
      this.setState({
        productCategories: transformedCategories,
      });
    }
  };

  render() {
    const {
      hasFormSaved,
      targetGroups,
      // media: { targetGroupId },
      media,
      onInputChange,
      isDuplicateName,
      removeCategory,
      campaign,
      // categoryList,
      // categorySearch,
      duplicateScheduleNumberMessage,
    } = this.props;

    const { productCategories } = this.state;

    const showError = hasFormSaved;

    const allowModifyingStartDate = CAMPAIGN_ACTIONS_ALLOWED_STATUS_LIST[
      CAMPAIGN_ACTIONS.EDIT_VALIDITY
    ].includes(media.status);

    const validity = campaign.id && !!campaign.validity ? campaign.validity : {};

    const availableCategories = productCategories.filter(
      (category) =>
        !media?.categories?.some(
          (selectedCategory) =>
            selectedCategory?.categoryId === category?.categoryId &&
            selectedCategory?.subCategory1Id === category?.subCategory1Id &&
            selectedCategory?.subCategory2Id === category?.subCategory2Id
        )
    );

    //!Date Range Validation
    const today = moment();
    const isPastCampaign = today > new Date(validity?.toDate);

    let dateRangeMin = undefined;
    let dateRangeMax = undefined;
    let endDateMin = undefined;
    let endDateMax = undefined;

    if (!isPastCampaign) {
      //date range: {from: max(tg start date,today), to: tg end date}
      dateRangeMin = validity?.fromDate
        ? moment.max(moment(validity?.fromDate), today).toDate()
        : new Date();
      dateRangeMax = validity?.toDate ? new Date(validity.toDate) : undefined;
      //end date: {from: max(media start date,today), to: tg end date}
      endDateMin = media.validity?.fromDate
        ? moment.max(moment(media.validity.fromDate), today).toDate()
        : undefined;
      endDateMax = dateRangeMax;
    }

    return (
      <InfoBlock header="Media Details" childClassName={styles.childWrapper}>
        <div
          className={classNames(
            "flex flex-wrap clearfix",
            styles.formContainer,
            styles.basicDetails
          )}
        >
          <Form
            isFormGroup={false}
            config={[
              {
                id: "name",
                label: "Media Name",
                type: FORM_FIELD_TYPE.INPUT,
                size: {
                  lg: 5,
                  md: 5,
                },
                placeholder: "Enter Name",
                value: media.name || "",
                onChange: (e) => this.onNameChange(e.target.value),
                error: (hasFormSaved && !media.name) || this.state.duplicateName,
                errorMessage: isDuplicateName ? "Duplicate Media Name" : "Please Enter Name",
              },
              {
                id: "targetGroup",
                key: "targetGroup",
                type: FORM_FIELD_TYPE.SELECT,
                size: {
                  lg: "col-lg-6 col-lg-offset-1",
                  md: "col-md-6 col-md-offset-1",
                },
                containerClassName: styles.mediaFormField,
                title: "Target Group",
                list: targetGroups.map((t) => ({ ...t, value: t.name })),
                // selectedList: !isEmpty(media.targetGroup) ? [media.targetGroup.name] : [],
                selectedList: !isEmpty(media.targetGroupName) ? [media.targetGroupName] : [],
                singleSelect: true,
                compareKey: "id",
                textKey: "name",
                usePortal: false,
                placeHolder: "Select Target Group",
                showLabelInButton: false,
                onSelect: ([data]) =>
                  onInputChange(
                    targetGroups.find((t) => t.id === data.id),
                    "targetGroup"
                  ),
                errorMessage: "Please Select Target Group",
                error: hasFormSaved && isEmpty(media.targetGroupName),
              },
              {
                id: "validity",
                type: FORM_FIELD_TYPE.DATE_RANGE_PICKER,
                formatDate: (date) => moment(date).format("DD/MM/YYYY"),
                title: "Validity",
                containerClassName: "col-11",
                size: {
                  lg: 4,
                  md: 4,
                },
                handleDateChange: (dateRange) => onInputChange(dateRange, "validity"),
                dateRange: getDateRange(media?.validity),
                // disabled: Object.keys(media.targetGroup).length === 0 || isPastCampaign, //!condition to disable might change in future
                disabled: isPastCampaign, //!condition to disable might change in future
                minDate: dateRangeMin,
                maxDate: dateRangeMax,
                errorMessage: "Please Enter Validity",
                error: hasFormSaved && (isEmpty(media.targetGroupName) || !media?.validity),
                isHidden: !allowModifyingStartDate,
                initialMonth: new Date(dateRangeMin),
              },
              {
                id: "startDate",
                type: FORM_FIELD_TYPE.INPUT,
                label: "Validity (Start Date)",
                size: { lg: 2, md: 2 },
                value: moment(media.validity.fromDate).format("MMM DD, YYYY"),
                disabled: !allowModifyingStartDate,
                isHidden: allowModifyingStartDate,
              },
              {
                id: "endDate",
                type: FORM_FIELD_TYPE.DATE,
                title: "Validity (End Date)",
                size: { lg: 2, md: 2 },
                onChange: (v) => onInputChange([null, v], "validity"),
                value: new Date(media.validity.toDate),
                placeholder: "End Date",
                isHidden: allowModifyingStartDate,
                minDate: endDateMin,
                maxDate: endDateMax,
                disabled: isPastCampaign, //!condition to disable might change in future
              },
              // {
              //   id: "purchaseOrderNumber",
              //   label: "Purchase Order Number",
              //   type: FORM_FIELD_TYPE.INPUT,
              //   className: "col-11",
              //   size: {
              //     lg: "col-lg-6 col-lg-offset-2",
              //     md: "col-md-6 col-md-offset-2",
              //   },
              //   placeholder: "Enter Purchase Order Number",
              //   value: media.purchaseOrderNumber || "",
              //   onChange: (e) => onInputChange(e.target.value, "purchaseOrderNumber"),
              //   disabled: false,
              // },
              [
                {
                  id: "contentId",
                  label: "Content",
                  type: FORM_FIELD_TYPE.API_SEARCH,
                  size: {
                    lg: 5,
                    md: 5,
                  },
                  placeholder: "Select Content",
                  showSubText: false,
                  resetOnSelect: false,
                  tagTypes: TAG_TYPE.CONTENT,
                  query: media.contentName ?? "",
                  fetchAction: (value) => {
                    onInputChange(value, "contentName");
                    return searchCpls(value);
                  },
                  // onfocus: (event) => {
                  //   // if (!event.target.value.length) {
                  //   onInputChange("", "contentTypeId");
                  //   onInputChange("", "contentDurationInSeconds");
                  //   onInputChange(null, "contentId");
                  //   // }
                  // },
                  parseResults: (response) =>
                    response.data.map((item) => ({
                      id: item.id,
                      name: item.name,
                      type: item.contentTypeName,
                      typeId: item.contentTypeId,
                      contentDurationInSeconds: item.durationInSeconds,
                      tag: startCase(item.contentTypeName),
                    })),
                  // selected: (list) => list.filter((item) => item.id === media.contentId),
                  onEmpty: () => {
                    onInputChange("", "contentName");
                    if (media.contentId) {
                      onInputChange("", "contentTypeId");
                      onInputChange("", "contentDurationInSeconds");
                      onInputChange(null, "contentId");
                    }
                  },
                  onSelect: (data) => {
                    onInputChange(data.name, "contentName");
                    onInputChange(data.id, "contentId");
                    onInputChange(data.typeId, "contentTypeId");
                    onInputChange(data.contentDurationInSeconds, "contentDurationInSeconds");
                  },
                  errorMessage: "Please Select Content",
                  // error: showError && !this.validateMediaContent("contentId"),
                  error: showError && isEmpty(media.contentName),
                  disabled: media.status === "Active",
                },
                {
                  id: "label",
                  type: FORM_FIELD_TYPE.LABEL,
                  value: "(or)",
                  size: { xl: 1, lg: 1, md: 1, sm: 12, xs: 12 },
                  className: styles.cplLabelText,
                  containerClassName: styles.labelOr,
                },

                {
                  type: FORM_FIELD_TYPE.API_SELECT,
                  id: "contentTypeId",
                  title: "Content Type",
                  placeholder: "Select Content Type",
                  size: {
                    lg: "col-lg-4",
                    md: "col-md-6",
                    // lg: 4,
                    // md: 6,
                  },
                  //isLabel: Boolean(media.contentId) && false,
                  fetchAction: () => searchContentTypes(-1, 0, "name"),
                  parseResults: (response) =>
                    response.data.map((item) => ({
                      id: item.id,
                      value: item.name,
                    })),
                  selected: (list) => {
                    return list.filter((item) => item.id === media.contentTypeId);
                  },
                  singleSelect: true,
                  disabled: !!media.contentId,
                  showLabelInButton: false,
                  errorMessage: "Please Select Content Type",
                  //error: showError && !this.validateMediaContent("contentTypeId"),
                  error: showError && isEmpty(media.contentTypeId),
                  // (isEmpty(media.contentId) || isEmpty(media.contentName)),
                  onSelect: ([data]) => onInputChange(data.id, "contentTypeId"),
                },
                {
                  id: "contentDurationInSeconds",
                  title: "Duration (In Seconds)",
                  type: FORM_FIELD_TYPE.NUMERIC_INPUT,
                  size: {
                    lg: 2,
                    md: 5,
                  },
                  //isLabel: Boolean(media.contentId),
                  placeholder: "Enter Duration",
                  disabled: !!media.contentId,
                  value: media.contentDurationInSeconds ? media.contentDurationInSeconds : "",
                  errorMessage: "Please Enter Duration",
                  //error: showError && !this.validateMediaContent("contentDurationInSeconds"),
                  error: showError && !media.contentDurationInSeconds,
                  onValueChange: (value) => onInputChange(value, "contentDurationInSeconds"),
                },
              ],
              {
                id: "brand",
                type: FORM_FIELD_TYPE.API_SEARCH,
                label: "Brand",
                placeholder: "Search for Brand",
                size: {
                  lg: 5,
                  md: 5,
                },
                onSelect: (item) => {
                  // clear product categories in case brand removal or brand change
                  // if (!item || item.id !== media?.brandId) {
                  //   removeCategory(-1);
                  // }
                  //onInputChange(item?.type, "brandType");

                  //product categories are cleared by onInputChange function itself
                  return onInputChange(item, "brandId");
                },
                // selected: (list) => {
                //   return list.filter((item) => item.id === media.brandId) ?? [];
                // },
                // query: media.brand?.name ?? "",
                query: media.brandName ?? media.brand?.name ?? "",
                showSubText: true,
                //isLabel: Boolean(media.contentId),
                parseResults: (data) =>
                  data.map((item) => ({
                    id: item[`${item.type}Id`],
                    name: this.renderBrandMenuName(item),
                    value: item[`${item.type}Name`],
                    type: item.type,
                    tag: startCase(item.type),
                  })),
                fetchAction: (value) => {
                  return searchBrandsWithAdvertiserId({
                    q: value,
                    advertiserId: this.props.campaign.advertiserId,
                  });
                },
                errorMessage: "Please Add Brand",
                //error: showError && !this.validateMediaContent("brandId"),
                error: showError && isEmpty(media.brandId),
                clearSelectOnReset: true,
                //! condition to disable might change in future
                disabled: media.status === "Active",
              },
              {
                id: "category",
                type: FORM_FIELD_TYPE.MULTI_SELECT,
                label: "Product Category",
                size: {
                  lg: "col-lg-6 col-lg-offset-1",
                  md: "col-md-6 col-md-offset-1",
                },
                tagRenderer: this.categoryMenuRenderer,
                tagInputProps: {
                  placeholder: "Enter Product Category",
                  tagProps: { intent: Intent.NONE, minimal: true },
                  onRemove: (data, index) => removeCategory(index),
                  disabled: !media.brandId,
                  rightElement:
                    media?.categoryIds?.length > 0 ? (
                      <RemoveIcon onClick={() => removeCategory(-1)} />
                    ) : (
                      <SearchIcon />
                    ),
                },
                onQueryChange: this.onBrandQueryChange,
                onClick: () => this.onBrandQueryChange(""),
                selectedItems: media?.categories ?? [],
                items: availableCategories,
                // incase of product categories are unavailable
                noResults: "No Results Found",
                onItemSelect: (data) => onInputChange(data, "categoryIds"),
                itemRenderer: this.renderCategoryMenuItem,
                resetOnSelect: true,
                //isLabel: Boolean(media.contentId),
                errorMessage: "Please Enter Product Category",
                //error: showError && !this.validateMediaContent("categoryIds"),
                error: showError && isEmpty(media.categoryIds),
              },
              {
                id: "scheduleNumber",
                label: "Schedule Number",
                type: FORM_FIELD_TYPE.INPUT,
                size: {
                  lg: 5,
                  md: 5,
                },
                errorMessage:
                  duplicateScheduleNumberMessage ||
                  (showError && !media.scheduleNumber ? "Please Enter Schedule Number" : ""),
                error: !!(duplicateScheduleNumberMessage || (showError && !media.scheduleNumber)),
                placeholder: "Enter Schedule Number",
                value: media?.scheduleNumber,
                onChange: (e) => onInputChange(e.target.value, "scheduleNumber"),
              },
            ]}
          />
        </div>
      </InfoBlock>
    );
  }
}
