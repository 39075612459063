import * as types from "../types/campaignBias";

const initialState = {
  isLoading: true,
  isError: false,
  data: [],
};

export default function reducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case types.IS_LOADING:
      return { ...state, isLoading: payload };
    case types.CAMPAIGN_BIAS_DATA:
      return { ...state, data: payload };
    case types.IS_ERROR:
      return { ...state, isError: payload };
    default:
      return state;
  }
}
