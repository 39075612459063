import { isEmpty } from "lodash";
import { Icons } from "prefab";
import PropTypes from "prop-types";
import React, { Component } from "react";
import styles from "../../../../../styles/CampaignCreate/TargetGroup.module.scss";
import TabView from "../../../../common/Tabs";

import * as constants from "../../../../../constants";
import {
  CAMPAIGN_ACTIONS,
  CAMPAIGN_ACTIONS_ALLOWED_STATUS_LIST,
  THEATRE_CRITERIA_TYPES,
} from "../../../../../constants";
import { locationColumn } from "../../../../../utils";
import ActionIcon from "../../../../common/ActionIcon";
import EmptyPage from "../../../../common/EmptyPage";
import SwitchButtonWithText from "../../../../common/SwitchButtonWithText";
import CriteriaList from "../../common/CriteriaList";
import BasicDetails from "./BasicDetails";
import LocationBasedInput from "./LocationBasedInput";
import TargetGroupTheatres from "./TargetGroupTheatres";
import TheatreBasedInput from "./TheatreBasedInput";

const { AddIcon, LocationIcon, TheatreIcon } = Icons;

export default class CreateTargetGroup extends Component {
  static propTypes = {
    campaign: PropTypes.object,
  };

  static defaultProps = {
    campaign: {
      countries: [],
    },
    errors: [],
  };

  state = {
    selectedTabId: "targets",
    activeTargetType: "theatre",
    criteria: {
      inclusions: [],
      exclusions: [],
    },
    includedProvinces: [],
    errors: [],
  };

  componentDidMount() {
    const { targetGroup, campaign, errors } = this.props;
    let targetGroupCriteria = this.props.criteria;
    if (!isEmpty(targetGroup) && !isEmpty(targetGroup?.criteria))
      targetGroupCriteria = targetGroup.criteria;
    this.setState({
      criteria: targetGroupCriteria,
      errors,
      countries: campaign?.countries ?? [],
    });
  }

  componentDidUpdate(prevProps) {
    const { criteria, errors } = this.props;
    if ((!isEmpty(criteria) && prevProps.criteria !== criteria) || prevProps.errors !== errors) {
      this.setState({ criteria, errors });
    }
  }

  handleTabChange = (newTabId) =>
    this.setState({
      selectedTabId: newTabId,
    });

  updateCurrentTargetingType = (currentTargetType) =>
    this.setState({
      activeTargetType: currentTargetType,
      errors: [],
    });

  validateTheatreCriteriaType = (targetGroupType) => {
    const { criteria } = this.state;
    const criteriaList = {
      inclusions: criteria?.inclusions?.filter((item) => item.targetGroupType === targetGroupType),
      exclusions: criteria?.exclusions?.filter((item) => item.targetGroupType === targetGroupType),
    };

    return !isEmpty(criteriaList.inclusions) || !isEmpty(criteriaList.exclusions);
  };

  handleRemoveCriteriaItem = (params) => {
    this.props.onCriteriaListUpdate(...params);
  };

  handleFormReset = () => {
    const { targetGroupEdit } = this.props;
    let targetGroupCriteria = this.state.criteria;

    if (!isEmpty(targetGroupEdit)) targetGroupCriteria = targetGroupEdit.criteria;
    this.setState({
      selectedTabId: "targets",
      activeTargetType: "theatre",
      criteria: targetGroupCriteria,
      includedProvinces: [],
      errors: [],
    });
  };
  //SLATE-1435 Dec11 Anushree:- method to reset errors to empty array
  handleErrorReset = () => {
    this.setState({ errors: [] });
  };

  renderTargetCriteriaTypes = () => {
    const { criteria, activeTargetType, countries, errors } = this.state;
    const {
      onCriteriaListUpdate,
      targetGroup,
      campaign: { id },
    } = this.props;

    // Filtered inclusions and exclusions arrays
    const filteredInclusions = criteria.inclusions.filter((item) => item.name !== undefined);
    const filteredExclusions = criteria.exclusions.filter((item) => item.name !== undefined);

    return (
      <div className={styles.targetTypesContainer}>
        <div className={styles.title}>Choose Targeting Type</div>
        <div className={styles.buttonContainer}>
          <SwitchButtonWithText
            label=""
            buttons={[
              {
                isActive: activeTargetType === "theatre",
                onClick: () => this.updateCurrentTargetingType("theatre"),
                text: "Button.theatre",
                icon: !this.validateTheatreCriteriaType("theatre") && <AddIcon />,
                style: { padding: "0 1.75rem" },
              },
              {
                isActive: activeTargetType === "location",
                onClick: () => this.updateCurrentTargetingType("location"),
                text: "Button.location",
                style: { padding: "0 1.75rem" },
              },
            ]}
          />

          {/* <LocalizedButton
            id="location"
            onClick={() => this.updateCurrentTargetingType("location")}
            text="Button.location"
            className={classNames(styles.button, {
              [styles.active]: activeTargetType === "location",
            })}
          />
          <LocalizedButton
            id="theatre"
            icon={!this.validateTheatreCriteriaType("theatre") && <AddIcon />}
            onClick={() => this.updateCurrentTargetingType("theatre")}
            text="Button.theatre"
            className={classNames(styles.button, {
              [styles.active]: activeTargetType === "theatre",
            })}
          /> */}
        </div>
        <div className={styles.contentWrapper}>
          {activeTargetType === "location" ? (
            <LocationBasedInput
              countries={countries}
              updateCriteriaList={(data, criteriaListType) =>
                onCriteriaListUpdate(data, criteriaListType, "location")
              }
              targetGroup={targetGroup}
            />
          ) : (
            <TheatreBasedInput
              updateCriteriaList={(data, criteriaListType) =>
                onCriteriaListUpdate(data, criteriaListType, "theatre")
              }
              handleErrorReset={this.handleErrorReset}
              companyId={this.props.companyId}
              targetGroup={targetGroup}
              campaignId={id}
            />
          )}
          <div className={styles.errorList}>
            {errors.map((error, index) => (
              <div key={index}>{error}</div>
            ))}
          </div>
          <CriteriaList
            targetGroupType="theatre"
            criteria={{
              inclusions: filteredInclusions,
              exclusions: filteredExclusions,
            }}
            errors={errors}
            headerIcon={<TheatreIcon />}
            headerName={"Theatre"}
            onRemoveCriteriaItem={(data, criteriaListType) =>
              this.props.onCriteriaListUpdate(data, criteriaListType, "theatre", true)
            }
            targetGroupAction={this.props.targetGroupAction}
            targetGroup={targetGroup}
          />
          <CriteriaList
            targetGroupType="location"
            criteria={{
              inclusions: filteredInclusions,
              exclusions: filteredExclusions,
            }}
            errors={errors}
            countries={countries}
            headerIcon={<LocationIcon />}
            headerName={"Location"}
            onRemoveCriteriaItem={(data, criteriaListType) =>
              this.props.onCriteriaListUpdate(data, criteriaListType, "location", true)
            }
            targetGroupAction={this.props.targetGroupAction}
            targetGroup={targetGroup}
          />
        </div>
      </div>
    );
  };

  excludeTheatre = (obj) => {
    const { id, name, screens } = obj;
    const exclusion = {
      id,
      name,
      tags: [id],
      type: !!screens ? THEATRE_CRITERIA_TYPES.THEATRE_BY_ID : THEATRE_CRITERIA_TYPES.SCREEN_BY_ID,
      targetGroupType: "theatre",
    };
    return exclusion;
  };
  onExclusion = (exclusion) => {
    const criteria = {
      inclusions: this.state.criteria.inclusions,
      exclusions: [...this.state.criteria.exclusions, { ...exclusion }],
    };
    this.setState({
      criteria: criteria,
    });
    this.props.onInputChange(criteria, "criteria");
  };
  render() {
    const { selectedTabId, criteria } = this.state;
    const { hasFormSaved, onInputChange, targetGroups, campaign, targetGroup } = this.props;

    const columns = [
      {
        id: "name",
        Header: "Theatre",
        accessor: "name",
      },
      locationColumn(this.props.onFilterIdSelect),
      {
        id: "averageWeeklyOccupancy",
        Header: "Average Weekly Occupancy",
        accessor: "averageWeeklyOccupancy",
      },
      {
        ...constants.DEFAULT_ACTION_ICON_COLUMN,
        Cell: (d) => (
          <ActionIcon
            iconProps={[
              {
                toolTip: "Exclude",
                iconName: "CancelFilledIcon",
                onClick: (e) => {
                  //SLATE-1404 Nov29 Anushree:- id is available in d.original
                  //this.excludeTheatre(d.id),
                  if (targetGroup.status !== "Active") {
                    this.onExclusion(this.excludeTheatre(d.original));
                  } else {
                    e.stopPropagation();
                  }
                },
                className: targetGroup.status === "Active" ? styles.disabledBtn : "",
              },
            ]}
          />
        ),
      },
    ];
    if (!CAMPAIGN_ACTIONS_ALLOWED_STATUS_LIST[CAMPAIGN_ACTIONS.EDIT].includes(targetGroup.status))
      return (
        <EmptyPage
          heading="EmptyState.targetGroupNotEditableHeading"
          body="EmptyState.targetGroupNotEditableMessage"
          isHomeLinkVisible={false}
        />
      );
    return (
      <div className={styles.formContainer}>
        <BasicDetails
          campaign={campaign}
          targetGroups={targetGroups}
          targetGroup={targetGroup}
          hasFormSaved={hasFormSaved}
          onInputChange={onInputChange}
        />
        <div className={styles.formContent}>
          <div className={styles.tabContainer}>
            <TabView
              id="campaignTabs"
              defaultSelectedTabId="targets"
              selectedTabId={selectedTabId}
              onChange={this.handleTabChange}
              tabs={[
                {
                  id: "targets",
                  title: "Targets",
                  panel: this.renderTargetCriteriaTypes(),
                },
                {
                  id: "theatres",
                  title: "Theatres/Screens",
                  panel: (
                    <TargetGroupTheatres
                      criteria={criteria}
                      columns={columns}
                      onExclusion={this.onExclusion}
                    />
                  ),
                },
              ]}
            />
          </div>
        </div>
      </div>
    );
  }
}
