import React, { Component } from "react";
import PropTypes from "prop-types";
import * as constants from "../../../constants";
import { searchLocation, fetchAdvertiserTypes } from "../../../api";
import RightPanel from "../../common/RightPanel";
import Form from "../../common/Form";

export default class EditAdvertiser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasEditError: false,
      advertiser: this.props.advertiser,
    };
  }
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    advertiser: PropTypes.object,
    onUpdate: PropTypes.func.isRequired,
  };

  componentDidUpdate(prevProps) {
    if (this.props.advertiser !== prevProps.advertiser)
      this.setState({ advertiser: this.props.advertiser });
  }

  cancelUpdate = () => {
    this.setState({ hasEditError: false });
    this.props.cancelEdit();
  };

  isValid = (advertiser) => {
    if (
      advertiser === undefined ||
      !advertiser.name ||
      !advertiser.cityId ||
      !advertiser.advertiserTypeId
    )
      return false;
    else return true;
  };

  update = () => {
    const { advertiser } = this.state;
    if (this.isValid(advertiser)) {
      this.props.onUpdate(advertiser);
      this.cancelUpdate();
    } else {
      return this.setState({ hasEditError: true });
    }
  };

  inputOnChange = (value, accessor) => {
    const { advertiser } = this.state;
    this.setState({
      advertiser: { ...advertiser, [accessor]: value },
    });
  };

  render = () => {
    const { isOpen } = this.props;
    const { advertiser = {}, hasEditError } = this.state;
    return (
      <RightPanel
        isOpen={isOpen}
        onClose={() => this.cancelUpdate()}
        header={advertiser.id ? "RightPanelHeader.updateClient" : "RightPanelHeader.addClient"}
        showFooter={true}
        isEdit={advertiser.id ? true : false}
        primaryButtonProps={
          advertiser.id
            ? { text: "Button.update", onClick: this.update }
            : { text: "Button.add", onClick: this.update }
        }
        secondaryButtonProps={{ text: "Button.cancel", onClick: this.cancelUpdate }}
      >
        <Form
          config={[
            {
              id: "name",
              type: constants.FORM_FIELD_TYPE.INPUT,
              label: "Client Name",
              className: "col-12",
              placeholder: "Enter Client Name",
              value: advertiser.name,
              error: hasEditError && !advertiser?.name,
              errorMessage: "Enter Client Name",
              onChange: (e) => this.inputOnChange(e.target.value, "name"),
            },
            {
              id: "advertisertype",
              type: constants.FORM_FIELD_TYPE.API_SELECT,
              label: "Client Type",
              className: "col-12",
              placeholder: "Select Client Type",
              value: advertiser.advertiserTypeId,
              fetchAction: () => fetchAdvertiserTypes("rank"),
              parseResults: (response) => {
                return [
                  ...response.map((item) => ({
                    id: item.id,
                    value: item.name,
                  })),
                ];
              },
              selected: (list) => list.filter((item) => item.id === advertiser.advertiserTypeId),
              singleSelect: true,
              showLabelInButton: false,
              onSelect: ([data]) => {
                this.inputOnChange(data.id, "advertiserTypeId");
              },
              error: hasEditError && !advertiser?.advertiserTypeId,
              errorMessage: "Select Client Type",
            },
            {
              id: "cityId",
              type: constants.FORM_FIELD_TYPE.API_SEARCH,
              label: "Location",
              placeholder: "Select Location",
              onSelect: (props) => {
                this.inputOnChange(props.id, "cityId");
              },
              searchIcon: "right",
              query: advertiser.cityName,
              showSubText: true,
              parseResults: (data) => {
                return data.results.slice(0, 15).map((item) => ({
                  id: item.id,
                  name: item.name,
                  tag: `${item.province_name} · ${item.country_name}`,
                }));
              },
              fetchAction: (value) => searchLocation(value, null, "city"),
              error: hasEditError && !advertiser?.cityId,
              errorMessage: "Select location",
            },
          ]}
        />
      </RightPanel>
    );
  };
}
