import { getCampaignBias as getCampaignBiasApi } from "../../api";
import { updateCampaignBias as updateCampaignBiasApi } from "../../api";
import * as types from "../types/campaignBias";
import * as constants from "../../constants";
import { showToast } from "../../utils";

const actionCreator = (type, payload) => {
  return { type, payload };
};

// Update campaign bias data action
const updateCampaignBiasData = (payload) => {
  return { type: types.CAMPAIGN_BIAS_DATA, payload: payload };
};

// Fetch campaign bias list function
const getCampaignBiasData = (
  params,
  filters = [],
  getList = getCampaignBiasApi,
  isPartial = true
) => {
  const { page = 1, ps = constants.DEFAULT_PAGE_SIZE, sort = "name:asc" } = params;

  return async (dispatch) => {
    try {
      dispatch(actionCreator(types.IS_LOADING, true));
      const { data } = await getList(ps, (page - 1) * ps, sort, filters);

      dispatch(updateCampaignBiasData(data));
      dispatch(actionCreator(types.IS_LOADING, false));
    } catch (error) {
      dispatch(actionCreator(types.IS_ERROR, error.message));
      dispatch(actionCreator(types.IS_LOADING, false));
    }
  };
};

const updateCampaignBiasValue = (campaignId, payload) => {
  return async (dispatch) => {
    try {
      dispatch(actionCreator(types.IS_LOADING, payload));
      await updateCampaignBiasApi(campaignId, payload);
      await showToast("Toast.updatedCampaignBias", true);
      dispatch(actionCreator(types.IS_LOADING, false));
    } catch (error) {
      const errorMessage =
        error?.response?.data?.message || error.message || "Toast.unexpectedError";
      dispatch(actionCreator(types.IS_ERROR, errorMessage));
      await showToast(errorMessage, false);
      dispatch(actionCreator(types.IS_LOADING, false));
    }
  };
};

export { getCampaignBiasData, updateCampaignBiasValue };
