import { groupBy, partition, values, isEmpty, mapValues, mapKeys, last } from "lodash";
import moment from "moment";
import {
  FILTER_CONDITION_TYPE,
  DATE_RANGE_TAGS,
  STATIC_FILTER_TAGS,
  OPERATION_TAGS,
  TAG_TYPE,
} from "../constants";

const _createQueryParams = (filters) => {
  if (isEmpty(filters)) return null;
  const groups = groupBy(filters, "type");
  const params = mapValues(groups, (group) => group.map((g) => g.id));
  return mapKeys(params, (v, key) => last(key.split("_")) || key);
};

const _createFilterChild = (tag) => {
  if (DATE_RANGE_TAGS.includes(tag.type)) {
    const { to, from, type: fieldName } = tag;
    let toDate = to,
      fromDate = from;
    if (fromDate) fromDate = moment(fromDate).startOf("day").toDate();
    if (toDate) toDate = moment(toDate).endOf("day").toDate();
    const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
    return {
      dateTimeRange: {
        to: toDate,
        from: fromDate,
        fieldName,
        ...(tag.type === TAG_TYPE.SHOW_DATE && { timeZone }), // Conditionally include timeZone for Spot Status
      },
    };
  } else if (OPERATION_TAGS.includes(tag.type)) {
    const { operation, value, type: fieldName } = tag;
    return { operatorFilter: { fieldName, operation, value } };
  } else return { tagId: tag.id };
};

const _createFilterChildren = (tags) => {
  // console.log("_createFilterChildren 3", tags);
  if (tags.length === 1) return _createFilterChild(tags[0]);
  if (tags.length > 1) {
    return {
      condition: FILTER_CONDITION_TYPE.OR,
      children: tags.map((tag) => _createFilterChild(tag)),
    };
  }
};

const _createFilterTree = (tagFilters) => {
  // console.log("_createFilterTree 2", tagFilters);
  if (tagFilters.length === 0) return null;
  const tagGroups = values(groupBy(tagFilters, "type"));
  if (tagGroups.length === 1) return _createFilterChildren(tagGroups[0]);
  return {
    condition: FILTER_CONDITION_TYPE.AND,
    children: tagGroups.map((tagGroup) => _createFilterChildren(tagGroup)),
  };
};

const createFilterRequest = (filters) => {
  // console.log("createFilterRequest 1", filters);
  if (isEmpty(filters)) return {};
  const [staticFilters, tagFilters] = partition(filters, (filter) =>
    STATIC_FILTER_TAGS.includes(filter.type)
  );
  return {
    qp: _createQueryParams(staticFilters),
    req: _createFilterTree(tagFilters),
  };
};

export default createFilterRequest;
