import React from "react";
import moment from "moment";
import { lowerCase } from "lodash";
import { Icons } from "prefab";
import classNames from "classnames";
import { Tooltip } from "@blueprintjs/core";
import {
  CAMPAIGN_STATUS_LIST,
  CONTENT_AVAILABILITY_STATUS,
  SPECIAL_CHARACTERS,
  TAG_TYPE,
  THEATRE_CRITERIA_TYPES,
  STATIC_FILTER_TAG_LIST,
} from "../../../../constants";
import StatusIcon from "../../../common/StatusIcon";
import StatusIconLegend from "../../../common/StatusIconLegend";
import InfoBlock from "../../../common/InfoBlock";
import {
  formatValidityPeriod,
  sortByEarliestDate,
  formatDateTime,
  getUserTimeZone,
} from "../../../../utils";
import styles from "../../../../styles/Campaigns/Campaigns.module.scss";
import treeStyles from "../../../../styles/Campaigns/CampaignTree.module.scss";
import iconStyles from "../../../../styles/Icon.module.scss";
import activeCellStyle from "../../../../styles/ActiveTableCell.module.scss";
import getContactDetails from "./getContactDetails";
import getBillingInfo from "./getBillingInfo";
import getSummaryBlock from "./getSummaryBlock";
import getScrollableSummaryBlock from "./getScrollableSummaryBlock";
import MediaPlacements from "./MediaPlacements";
import validateSelectedCampaignsDurations from "./validateSelectedCampaignsDurations";
import isCampaignStatusChangesAvailable from "./isCampaignStatusChangesAvailable";
import getCurrentActionPeriod from "./getCurrentActionPeriod";
import validateCampaignsAction from "./validateCampaignsAction";
import {
  RemovePausedDurationsFromDurationGantt,
  convertDateRangeToSingleDaySegments,
} from "./ganttChartTools";

const { InfoFilledIcon } = Icons;

const { TheatreIcon, LocationIcon, DurationIcon, AdGroupIcon } = Icons;

const getStatus = (d, onFilterSelect = null) => {
  const [statusDetails] = CAMPAIGN_STATUS_LIST.filter((status) => status.displayName === d.status);
  if (!statusDetails) return "";
  return (
    <div
      className={`flex align-center cursor ${activeCellStyle.highlight}`}
      onClick={(e) => {
        e.stopPropagation();
        onFilterSelect &&
          onFilterSelect({
            id: statusDetails.id,
            displayName: statusDetails.displayName,
            type: TAG_TYPE.CAMPAIGN_STATUS,
          });
      }}
    >
      <StatusIcon color={statusDetails.color} type={statusDetails.type} />
      <span>{statusDetails.displayName}</span>
    </div>
  );
};
//general status display function
const getStatusDisplay = (d, onFilterSelect = null, tagType = "", showStatusIcon = false) => {
  const [statusDetails] = STATIC_FILTER_TAG_LIST[tagType].filter((status) => status.id === d);
  if (!statusDetails) return "";
  return (
    <div
      className={`flex align-center cursor ${activeCellStyle.highlight}`}
      onClick={(e) => {
        e.stopPropagation();
        onFilterSelect &&
          onFilterSelect({
            id: statusDetails.id,
            displayName: statusDetails.displayName,
            type: tagType,
          });
      }}
    >
      {showStatusIcon &&
        statusDetails &&
        (statusDetails.icon ? (
          <div className={`${iconStyles[statusDetails.color]}`}>
            <statusDetails.icon />
          </div>
        ) : (
          <StatusIcon color={statusDetails.color} type={statusDetails.type} />
        ))}

      <span>{statusDetails.displayName}</span>
    </div>
  );
};

const getContentAvailabilityStatus = (d, onFilterSelect = null) => {
  const availabilityStatus = CONTENT_AVAILABILITY_STATUS.find(
    (status) => status.id === d.isContentAvailable
  );
  if (!availabilityStatus) return "";
  return (
    <div
      className={`flex align-center cursor ${activeCellStyle.highlight}`}
      onClick={() => {
        onFilterSelect &&
          onFilterSelect({
            id: availabilityStatus.id,
            displayName: availabilityStatus.displayName,
            type: TAG_TYPE.CONTENT_AVAILABILITY,
          });
      }}
    >
      <span>{availabilityStatus.displayName}</span>
    </div>
  );
};
//general status legend function
const getStatusIconLegend = (
  data,
  tagType,
  onFilterSelect,
  statusPropName = "status",
  statusIcon = false,
  showCount = true
) => {
  if (!data) {
    return null;
  }

  const statusInfo = STATIC_FILTER_TAG_LIST[tagType].map((status) => ({
    name: status.displayName,
    id: status.id,
    // Check if statusPropName is an array or a string and handle accordingly
    count:
      data &&
      data.filter((row) =>
        Array.isArray(row[statusPropName])
          ? row[statusPropName].includes(status.id)
          : row[statusPropName] === status.id
      ).length,
    type: status.type,
    color: status.color,
    showCount,
    tagType,
    ...(statusIcon && { icon: status.icon }),
  }));

  return <StatusIconLegend statusInfo={statusInfo} onFilterSelect={onFilterSelect} />;
};

const getValidity = (data, format = "MMM DD, YYYY", hideStatusChanges = false) => {
  if (!data.validity) return "";

  const currentValidity = formatValidityPeriod(data.validity, format);

  const statusChanges = data.statusChanges
    ? data.statusChanges.map((change) =>
        [
          change.status,
          "from",
          [
            formatDateTime(change.fromDate, "DD MMM YYYY"),
            !change.toDate && moment().isAfter(moment(data.validity.toDate))
              ? formatDateTime(data.validity.toDate, "DD MMM YYYY")
              : formatDateTime(change.toDate, "DD MMM YYYY"),
          ].join(" to "),
        ].join(" ")
      )
    : [];

  return (
    <>
      <div>{currentValidity}</div>
      {!hideStatusChanges &&
        statusChanges.map((statusChange, key) => (
          <div key={key} className={styles.secondaryValue}>
            {statusChange}
          </div>
        ))}
    </>
  );
};

const targetGroupIcons = (type) => {
  switch (lowerCase(type)) {
    case "location":
      return <LocationIcon />;
    case "theatre":
      return <TheatreIcon />;
    case "movie":
      return <AdGroupIcon />;
    case "time":
      return <DurationIcon />;
    default:
      return <TheatreIcon />;
  }
};

const getName = (data, isAdminApp) => {
  return (
    <>
      <div>{`${data.name} (${data.code})`}</div>
      {isAdminApp && <div className={styles.secondaryValue}>{data.id}</div>}
    </>
  );
};

const getInfo = (campaign, isAdminApp = false) => {
  if (!isAdminApp) {
    return (
      <>
        <div>{`${campaign?.createdBy?.firstName} ${campaign?.createdBy?.lastName}`}</div>
        <div className={styles.secondaryValue}>{`${campaign?.createdBy?.id}`}</div>
      </>
    );
  } else {
    return (
      <>
        <div>{`${campaign.advertiserName}`}</div>
        <div className={styles.secondaryValue}>{isAdminApp ? `${campaign.advertiserId}` : ""}</div>
      </>
    );
  }
};

const getCampaignBasicDetails = (campaign, isAdminApp = false, header = "Campaign Details") => (
  <InfoBlock
    header={header}
    config={[
      {
        title: "Campaign Name & ID",
        value: getName(campaign, isAdminApp),
        size: {
          lg: 6,
        },
      },
      {
        title: `Validity  (${getUserTimeZone().abbr})`,
        value: getValidity(campaign, "DD MMM YYYY"),
        size: {
          lg: 6,
        },
      },
      {
        title: "Country",
        value: campaign.countryName,
        size: {
          lg: 6,
        },
      },
      {
        title: "Currency",
        value: campaign.currencyName,
        size: {
          lg: 6,
        },
      },
      {
        title: "Created by",
        value: campaign.createdBy && getInfo(campaign),
        size: {
          lg: 6,
        },
      },
      {
        title: isAdminApp ? "Created for" : "Client",
        value: `${campaign.advertiserName}${isAdminApp ? ` (${campaign.advertiserId})` : ""}`,
        size: {
          lg: 6,
        },
      },
      {
        title: "Campaign Value",
        value: campaign?.campaignValue ? `${campaign.currencyCode} ${campaign.campaignValue}` : "",
        size: {
          lg: 6,
        },
      },
      {
        title: (
          <div className="flex align-center">
            <div>Effective Rate</div>
            <div className={styles.infoIconWrapper}>
              <Tooltip
                popoverClassName={treeStyles.tooltipPopover}
                content={
                  <>
                    [7 * 10 * Campaign Value] / [(WBPC1 * M1 Days * M1 Duration * M1 Screen Count)+
                    (WBPC2 + M2 Days* M2 duration* M2 Screen Count) …]
                  </>
                }
                className={classNames(
                  treeStyles.title,
                  treeStyles.mediaCardTitle,
                  treeStyles.tooltipContent,
                  treeStyles.infoIconTooltip
                )}
              >
                <InfoFilledIcon />
              </Tooltip>
            </div>
          </div>
        ),
        value: campaign?.effectiveRate
          ? (() => {
              const er = campaign.effectiveRate.toString().split(".");
              if (!er[1]) return campaign.effectiveRate;
              er[1] = er[1].slice(0, 3);
              return er.join(".");
            })()
          : "",
        size: {
          lg: 6,
        },
      },
    ].filter((col) => col.name !== "Created by" || isAdminApp)}
  />
);

const getRequestedChanges = (requestedChanges, header = "Requested Changes") => {
  if (!requestedChanges) {
    return null;
  }
  const [changes] = sortByEarliestDate(requestedChanges, "requestedAt", "MMM DD, YYYY HH:mm");
  return (
    <InfoBlock
      header={header}
      config={[
        {
          title: "Requested By",
          value: [
            changes.requestedBy.firstName,
            changes.requestedBy.lastName,
            changes.requestedBy.email ? `(${changes.requestedBy.email})` : null,
          ].join(" "),
          size: {
            lg: 6,
          },
        },
        {
          title: "Requested On",
          value: formatDateTime(changes.requestedAt),
          size: {
            lg: 6,
          },
        },
        {
          title: "Notes",
          value: changes.notes,
          size: {
            lg: 6,
          },
        },
      ]}
    />
  );
};

const getApprovalDetails = (campaign, header = "Approval Details") =>
  campaign.approvedBy && (
    <InfoBlock
      header={header}
      config={[
        {
          title: "Approved By",
          value: [
            campaign.approvedBy.firstName,
            campaign.approvedBy.lastName,
            campaign.approvedBy.email ? `(${campaign.approvedBy.email})` : null,
          ].join(" "),
          size: {
            lg: 6,
          },
        },
        {
          title: "Approved On",
          value: formatDateTime(campaign.approvedAt),
          size: {
            lg: 6,
          },
        },
        {
          title: "Notes",
          value: campaign.approvalNotes,
          size: {
            lg: 6,
          },
        },
      ]}
    />
  );

const getRejectionDetails = (campaign, header = "Rejection Details") =>
  campaign.rejectionReasonId && (
    <InfoBlock
      header={header}
      config={[
        {
          title: "Rejected By",
          value: [
            campaign.rejectedBy.firstName,
            campaign.rejectedBy.lastName,
            campaign.rejectedBy.email ? `(${campaign.rejectedBy.email})` : null,
          ].join(" "),
          size: {
            lg: 6,
          },
        },
        {
          title: "Rejected On",
          value: formatDateTime(campaign.rejectedBy),
          size: {
            lg: 6,
          },
        },
        {
          title: "Reason",
          value: campaign.rejectionReasonName,
          size: {
            lg: 6,
          },
        },
        {
          title: "Notes",
          value: campaign.rejectionNotes,
          size: {
            lg: 6,
          },
        },
      ]}
    />
  );

const renderCriteriaName = (data) => {
  const {
    TICKET_PRICE,
    SEATING_CAPACITY,
    AUDITORIUM_DIMENSIONS,
    SCREEN_BY_ID,
  } = THEATRE_CRITERIA_TYPES;

  if (data.targetGroupType === "theatre" && data) {
    if ([TICKET_PRICE, SEATING_CAPACITY].includes(data.type)) {
      return `${data.rangeFrom} to ${data.rangeTo}`;
    } else if (data.type === AUDITORIUM_DIMENSIONS) {
      const { rangeFrom: minLength, rangeTo: maxLength } = data.auditoriumLength;
      const { rangeFrom: minWidth, rangeTo: maxWidth } = data.auditoriumWidth;
      const { rangeFrom: minHeight, rangeTo: maxHeight } = data.auditoriumHeight;
      return `${minLength || 0} x ${minWidth || 0} x ${minHeight || 0} To ${maxLength || "max"} x ${
        maxWidth || "max"
      } x ${maxHeight || "max"}`;
    } else if (data.type === SCREEN_BY_ID && !data?.theatreName) {
      let theatreName = "";
      if (data.tagsInfos && data.tagsInfos.length > 0) {
        theatreName = data.tagsInfos[0]?.context?.theatre?.name || "";
      }

      return (
        <div>
          <div>
            {`${theatreName ? theatreName : ""} ${
              theatreName ? SPECIAL_CHARACTERS.MIDDLE_DOT : ""
            } ${data.displayName ? data.displayName : data.name} `}
          </div>
          <div className={styles.secondaryText}>
            {[
              data?.tagsInfos?.[0]?.context?.city?.name,
              data?.tagsInfos?.[0]?.context?.province?.name,
              data?.tagsInfos?.[0]?.context?.country?.name,
            ]
              .filter(Boolean)
              .join(", ")}
          </div>
        </div>
      );
    }
  }

  if (data.targetGroupType === "location" && data) {
    return data.locationInfo?.displayName
      ? data.locationInfo?.displayName
      : data.locationInfo?.name;
  } else {
    return (
      <div>
        <div>
          {`${data.theatreName ? data.theatreName : ""} ${
            data.theatreName ? SPECIAL_CHARACTERS.MIDDLE_DOT : ""
          } ${data.displayName ? data.displayName : data.name}`}
        </div>
        <div className={styles.secondaryText}>
          {[
            data?.tagsInfos?.[0]?.context?.city?.name,
            data?.tagsInfos?.[0]?.context?.province?.name,
            data?.tagsInfos?.[0]?.context?.country?.name,
          ]
            .filter(Boolean)
            .join(", ")}
        </div>
      </div>
    );
  }
};

//function used to format the date to "Month Day, Year"
const formatDate = (date) => {
  const d = new Date(date);
  const month = d.toLocaleString("default", { month: "short" });
  return `${month} ${d.getDate()}, ${d.getFullYear()}`;
};

export {
  getStatus,
  getStatusIconLegend,
  getContentAvailabilityStatus,
  getValidity,
  targetGroupIcons,
  getName,
  getContactDetails,
  getBillingInfo,
  getRequestedChanges,
  getApprovalDetails,
  getCampaignBasicDetails,
  getSummaryBlock,
  getScrollableSummaryBlock,
  getRejectionDetails,
  MediaPlacements,
  validateSelectedCampaignsDurations,
  isCampaignStatusChangesAvailable,
  getCurrentActionPeriod,
  validateCampaignsAction,
  renderCriteriaName,
  RemovePausedDurationsFromDurationGantt,
  convertDateRangeToSingleDaySegments,
  getStatusDisplay,
  formatDate,
};
