import { combineReducers } from "redux";
import app from "./app";
import userData from "./user";
import playlistPackTypes from "./playlistPackTypes";
import logs from "./logs";
import list from "./list";
import certifications from "./contentRatings";
import notificationTemplate from "./notificationTemplate";
import rights from "./rights";
import contentType from "./contentTypes";
import billingCycles from "./billingCycles";
import reasons from "./reasons";
import countries from "./countries";
import segmentTypes from "./segmentTypes";
import campaignTargetType from "./campaignTargetType";
import brands from "./brands";
import categories from "./categories";
import businessTypes from "./businessTypes";
import productIdentificationNumberType from "./productIdentificationNumberNumberType";
import rolesScopes from "./rolesScopes";
import brandVerifications from "./brandVerifications";
import theatres from "./theatres";
import screens from "./screens";
import companies from "./companies";
import movies from "./movies";
import playlistTemplates from "./playlistTemplates";
import cpls from "./cpls";
import reports from "./reports";
import campaigns from "./campaigns";
import targetGroups from "./targetGroups";
import media from "./media";
import content from "./content";
import timesOfDay from "./timesOfDay";
import campaignApprovals from "./campaignApprovals";
import campaignCreate from "./campaignCreate";
import advertiserTypes from "./advertiserTypes";
import compositions from "./compositions";
import schedules from "./schedules";
import showFailures from "./showFailures";
import showFailureSchedule from "./showFailureSchedule";
import showFailureSpotStatus from "./showFailureSpotStatus";
import campaignBias from "./campaignBias";

const rootReducer = combineReducers({
  app,
  userData,
  logs,
  list,
  reasons,
  playlistPackTypes,
  certifications,
  notificationTemplate,
  rights,
  contentType,
  billingCycles,
  countries,
  segmentTypes,
  campaignTargetType,
  brands,
  categories,
  businessTypes,
  productIdentificationNumberType,
  rolesScopes,
  brandVerifications,
  theatres,
  screens,
  companies,
  movies,
  playlistTemplates,
  cpls,
  reports,
  campaigns,
  targetGroups,
  media,
  content,
  timesOfDay,
  campaignApprovals,
  campaignCreate,
  advertiserTypes,
  compositions,
  schedules,
  showFailures,
  showFailureSchedule,
  showFailureSpotStatus,
  campaignBias,
});

export default rootReducer;
