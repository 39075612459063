/* 
This is the entry point of the Stale-web application 
1. Application will load the Login page for the first time user.
2. After signing in an access token gets stored in local storage, but if the user has
   already signed in validate function verifies the token in local storage and in case of any error 
   generates a eventId which will show the user unexpectedError page.
3. Until the tokenChecked flag is PageLoadingWithTable component is displayed.
4. Once tokenChecked is true User can see the UI depending in the role and scope assigned
   to the use by the Auth component.
5. Protected Route components handles the authentication and access for any given component.
6. withTracker is a higher order component that handles the google analytics for any given component.
*/
import { Intent } from "@blueprintjs/core";
import * as Sentry from "@sentry/browser";
import React, { Component } from "react";
import { FormattedMessage, IntlProvider } from "react-intl";
import { Provider } from "react-redux";
import { Redirect, Route, BrowserRouter as Router, Switch } from "react-router-dom";
import { validateToken } from "../api";
import { EmptyState } from "../assets";
import { DEFAULT_LANGUAGE, LOCAL_STORAGE_KEY, SCOPES, SCOPE_ACTIONS } from "../constants";
import combineMessages from "../localization/combineMessages";
import { loginUser, logoutUser } from "../store/actions";
import EmptyStateStyles from "../styles/EmptyState.module.scss";
import styles from "../styles/UnexpectedError.module.scss";
import AuthHandler from "./auth/AuthHandler";
import Login from "./auth/Login";
import Logout from "./auth/Logout";
import NotFound from "./auth/NotFound";
import Unauthorized from "./auth/Unauthorized";
import Layout from "./common/Layout";
import LocalizedButton from "./common/LocalizedButton";
import PageLoadingWithTable from "./common/PageLoadingWithTable";
import TableSample from "./pages/TableSample";
import BrandVerificationPage from "./pages/brandVerifications/BrandVerificationView";
import BrandVerifications from "./pages/brandVerifications/BrandVerificationsList";
import RejectedVerification from "./pages/brandVerifications/RejectedVerificationView";
import RejectedUpdateList from "./pages/brandVerifications/RejectedVerificationsList";
import ShowFailuresSchedules from "./pages/campaigns/Campaigns/ShowFailuresSchedules";
import BrandList from "./pages/catalogue/brands/BrandList";
import BrandPage from "./pages/catalogue/brands/BrandView";
import Dashboard from "./pages/dashboard/Dashboard";
import PlaylistTemplates from "./pages/inventory/Playlists/Playlists";
import AdminScreensList from "./pages/inventory/TheatresScreens/AdminScreensList";
import AdminTheatresList from "./pages/inventory/TheatresScreens/AdminTheatresList";
import ScreenView from "./pages/inventory/TheatresScreens/Screen";
import TheatreView from "./pages/inventory/TheatresScreens/Theatre";
import UserScreensList from "./pages/inventory/TheatresScreens/UserScreensList";
import UserTheatresList from "./pages/inventory/TheatresScreens/UserTheatresList";
import BillingCycles from "./pages/lists/BillingCycles";
import BusinessTypes from "./pages/lists/BusinessTypes";
import CampaignTargetTypes from "./pages/lists/CampaignTargetType/CampaignTargetTypes";
import CertificationsPage from "./pages/lists/ContentRatings";
import ContentTypes from "./pages/lists/ContentTypes/ContentTypes";
import CountriesAndTaxes from "./pages/lists/CountriesAndTaxes/CountriesAndTaxesList";
import ManageCountry from "./pages/lists/CountriesAndTaxes/Country";
import ListPage from "./pages/lists/ListsOfList";
import NotificationTemplates from "./pages/lists/NotificationTemplates/NotificationList";
import PlaylistPackTypes from "./pages/lists/PlaylistPackTypes";
import Categories from "./pages/lists/ProductCategories/Categories";
import ProductIdentificationNumberType from "./pages/lists/ProductIdentificationNumberTypes/ProductIdentificationNumberTypeList";
import ReasonsList from "./pages/lists/Reasons/ReasonsList";
import ListRights from "./pages/lists/Rights";
import RolesScopes from "./pages/lists/RolesScopes";
import SegmentTypes from "./pages/lists/SegmentTypes/SegmentTypesList";
import TimesOfDayList from "./pages/lists/TimesOfDay/TimesOfDayList";
import LogsPage from "./pages/logs/AuditLogsList";
import GeneratedReports from "./pages/reports/GeneratedReports";
import Reports from "./pages/reports/Reports";

import ProtectedRoute from "./auth/ProtectedRoute";
import withTracker from "./hoc/withTracker";
import AdvertisersPage from "./pages/companies/AdvertiserView";
import Advertisers from "./pages/companies/Advertisers";
import Companies from "./pages/companies/Companies";
import Exhibitor from "./pages/companies/Exhibitor";
import MediaAgency from "./pages/companies/MediaAgency";
import CPLs from "./pages/content/CPLs/CPLs";
import ArchivedContentList from "./pages/content/Content/ArchivedContentList";
import ContentDetails from "./pages/content/Content/ContentDetails";
import AdvertiserTypes from "./pages/lists/AdvertiserTypes/AdvertiserTypesList";
//import Campaigns from "./pages/campaigns/Campaigns/CampaignsList";
import CampaignApprovalView from "./pages/campaigns/CampaignApprovals/CampaignApproval";
import CampaignApprovalsList from "./pages/campaigns/CampaignApprovals/CampaignApprovals";
import RejectedProposalsList from "./pages/campaigns/CampaignApprovals/RejectedProposals";
import CampaignCreate from "./pages/campaigns/CampaignCreate/Campaign";
import CampaignReview from "./pages/campaigns/CampaignCreate/CampaignReview";
import MediaCreate from "./pages/campaigns/CampaignCreate/Media";
import CampaignProposal from "./pages/campaigns/CampaignCreate/Proposal";
import TargetGroupCreate from "./pages/campaigns/CampaignCreate/TargetGroup";
import CampaignView from "./pages/campaigns/Campaigns/Campaign";
import CampaignReports from "./pages/campaigns/Campaigns/CampaignReports";
import CampaignsLandingPage from "./pages/campaigns/Campaigns/CampaignsLandingPage";
import ShowFailures from "./pages/campaigns/Campaigns/ShowFailures";
import CampaignsRateBias from "./pages/campaigns/Campaigns/CampaignsRateBias";
import ShowFailuresSpotStatus from "./pages/campaigns/Campaigns/ShowFailuresSpotStatus";
import MediaView from "./pages/campaigns/Media/Media";
import TargetGroupView from "./pages/campaigns/TargetGroups/TargetGroup";
import CompositionsList from "./pages/compositions/CompositionsList";
import CreateSchedules from "./pages/schedules/CreateSchedules";
import ScheduleList from "./pages/schedules/SchedulesList";
import Settings from "./pages/settings/Settings";
import WixRedirectHandle from "./pages/wixRedirect/wixRedirect";

class Root extends Component {
  constructor(props) {
    super(props);
    try {
      const currentLanguage = localStorage.getItem("CURRENT_LANGUAGE") || DEFAULT_LANGUAGE;
      this.state = {
        eventId: null,
        tokenChecked: false,
        lang: currentLanguage,
      };
      // For Toasts, which are in a separate React tree and so there's no shared state or context
      window.CURRENT_LANGUAGE = currentLanguage;
    } catch {
      // eslint-disable-next-line no-console
      console.log("Local Storage Error");
    }
  }

  validate = (token) => {
    validateToken(token)
      .then((res) => {
        this.props.store.dispatch(loginUser({ loggedIn: true, token, ...res.data }));
        this.setState({ tokenChecked: true });
      })
      .catch(this.invalidate);
  };

  invalidate = (err) => {
    this.props.store.dispatch(logoutUser(err));
    this.setState({ tokenChecked: true });
  };

  componentDidMount() {
    try {
      const token = localStorage.getItem(LOCAL_STORAGE_KEY.TOKEN);
      if (token) this.validate(token);
      else this.invalidate();
    } catch {
      // eslint-disable-next-line no-console
      console.log("Local Storage Error");
    }
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope((scope) => {
      scope.setExtras(errorInfo);
      const eventId = Sentry.captureException(error);
      this.setState({ eventId });
    });
  }

  changeLang = (lang) => {
    this.setState({ lang });
    // For Toasts, which are in a separate React tree and so there's no shared state or context
    window.CURRENT_LANGUAGE = lang;
    try {
      localStorage.setItem("CURRENT_LANGUAGE", lang);
    } catch {
      // eslint-disable-next-line no-console
      console.log("Local Storage Error");
    }
  };

  render() {
    const { eventId, tokenChecked, lang } = this.state;
    const { store } = this.props;

    if (eventId) {
      return (
        <div className={styles.container}>
          <IntlProvider locale={lang} messages={combineMessages(lang)}>
            <div className={EmptyStateStyles.imageAlign}>
              <EmptyState />
            </div>
            <div className={styles.body}>
              <FormattedMessage id={"EmptyState.unexpectedError"} />
            </div>
            <div className={styles.buttonAlign}>
              <LocalizedButton
                className={styles.reloadButton}
                onClick={() => window.location.reload()}
                text="Button.reloadPage"
              />
              <LocalizedButton
                intent={Intent.PRIMARY}
                className={styles.reportButton}
                onClick={() => Sentry.showReportDialog({ eventId: this.state.eventId })}
                text="Button.reportFeedback"
              />
            </div>
          </IntlProvider>
        </div>
      );
    }

    if (!tokenChecked) return <PageLoadingWithTable />;
    else
      return (
        <Provider store={store}>
          <IntlProvider locale={lang} messages={combineMessages(lang)}>
            <Router>
              <Switch>
                <Route exact path="/" render={() => <Redirect to="/auth" />} />
                <Route exact path="/table" component={withTracker(TableSample)} />
                <Route exact path="/login" component={withTracker(Login)} />
                <Route exact path="/redirect" component={withTracker(WixRedirectHandle)} />
                <Route exact path="/logout" component={withTracker(Logout)} />
                <Route exact path="/auth" component={withTracker(AuthHandler)} />
                <Layout>
                  <Switch>
                    <ProtectedRoute
                      exact
                      path="/logs/:route/:ref"
                      scope={SCOPES.LOGS}
                      component={withTracker(LogsPage)}
                    />
                    <ProtectedRoute
                      exact
                      path="/lists"
                      scope={SCOPES.LISTS}
                      component={withTracker(ListPage)}
                    />
                    <ProtectedRoute
                      exact
                      path="/dashboard"
                      scope={SCOPES.__SELF__}
                      component={withTracker(Dashboard)}
                    />
                    <ProtectedRoute
                      exact
                      path="/settings/:tabId?"
                      scope={SCOPES.COMPANIES_PREFERENCES}
                      component={withTracker(Settings)}
                    />
                    <ProtectedRoute
                      exact
                      path="/unauthorized"
                      scope={SCOPES.__SELF__}
                      component={withTracker(Unauthorized)}
                    />
                    <ProtectedRoute
                      exact
                      path="/lists/content-ratings"
                      scope={SCOPES.CERTIFICATIONS}
                      component={withTracker(CertificationsPage)}
                    />
                    <ProtectedRoute
                      exact
                      path="/lists/playlist-pack-types"
                      scope={SCOPES.PLAYLIST_PACK_TYPES}
                      component={withTracker(PlaylistPackTypes)}
                    />
                    <ProtectedRoute
                      exact
                      path="/lists/notification-templates/:action?"
                      scope={SCOPES.NOTIFICATION_TEMPLATES}
                      paramScopeAction={SCOPE_ACTIONS.WRITE}
                      component={withTracker(NotificationTemplates)}
                    />
                    <ProtectedRoute
                      exact
                      path="/lists/reasons/:action?"
                      scope={SCOPES.REASONS}
                      paramScopeAction={SCOPE_ACTIONS.WRITE}
                      component={withTracker(ReasonsList)}
                    />
                    <ProtectedRoute
                      exact
                      path="/lists/segment-types/:action?"
                      scope={SCOPES.SEGMENT_TYPES}
                      paramScopeAction={SCOPE_ACTIONS.WRITE}
                      component={withTracker(SegmentTypes)}
                    />
                    <ProtectedRoute
                      exact
                      path="/lists/rights"
                      scope={SCOPES.RIGHTS}
                      component={withTracker(ListRights)}
                    />
                    <ProtectedRoute
                      exact
                      path="/lists/content-types/:action?"
                      scope={SCOPES.CONTENT_TYPES}
                      paramScopeAction={SCOPE_ACTIONS.WRITE}
                      component={withTracker(ContentTypes)}
                    />
                    <ProtectedRoute
                      exact
                      path="/lists/product-categories/:action?"
                      scope={SCOPES.CATEGORIES}
                      paramScopeAction={SCOPE_ACTIONS.WRITE}
                      component={withTracker(Categories)}
                    />
                    <ProtectedRoute
                      exact
                      path="/lists/brand-types"
                      scope={SCOPES.BRANDS}
                      component={withTracker(BusinessTypes)}
                    />
                    <ProtectedRoute
                      exact
                      path="/lists/product-identification-number-types/:action?"
                      scope={SCOPES.PRODUCT_IDENTIFICATION_NUMBER_TYPES}
                      paramScopeAction={SCOPE_ACTIONS.WRITE}
                      component={withTracker(ProductIdentificationNumberType)}
                    />
                    <ProtectedRoute
                      exact
                      path="/lists/countries"
                      scope={SCOPES.COUNTRIES}
                      component={withTracker(CountriesAndTaxes)}
                    />
                    <ProtectedRoute
                      exact
                      path="/lists/countries/:countryId/:tabId?/:action?"
                      scope={SCOPES.COUNTRIES}
                      component={withTracker(ManageCountry)}
                    />
                    <ProtectedRoute
                      exact
                      path="/lists/roles-scopes"
                      scope={SCOPES.SCOPES}
                      component={withTracker(RolesScopes)}
                    />
                    <ProtectedRoute
                      exact
                      path="/lists/campaign-target-types/:action?"
                      scope={SCOPES.CAMPAIGN_TARGET_TYPE}
                      paramScopeAction={SCOPE_ACTIONS.WRITE}
                      component={withTracker(CampaignTargetTypes)}
                    />
                    <ProtectedRoute
                      exact
                      path="/lists/times-of-day/:action?"
                      scope={SCOPES.TIMES_OF_DAY}
                      paramScopeAction={SCOPE_ACTIONS.WRITE}
                      component={withTracker(TimesOfDayList)}
                    />
                    <ProtectedRoute
                      exact
                      path="/brands/:tabId?/:action?"
                      scope={SCOPES.BRANDS}
                      paramScopeAction={{ action: SCOPE_ACTIONS.WRITE }}
                      component={withTracker(BrandList)}
                    />
                    <ProtectedRoute
                      exact
                      path="/brands/:tabId/:id/:viewtabId?/:action?"
                      scope={SCOPES.BRANDS}
                      paramScopeAction={{ action: SCOPE_ACTIONS.WRITE }}
                      component={withTracker(BrandPage)}
                    />
                    <ProtectedRoute
                      exact
                      path="/theatres"
                      scope={SCOPES.INVENTORY}
                      adminComponent={withTracker(AdminTheatresList)}
                      userComponent={withTracker(UserTheatresList)}
                    />
                    <ProtectedRoute
                      exact
                      path="/theatres/:theatreId/:tabId?/:action?"
                      scope={SCOPES.INVENTORY}
                      component={withTracker(TheatreView)}
                    />
                    <ProtectedRoute
                      exact
                      path="/screens"
                      scope={SCOPES.INVENTORY}
                      adminComponent={withTracker(AdminScreensList)}
                      userComponent={withTracker(UserScreensList)}
                    />
                    <ProtectedRoute
                      exact
                      path="/screens/:screenId/:tabId?/:childTabId?/:action?"
                      scope={SCOPES.INVENTORY}
                      component={withTracker(ScreenView)}
                    />
                    <ProtectedRoute
                      exact
                      path="/playlist-templates"
                      scope={SCOPES.PLAYLIST_TEMPLATES}
                      component={withTracker(PlaylistTemplates)}
                    />
                    <ProtectedRoute
                      exact
                      path="/brand-verifications"
                      scope={SCOPES.BRAND_VERIFICATIONS}
                      component={withTracker(BrandVerifications)}
                    />
                    <ProtectedRoute
                      exact
                      path="/compositions"
                      scope={SCOPES.COMPOSITIONS}
                      component={withTracker(CompositionsList)}
                    />
                    <ProtectedRoute
                      exact
                      path="/brand-verifications/rejected"
                      scope={SCOPES.BRAND_VERIFICATIONS}
                      component={withTracker(RejectedUpdateList)}
                    />
                    <ProtectedRoute
                      exact
                      path="/brand-verifications/rejected/:brandType/:draftId"
                      scope={SCOPES.BRAND_VERIFICATIONS}
                      component={withTracker(RejectedVerification)}
                    />
                    <ProtectedRoute
                      exact
                      path="/brand-verifications/:parentBrandId/:companyId"
                      scope={SCOPES.BRAND_VERIFICATIONS}
                      scopeAction={SCOPE_ACTIONS.WRITE}
                      component={withTracker(BrandVerificationPage)}
                    />
                    <ProtectedRoute
                      exact
                      path="/brand-merge/:mergeId"
                      scope={SCOPES.BRAND_VERIFICATIONS}
                      scopeAction={SCOPE_ACTIONS.WRITE}
                      component={withTracker(BrandVerificationPage)}
                    />
                    <ProtectedRoute
                      exact
                      path="/lists/billing-cycles"
                      scope={SCOPES.BILLING_CYCLES}
                      component={withTracker(BillingCycles)}
                    />
                    {/* <ProtectedRoute
                      exact
                      path="/movies"
                      scope={SCOPES.MOVIES}
                      component={withTracker(MoviesListWithFilters)}
                    />
                    <ProtectedRoute
                      exact
                      path="/movies/:movieId/:tabId?"
                      scope={SCOPES.MOVIES}
                      component={withTracker(MovieView)}
                    /> */}
                    <ProtectedRoute
                      exact
                      path="/companies/exhibitors/:companyId/:tabId?"
                      scope={SCOPES.COMPANIES_MANAGE}
                      component={withTracker(Exhibitor)}
                    />
                    <ProtectedRoute
                      exact
                      path="/companies/media_agencies/:companyId/:tabId?"
                      scope={SCOPES.COMPANIES_MANAGE}
                      component={withTracker(MediaAgency)}
                    />
                    <ProtectedRoute
                      exact
                      path="/companies/clients/:action?"
                      scope={SCOPES.BUYERS}
                      paramScopeAction={SCOPE_ACTIONS.WRITE}
                      component={withTracker(Advertisers)}
                    />
                    <ProtectedRoute
                      exact
                      path="/lists/client-types/:action?"
                      scope={SCOPES.ADVERTISER_TYPES}
                      paramScopeAction={SCOPE_ACTIONS.WRITE}
                      component={withTracker(AdvertiserTypes)}
                    />
                    <ProtectedRoute
                      exact
                      path="/companies/:tabId?/:action?"
                      scope={SCOPES.COMPANIES_MANAGE}
                      component={withTracker(Companies)}
                    />
                    <ProtectedRoute
                      exact
                      path="/companies/advertisers/:advertiserId/:advertiserTabId?/:brandTabId?/:action?"
                      scope={SCOPES.BUYERS}
                      paramScopeAction={SCOPE_ACTIONS.READ}
                      component={withTracker(AdvertisersPage)}
                    />
                    <ProtectedRoute
                      exact
                      path="/cpl_mappings/:status?/:cplId?/:action?"
                      scope={SCOPES.CPL_MAPPINGS}
                      component={withTracker(CPLs)}
                    />
                    <ProtectedRoute
                      exact
                      path="/content/archived/:id?"
                      scope={SCOPES.CONTENT}
                      component={withTracker(ArchivedContentList)}
                    />
                    {/* <ProtectedRoute
                      exact
                      path="/content/:id?"
                      scope={SCOPES.CONTENT}
                      component={withTracker(ContentList)}
                    /> */}
                    <ProtectedRoute
                      exact
                      path="/content/:id/:tabid/:action?"
                      scope={SCOPES.CONTENT}
                      component={withTracker(ContentDetails)}
                    />
                    <ProtectedRoute
                      exact
                      path="/reports"
                      scope={SCOPES.REPORTS}
                      scopeAction={SCOPE_ACTIONS.WRITE}
                      component={withTracker(Reports)}
                    />
                    <ProtectedRoute
                      exact
                      path="/reports/generated/:tab?"
                      scope={SCOPES.REPORTS}
                      component={withTracker(GeneratedReports)}
                    />
                    <ProtectedRoute
                      exact
                      path="/campaign-create/:campaignId?/(details)?"
                      scope={SCOPES.CAMPAIGNS}
                      scopeAction={SCOPE_ACTIONS.WRITE}
                      component={withTracker(CampaignCreate)}
                    />
                    <ProtectedRoute
                      exact
                      path="/campaign-create/:campaignId/target-groups/:action?"
                      scope={SCOPES.CAMPAIGNS}
                      scopeAction={SCOPE_ACTIONS.WRITE}
                      component={withTracker(TargetGroupCreate)}
                    />
                    <ProtectedRoute
                      exact
                      path="/campaign-create/:campaignId/media/:action?"
                      scope={SCOPES.CAMPAIGNS}
                      scopeAction={SCOPE_ACTIONS.WRITE}
                      component={withTracker(MediaCreate)}
                    />
                    <ProtectedRoute
                      exact
                      path="/campaign-create/:campaignId/(review)?"
                      scope={SCOPES.CAMPAIGNS}
                      scopeAction={SCOPE_ACTIONS.WRITE}
                      component={withTracker(CampaignReview)}
                    />
                    <ProtectedRoute
                      exact
                      path="/campaign-create/:campaignId/(finish)?"
                      scope={SCOPES.CAMPAIGNS}
                      scopeAction={SCOPE_ACTIONS.WRITE}
                      component={withTracker(CampaignProposal)}
                    />
                    <ProtectedRoute
                      exact
                      path="/campaigns/rejected-proposals"
                      scope={SCOPES.CAMPAIGNS}
                      scopeAction={SCOPE_ACTIONS.APPROVE}
                      component={withTracker(RejectedProposalsList)}
                    />
                    <ProtectedRoute
                      exact
                      path="/campaigns/:action?"
                      //path="/campaigns/:tabId?/:action?"
                      scope={SCOPES.CAMPAIGNS}
                      //SLATE-1369 Nov10-2023 Anushree:- CampaignLandingPage contains the tab to Campaigns
                      //component={withTracker(Campaigns)}
                      component={withTracker(CampaignsLandingPage)}
                    />
                    <ProtectedRoute
                      exact
                      path="/campaigns/campaigns/:campaignId/:tabId?/:action?"
                      scope={SCOPES.CAMPAIGNS}
                      component={withTracker(CampaignView)}
                    />
                    <ProtectedRoute
                      exact
                      path="/campaigns/target-groups/:targetGroupId/:tabId?/:action?"
                      scope={SCOPES.TARGET_GROUPS}
                      component={withTracker(TargetGroupView)}
                    />
                    <ProtectedRoute
                      exact
                      path="/campaigns/media/:mediaId/:tabId?/:action?"
                      scope={SCOPES.MEDIA}
                      component={withTracker(MediaView)}
                    />
                    <ProtectedRoute
                      exact
                      path="/campaign-approvals"
                      scope={SCOPES.CAMPAIGNS}
                      scopeAction={SCOPE_ACTIONS.APPROVE}
                      component={withTracker(CampaignApprovalsList)}
                    />
                    <ProtectedRoute
                      exact
                      path="/campaign-approvals/:campaignId/:tabId/:action?"
                      scope={SCOPES.CAMPAIGNS}
                      scopeAction={SCOPE_ACTIONS.APPROVE}
                      component={withTracker(CampaignApprovalView)}
                    />
                    <ProtectedRoute
                      exact
                      path="/campaign-reports"
                      scope={SCOPES.CAMPAIGNS}
                      // scopeAction={SCOPE_ACTIONS.APPROVE}
                      component={withTracker(CampaignReports)}
                    />
                    <ProtectedRoute
                      exact
                      path="/campaign-bias"
                      scope={SCOPES.CAMPAIGNS}
                      // scopeAction={SCOPE_ACTIONS.APPROVE}
                      component={withTracker(CampaignsRateBias)}
                    />
                    <ProtectedRoute
                      exact
                      path="/show-failures"
                      scope={SCOPES.CAMPAIGNS}
                      // scopeAction={SCOPE_ACTIONS.APPROVE}
                      component={withTracker(ShowFailures)}
                    />
                    <ProtectedRoute
                      exact
                      path="/show-failures/:id/schedules"
                      scope={SCOPES.CAMPAIGNS}
                      // scopeAction={SCOPE_ACTIONS.APPROVE}
                      component={withTracker(ShowFailuresSchedules)}
                    />
                    <ProtectedRoute
                      exact
                      path="/show-failures/:id/spotstatus"
                      scope={SCOPES.CAMPAIGNS}
                      // scopeAction={SCOPE_ACTIONS.APPROVE}
                      component={withTracker(ShowFailuresSpotStatus)}
                    />
                    <ProtectedRoute
                      exact
                      path="/schedules/:scheduleId?/:action"
                      scope={SCOPES.SCHEDULES}
                      scopeAction={SCOPE_ACTIONS.WRITE}
                      component={withTracker(CreateSchedules)}
                    />
                    <ProtectedRoute
                      exact
                      path="/schedules"
                      // scope={SCOPES.SCHEDULES}
                      component={withTracker(ScheduleList)}
                    />
                    <ProtectedRoute scope={SCOPES.__SELF__} component={withTracker(NotFound)} />
                  </Switch>
                </Layout>
              </Switch>
            </Router>
          </IntlProvider>
        </Provider>
      );
  }
}

export default Root;
