import formatDateTime, { combineDateTimeToISO } from "./formatDateTime";

const getDuration = (duration, format = "MMM DD, YYYY HH:mm") =>
  duration
    ? `${formatDateTime(
        combineDateTimeToISO(duration.fromDate, duration.startTime),
        format
      )} - ${formatDateTime(combineDateTimeToISO(duration.toDate, duration.endTime), format)}`
    : "";

export default getDuration;
