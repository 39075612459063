import React, { Component } from "react";
import ClassNames from "classnames";
import { Tooltip, Position } from "@blueprintjs/core";
import { isEmpty, isNumber } from "lodash";

import { Icons } from "prefab";
import { formatValidityPeriod } from "../../../../../utils";
import styles from "../../../../../styles/Campaigns/CampaignTree.module.scss";
import StatusIcon from "../../../../common/StatusIcon";
import { CAMPAIGN_STATUS_LIST } from "../../../../../constants";

const { ChevronUpIcon, ChevronDownIcon } = Icons;
const defaultMediaInfo = [
  {
    id: "duration",
    label: "Duration",
    value: "Enter Duration",
  },
  {
    id: "validity",
    label: "Validity",
    value: "Select Media Validity",
  },
  {
    id: "totalEstimatedPlays",
    label: "Estd. Plays",
    value: "Enter Estimated Plays",
  },
  {
    id: "actualPlays",
    label: "Actual Plays",
    value: "-",
  },
  {
    id: "contentId",
    label: "Content",
    value: "Select Content",
  },
];

export default class MediaCard extends Component {
  state = {
    isExpanded: this.props.isExpanded,
  };

  handleCardToggle = (isExpanded) => {
    this.setState({
      isExpanded: isExpanded,
    });
  };

  formatMediaValues = (defaultValue, mediaData) => {
    const value = mediaData && mediaData[defaultValue.id];

    if (defaultValue.id === "contentId" && isEmpty(value)) {
      return <span style={{ color: "red" }}>Unavailable</span>;
    }

    if (isEmpty(value) && !isNumber(value)) return defaultValue.value;

    if (defaultValue.id === "validity") {
      return formatValidityPeriod(value, "DD MMM YYYY");
    }

    return value;
  };

  render() {
    const { title, showCollapse, isExpanded, mediaData } = this.props;
    const status = mediaData?.status;
    const contentId = mediaData && mediaData.contentId;

    // This is a list of status info to be displayed in the media card
    const statusInfo = CAMPAIGN_STATUS_LIST.map((status) => ({
      name: status.displayName,
      id: status.id,
      type: status.type,
      color: status.color,
    }));

    return (
      <div className={ClassNames(styles.cardContainer, styles.mediaContainer)}>
        <div className={styles.header}>
          <div className={styles.contentWrapper}>
            <div className={styles.titleContent}>
              {/* 
                - The status icon is displayed based on the status of the media
              */}
              {statusInfo
                .filter((info) => info.id === status)
                .map((status, index) => {
                  return (
                    <div key={index}>
                      <Tooltip
                        className={styles.toolTip}
                        boundary={true}
                        position={Position.AUTO}
                        content={status.name}
                        usePortal={true}
                        popoverClassName={styles.tooltipPopover}
                      >
                        <StatusIcon color={status.color} type={status.type} />
                      </Tooltip>
                    </div>
                  );
                })}

              <Tooltip
                popoverClassName={styles.tooltipPopover}
                boundary={true}
                position={Position.AUTO}
                content={title}
                usePortal={true}
              >
                <div
                  className={ClassNames(styles.title, styles.mediaCardTitle, styles.tooltipContent)}
                >
                  {title}
                </div>
              </Tooltip>
            </div>
          </div>
          {showCollapse && (
            <div className={styles.actions}>
              {isExpanded ? (
                <span onClick={() => this.handleCardToggle(false)}>
                  <ChevronUpIcon />
                </span>
              ) : (
                <span onClick={() => this.handleCardToggle(true)}>
                  <ChevronDownIcon />
                </span>
              )}
            </div>
          )}
        </div>
        <div className={styles.cardItemWrapper}>
          {defaultMediaInfo.map((ItemDefaultData, index) => {
            if (
              ItemDefaultData &&
              ItemDefaultData.id &&
              ItemDefaultData.id === "contentId" &&
              contentId
            ) {
              return null; // Skip rendering "Content" field if content is available
            }
            return (
              <div key={index} className={styles.itemContainer}>
                <div className={styles.labelContainer}>
                  <div className={styles.infoIcon}>{ItemDefaultData.icon}</div>
                  <div className={styles.label}>{ItemDefaultData.label}</div>
                </div>
                <div className={styles.infoValue}>
                  {this.formatMediaValues(ItemDefaultData, mediaData)}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}
