import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import queryString from "query-string";
import { Table } from "workbench";
import classNames from "classnames";
import PageLoadingWithTable from "../../../../common/PageLoadingWithTable";
import { getTagByID } from "../../../../../api";
import {
  bindDispatch,
  modifyTableColumns,
  getBusinessType,
  getCategoryNames,
  checkScopes,
  lastUpdatedByColumn,
  lastUpdatedAtColumn,
  getBrandType,
  createdByColumn,
  createdAtColumn,
  getColumnsByViewport,
} from "../../../../../utils";
import * as constants from "../../../../../constants";
import { GET_BRAND_ACTIONS } from "../brandConstants";
import FilterChips from "../../../../common/Filters/FilterChips";
import WithFetchList from "../../../../hoc/withFetchList";
import TableControls from "../../../../common/TableControls";
import TableLoading from "../../../../common/TableLoading";
import FooterControls from "../../../../common/FooterControls";
import ActionIcon from "../../../../common/ActionIcon";
import {
  ActiveTableCell,
  ActiveTableCellColumnFromArray,
} from "../../../../common/ActiveTableCell";
import pageStyles from "../../../../../styles/App.module.scss";
import { BRAND_LIST_COMMON_TAG_TYPES } from "../brandConstants";
import EditBrand from "../EditBrand";

const DEFAULT_COLUMNS = [
  "Variant",
  "Product",
  "Individual Brand",
  "Parent Brand",
  "Categories",
  "Clients",
];
const MOBILE_COLUMNS = ["Variant", "Product", "Individual Brand", "Parent Brand"];
class VariantList extends Component {
  state = {
    selectedColumns: getColumnsByViewport(
      this.props.app.pageDimensions,
      MOBILE_COLUMNS,
      DEFAULT_COLUMNS
    ),
  };

  componentDidMount = () => {
    const {
      actions,
      history,
      match: { params },
    } = this.props;
    const searchParams = queryString.parse(history.location.search);
    if (params.action) {
      if (params.action === "new") {
        actions.addBrand(params);
      } else if (params.action && params.viewtabId) {
        actions[GET_BRAND_ACTIONS[params.tabId]](params, false);
      }
    } else if (searchParams.editBrandId) {
      this.redirectToBranddetailsPage(searchParams);
    }
    this.props.fetchData(null, true, [{ parentTagId: this.props.parentTagId }]);
    this.props.actions.getBusinessTypesList();
    this.props.actions.getProductIdentificationNumberTypeList();
  };

  redirectToBranddetailsPage = async (searchParams) => {
    const {
      actions,
      history,
      match: { params },
    } = this.props;
    const { data } = await getTagByID(searchParams.editBrandId);
    const brandType = getBrandType(searchParams.editBrandId, data);
    if (brandType) {
      history.replace(`/brands/${brandType}/${searchParams.editBrandId}/edit`);
      actions[GET_BRAND_ACTIONS[brandType]](params, true);
    }
  };

  onColumnSelect = (selectedColumns, reorderedColumns) =>
    this.setState({ selectedColumns, reorderedColumns });

  editVariant = (prop) => {
    const { actions, editBrand } = this.props;
    actions.editBrand(prop);
    editBrand(prop.id);
  };

  renderAdvertisers = (advertisers) => {
    if (!advertisers || advertisers.length === 0) return "-";
    return advertisers.map((ad, i) => <div key={i}>{ad}</div>);
  };
  render = () => {
    const {
      match: { params },
      history,
      brands: { isLoading, variantList, isPartialLoading, editBrand },
      businessTypes: { list },
      filters,
      ps,
      page,
      sort,
      onQueryChange,
      isFilterLoading,
      userData,
      addNewField,
      isViewOnly,
      hiddenColumn,
      title,
      isEdit,
      onSortedChange,
      hiddenTagTypes = [],
    } = this.props;
    const { selectedColumns, reorderedColumns } = this.state;
    const query = queryString.parse(history.location.search);
    const addButtonProps = {
      addButton: true,
      buttonLabel: "Button.addVariant",
      addButtonScope: constants.SCOPES.BRANDS,
      addButtonScopeAction: constants.SCOPE_ACTIONS.WRITE,
      addNewField: addNewField,
      userData,
    };
    const addActionButton = isViewOnly || isEdit ? {} : addButtonProps;

    if (isLoading && !variantList.data)
      return (
        <PageLoadingWithTable
          showBreadcrumb={false}
          tableRowsCount={constants.DEFAULT_LOADING_ROW_COUNT}
        />
      );
    const activeTableCell = (id, name) =>
      ActiveTableCell(id, name, () => this.props.onFilterIdSelect(id));

    const columns = [
      {
        id: "name",
        Header: "Variant",
        accessor: "name",
      },
      {
        id: "productName",
        Header: "Product",
        accessor: (d) => activeTableCell(d.productId, d.productName),
      },
      {
        id: "individualBrandName",
        Header: "Individual Brand",
        accessor: (d) => activeTableCell(d.individualBrandId, d.individualBrandName),
      },
      {
        id: "parentBrandName",
        Header: "Parent Brand",
        accessor: (d) => activeTableCell(d.parentBrandId, d.parentBrandName),
      },

      {
        id: "brandType",
        Header: "Brand Type",
        accessor: (d) => activeTableCell(d.businessTypeTag, getBusinessType(list, d.businessType)),
      },
      {
        id: "categories",
        Header: "Categories",
        sortable: false,
        accessor: (d) => getCategoryNames(d.categories, (id) => this.props.onFilterIdSelect(id)),
      },
      {
        id: "client",
        Header: "Clients",
        sortable: false,
        accessor: (d) => ActiveTableCellColumnFromArray(d.advertisers, this.props.onFilterIdSelect),
      },
      lastUpdatedByColumn(this.props.onFilterIdSelect),
      lastUpdatedAtColumn(),
      createdByColumn(this.props.onFilterIdSelect),
      createdAtColumn(),
      {
        ...constants.DEFAULT_ACTION_ICON_COLUMN,
        width: isViewOnly ? 64 : 96,
        accessor: (props) => (
          <ActionIcon
            iconProps={checkScopes(
              [
                {
                  toolTip: "Tooltip.view",
                  url: `/brands/variants/${props.id}/details`,
                  iconName: "ViewIcon",
                },
                {
                  id: "edit",
                  scope: constants.SCOPES.BRANDS,
                  scopeAction: constants.SCOPE_ACTIONS.WRITE,
                  toolTip: "Tooltip.update",
                  onClick: () => this.editVariant(props),
                  iconName: "EditIcon",
                },
              ].filter((icon) => (isViewOnly ? icon.id !== "edit" : icon)),
              userData
            )}
          />
        ),
      },
    ].filter((column) => (hiddenColumn ? column.id !== hiddenColumn : column));

    const tagTypes = [
      constants.TAG_TYPE.PARENT_BRAND,
      constants.TAG_TYPE.INDIVIDUAL_BRAND,
      constants.TAG_TYPE.PRODUCT,
      constants.TAG_TYPE.VARIANT,
      ...BRAND_LIST_COMMON_TAG_TYPES,
    ];
    return (
      <div className={classNames("col-12 clearfix", pageStyles.pageContainer)}>
        <TableControls
          searchBar
          columnFilter
          showRowSize
          pagination
          columns={columns}
          selectedColumns={selectedColumns}
          reorderedColumns={reorderedColumns}
          data={variantList}
          query={query}
          ps={ps}
          page={page}
          tagTypes={tagTypes.filter((tag) => !hiddenTagTypes.includes(tag))}
          onSearchFilterSelect={this.props.onFilterSelect}
          onFilterChange={this.props.onFilterChange}
          onColumnFilterSelect={this.onColumnSelect}
          onRowSizeChange={onQueryChange}
          filters={filters}
        />
        <FilterChips
          selected={filters}
          showResultsCount
          resultsCount={variantList.totalCount}
          onFilterChange={this.props.onFilterChange}
          loading={isLoading}
        />
        <Table
          data={variantList.data || []}
          loading={isPartialLoading || isFilterLoading}
          sorted={sort}
          onSortedChange={onSortedChange}
          defaultPageSize={constants.DEFAULT_PAGE_SIZE}
          columns={modifyTableColumns(columns, selectedColumns, reorderedColumns)}
          LoadingComponent={
            <TableLoading
              columns={modifyTableColumns(columns, selectedColumns, reorderedColumns)}
            />
          }
          onRowClick={({ original }) => history.push(`/brands/variants/${original.id}/details`)}
        />
        <FooterControls
          pagination
          data={variantList}
          query={query}
          ps={ps}
          page={page}
          onRowSizeChange={this.onQueryChange}
          {...addActionButton}
        />
        {!isViewOnly && (
          <EditBrand
            title={title ?? params?.tabId}
            brand={editBrand.brand}
            fetchDataList={() => {
              // Reset filter list and then trigger data refresh
              this.props.onFilterChange([]);
            }}
            {...this.props}
          />
        )}
      </div>
    );
  };
}
export const Variants = WithFetchList("getVariantList", {
  sort: [{ id: "name" }],
})(VariantList);

const mapStateToProps = createSelector(
  (state) => state.brands,
  (state) => state.businessTypes,
  (state) => state.productIdentificationNumberType,
  (state) => state.userData,
  (state) => state.app,
  (brands, businessTypes, productIdentificationNumberType, userData, app) => ({
    brands,
    businessTypes,
    productIdentificationNumberType,
    userData: userData.user,
    app,
  })
);
export default connect(mapStateToProps, bindDispatch)(withRouter(Variants));
