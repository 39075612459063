import queryString from "query-string";
import { QA_BASE_URL, QA_CLIENT_ID, QA_PRODUCT_ID } from "../../../config";

const redirect = (redirect_uri) => {
  const url = `${QA_BASE_URL}dialog/authorize?${queryString.stringify({
    client_id: QA_CLIENT_ID,
    product_id: QA_PRODUCT_ID,
    redirect_uri,
    response_type: "token",
  })}`;
  window.location = url;
};

const WixRedirectHandle = () => {
  redirect(`${window.location.origin}/auth`);
  return null;
};

export default WixRedirectHandle;
